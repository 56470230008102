import React, { useMemo } from 'react';
import { graphql } from 'gatsby';

import { Chip, Divider, Toolbar, Typography, Box } from '@mui/material';
import Link from '@/components/Link';

const isBackgroundBlack = (backgroundColor) =>
  backgroundColor.includes('Black');

const NotificationBanner = ({ slice }) => {
  const {
    primary: {
      notification_banner_badge: notificationBannerBadge,
      notification_banner_cta_label: notificationBannerCtaLabel,
      notification_banner_cta_link: notificationBannerCtaLink,
      notification_banner_cta_mixpanel_event:
        notificationBannerCtaMixpanelEvent,
      notification_banner_style: notificationBannerStyle,
      notification_banner_text: notificationBannerText,
    },
  } = slice;
  const { backgroundColor, color } = useMemo(() => {
    if (isBackgroundBlack(notificationBannerStyle)) {
      return { backgroundColor: `#231A1A`, color: `#F8F8F8` };
    }
    return { backgroundColor: `#FFF`, color: `#0A0101` };
  }, [notificationBannerStyle]);

  if (!slice.primary) return <></>;
  return (
    <Box sx={{ backgroundColor, display: `flex`, justifyContent: `center` }}>
      <Toolbar sx={{ gap: 1 }}>
        <Chip
          label={notificationBannerBadge}
          sx={{
            backgroundColor: `#B1D0EC`,
            color: `#0A0101`,
            fontWeight: 800,
            fontSize: 11,
            lineHeight: 1.25,
            letterSpacing: `1.5px`,
            width: `inherit`,
            height: `inherit`,
            textTransform: `uppercase`,
            py: 0.5,
          }}
        />
        <Typography sx={{ color }} component="span">
          {notificationBannerText}
        </Typography>
        {notificationBannerCtaLink.url && (
          <>
            <Divider
              orientation="vertical"
              sx={{ borderColor: `#544D4D`, height: 40 }}
            />
            {notificationBannerCtaLink.target ? (
              <Link
                to={notificationBannerCtaLink.url}
                href={notificationBannerCtaLink.url}
                target={notificationBannerCtaLink.target}
                dataEventAction={notificationBannerCtaMixpanelEvent}
                sx={{ color }}
              >
                {notificationBannerCtaLabel}
              </Link>
            ) : (
              <Link to={notificationBannerCtaLink.link} sx={{ color }}>
                {notificationBannerCtaLabel}
              </Link>
            )}
          </>
        )}
      </Toolbar>
    </Box>
  );
};

export const qeury = graphql`
  fragment FeatureNotificationBanner on PrismicFeatureDataBodyNotificationBanner {
    id
    primary {
      notification_banner_badge
      notification_banner_cta_label
      notification_banner_cta_mixpanel_event
      notification_banner_cta_link {
        url
        target
      }
      notification_banner_style
      notification_banner_text
    }
    slice_label
    slice_type
  }
  fragment LandingPageNotificationBanner on PrismicLandingPageDataBodyNotificationBanner {
    id
    primary {
      notification_banner_badge
      notification_banner_cta_label
      notification_banner_cta_mixpanel_event
      notification_banner_cta_link {
        url
        target
      }
      notification_banner_style
      notification_banner_text
    }
    slice_label
    slice_type
  }
  fragment CompanyPageNotificationBanner on PrismicCompanyPageDataBodyNotificationBanner {
    id
    primary {
      notification_banner_badge
      notification_banner_cta_label
      notification_banner_cta_mixpanel_event
      notification_banner_cta_link {
        url
        target
      }
      notification_banner_style
      notification_banner_text
    }
    slice_label
    slice_type
  }
  fragment JobPageNotificationBanner on PrismicJobPageDataBodyNotificationBanner {
    id
    primary {
      notification_banner_badge
      notification_banner_cta_label
      notification_banner_cta_mixpanel_event
      notification_banner_cta_link {
        url
        target
      }
      notification_banner_style
      notification_banner_text
    }
    slice_label
    slice_type
  }
`;

export default NotificationBanner;
