import { createContext } from 'react';

export type PricingType = `monthly` | `yearly`;
export type PricingContextType = {
  type: PricingType;
  priceLoc: string | null;
  setType: (value: PricingType) => void;
};

const DEFAULT_CONTEXT: PricingContextType = {
  type: `yearly`,
  priceLoc: null,
  setType: () => {
    //  do nothing
  },
};

export const PricingContext = createContext(DEFAULT_CONTEXT);
export const { Consumer, Provider } = PricingContext;
