import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import Link from '@/components/Link';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import usePricingContext from '@/hooks/usePricingContext';

export const StyledRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: `rgb(248, 248, 248)`,
  },
});

export const StyledCell = styled(TableCell)(({ theme }) => ({
  color: `rgb(10, 1, 1)`,
  fontWeight: 400,
  letterSpacing: `-0.04px`,
  lineHeight: `28px`,
  fontSize: `16px !important`,
  [theme.breakpoints.down(`sm`)]: {},
}));

const AdditionalPricingSingleTable1 = ({ slice }) => {
  const { primary, items } = slice;
  const { priceLoc } = usePricingContext();
  const {
    ctaLabel,
    ctaLink,
    ctaMixpanelEvent,
    title,
    description,
    row1Title,
    row2Title,
    unitText,
  } = useMemo(() => {
    if (primary) {
      return {
        ctaLabel: primary.additional_pricing_single_table_cta_label,
        ctaLink: primary.additional_pricing_single_table_cta_link,
        ctaMixpanelEvent:
          primary.additional_pricing_single_table_cta_mixpanel_event,
        title: primary.additional_pricing_single_table_title,
        description: primary.additional_pricing_single_table_description,
        row1Title: primary.additional_pricing_single_table_row_1_title,
        row2Title: primary.additional_pricing_single_table_row_2_title,
        unitText: primary.unit_text,
      };
    }
    return {};
  }, [primary]);
  return (
    <Grid
      item
      xs={12}
      sm={6}
      sx={{ pr: { md: 1.5 }, display: `flex`, flexDirection: `column`, gap: 1 }}
    >
      {title.richText.map(({ text, type }) => (
        <CustomTypography
          key={uuidv4()}
          variant={type}
          customVariant="heading4"
        >
          {text}
        </CustomTypography>
      ))}
      {description.richText.map(({ text, type }) => (
        <CustomTypography
          key={uuidv4()}
          customVariant="paragraphMedium"
          variant={type}
          sx={{ color: `#544D4D` }}
        >
          {text}
        </CustomTypography>
      ))}
      {ctaLink.url && ctaLabel && (
        <Link
          to={ctaLink.url}
          href={ctaLink.url}
          dataEventAction={ctaMixpanelEvent}
          target={ctaLink.target}
          sx={{
            color: `#5298D5`,
            gap: 1,
            py: 1,
            fontWeight: 600,
            display: `flex`,
            alignItems: `center`,
            img: {
              width: 16,
              height: 12,
              transitionProperty: 'transform',
              transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
              transitionDuration: '0.15s',
            },
            '&:hover': {
              img: {
                transform: `translate(4px, 0px)`,
              },
            },
          }}
        >
          {ctaLabel}
          <img src="/blue-arrow.svg" alt="blue-arrow" />
        </Link>
      )}
      <TableContainer component="div" sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {row1Title.richText.map(({ text, type }) => (
                  <CustomTypography
                    key={uuidv4()}
                    variant={type}
                    customVariant="heading6"
                  >
                    {text}
                  </CustomTypography>
                ))}
              </TableCell>
              <TableCell>
                {row2Title.richText.map(({ text, type }) => (
                  <CustomTypography
                    key={uuidv4()}
                    variant={type}
                    customVariant="heading6"
                  >
                    {text}
                  </CustomTypography>
                ))}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.length > 0 &&
              items.map(
                ({
                  additional_pricing_single_table_row_1_item: row1Item,
                  additional_pricing_single_table_row_2_item: row2ItemUS,
                  additional_pricing_single_table_row_2_item_jp: row2ItemJP,
                  additional_pricing_single_table_row_2_item_tw: row2ItemTW,
                }) => (
                  <StyledRow key={uuidv4()}>
                    <StyledCell>{row1Item}</StyledCell>
                    <StyledCell>
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {priceLoc === `JP`
                        ? row2ItemJP
                        : priceLoc === `TW`
                        ? row2ItemTW
                        : row2ItemUS}
                      {unitText}
                    </StyledCell>
                  </StyledRow>
                ),
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export const query = graphql`
  fragment SingleTable1 on PrismicPricingPageDataBody3AdditionalPricingSingleTable1 {
    id
    items {
      additional_pricing_single_table_row_1_item
      additional_pricing_single_table_row_2_item
      additional_pricing_single_table_row_2_item_jp
      additional_pricing_single_table_row_2_item_tw
    }
    primary {
      additional_pricing_single_table_cta_label
      additional_pricing_single_table_cta
      additional_pricing_single_table_cta_link {
        url
        target
      }
      additional_pricing_single_table_description {
        richText
      }
      additional_pricing_single_table_row_1_title {
        richText
      }
      additional_pricing_single_table_row_2_title {
        richText
      }
      additional_pricing_single_table_title {
        richText
      }
      unit_text
    }
  }
`;
export default AdditionalPricingSingleTable1;
