import { red } from '@mui/material/colors';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      // custom values
      smid: 700,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          padding: `4px 8px`,
          marginBottom: `14px !important`,
        },
      },
    },
  },
  palette: {
    primary: {
      main: `#EBB0CF`,
    },
    secondary: {
      main: `#544D4D`,
    },
    info: {
      main: `#3b3434`,
      light: `#9d9999`,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: `#fff`,
    },
  },
  typography: {
    span: {},
    body1: {
      color: `#0A0101`,
      lineHeight: 1,
      '#___gatsby': {
        whiteSpace: `pre-wrap`,
      },
    },
    body2: {
      fontSize: `28px`,
      fontWeight: 700,
      letterSpacing: -0.84,
      lineHeight: 1.5,
    },
    heading1: {
      fontSize: `48px`,
      fontWeight: 800,
      letterSpacing: `-1.72px`,
      lineHeight: 1.1,
    },
    heading2: {
      fontSize: `40px`,
      fontWeight: 800,
      letterSpacing: `-2.1px`,
      lineHeight: 1.2,
    },
    heading3: {
      fontSize: `24px`,
      fontWeight: 600,
      letterSpacing: `-0.16px`,
      lineHeight: 1.3,
    },
    heading4: {
      fontSize: `20px`,
      fontWeight: 500,
      letterSpacing: `-0.13px`,
      lineHeight: 1.2,
    },
    heading5: {
      fontSize: `16px`,
      fontWeight: 700,
      letterSpacing: `-0.13px`,
    },
    heading6: {
      color: `#858080`,
      fontSize: `12px`,
      fontWeight: 800,
      letterSpacing: `1.5px`,
      lineHeight: 1.25,
      textTransform: `uppercase`,
    },
    textSmall: {
      fontSize: `12px`,
      lineHeight: 1.5,
    },
    textLarge: {
      fontWeight: 400,
      lineHeight: 1.5,
    },
    textJumbo: {
      fontSize: `64px`,
      fontWeight: 800,
      lineHeight: 1.12,
      letterSpacing: `-2.16px`,
    },
    bfText: {
      fontWeight: 800,
      fontSize: 96,
      lineHeight: `64px`,
      letterSpacing: `-2.16px`,
    },
    smallMuted: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1,
      letterSpacing: `-0.03px`,
      color: `#858080`,
    },
    subtitle1: {
      color: `rgb(133, 128, 128)`,
      fontSize: `12px`,
      fontWeight: 800,
      textTransform: `uppercase`,
      letterSpacing: `1.5px`,
    },
    subtitle2: {
      color: `rgb(133, 128, 128)`,
      fontSize: `12px`,
      fontWeight: 800,
      textTransform: `uppercase`,
      letterSpacing: `1.5px`,
    },
    subtitleLarge: {
      fontSize: `24px`,
      lineHeight: 1.33,
      fontWeight: 600,
      letterSpacing: `-0.16px`,
      textAlign: `center`,
    },
    paragraph: {
      fontSize: `16px`,
      lineHeight: 1.5,
    },
    paragraphSmall: {
      fontSize: `12px`,
      lineHeight: 1.5,
    },
    paragraphBold: {
      fontWeight: 600,
      fontSize: `16px`,
      lineHeight: 1.5,
      letterSpacing: `0px`,
    },
    paragraphMedium: {
      fontWeight: 400,
      fontSize: `16px`,
      lineHeight: 1.5,
    },
    paragraphLarge: {
      fontWeight: 400,
      fontSize: `20px`,
      lineHeight: 1.5,
    },
    paragraphLargeFixed: {
      fontWeight: 400,
      fontSize: `20px`,
      lineHeight: 1.5,
    },
    paragraphXL: {
      fontSize: `24px`,
      fontWeight: 600,
      letterSpacing: `-0.16px`,
      lineHeight: 1.3,
    },
    pricingHeader: {
      fontSize: `40px`,
      lineHeight: 1.2,
      letterSpacing: `-2.1px`,
      fontWeight: 800,
    },
    quote: {
      fontSize: `28px`,
      lineHeight: 1.3,
      letterSpacing: `-0.65px`,
      fontWeight: 600,
    },
    button: {
      letterSpacing: `1.2px`,
      lineHeight: `20px`,
      fontWeight: 800,
    },
    fontFamily: [`Manrope`, `Roboto`, `Helvetica`, `Arial`, `sans-serif`].join(
      `,`,
    ),
  },
});

theme.typography.heading1 = {
  ...theme.typography.heading1,
  [theme.breakpoints.down(`sm`)]: {
    fontSize: `32px`,
  },
};

theme.typography.heading2 = {
  ...theme.typography.heading2,
  [theme.breakpoints.down(`sm`)]: {
    fontSize: `32px`,
    lineHeight: `1.12`,
  },
};

theme.typography.textJumbo = {
  ...theme.typography.textJumbo,
  [theme.breakpoints.down(`md`)]: {
    fontSize: `48px`,
  },
  [theme.breakpoints.down(`sm`)]: {
    fontSize: `32px`,
  },
};

theme.typography.bfText = {
  ...theme.typography.bfText,
  [theme.breakpoints.down(`sm`)]: {
    fontSize: `64px`,
  },
};

theme.typography.paragraphXL = {
  ...theme.typography.paragraphXL,
  [theme.breakpoints.down(`md`)]: {
    fontSize: `20px`,
    lineHeight: `1.25`,
  },
  [theme.breakpoints.down(`sm`)]: {
    fontSize: `16px`,
  },
};

theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down(`sm`)]: {
    fontSize: `24px`,
    letterSpacing: `-1.26px`,
  },
};

theme.typography.h3 = {
  ...theme.typography.h3,
  [theme.breakpoints.down(`sm`)]: {
    fontSize: `16px`,
  },
};

theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.breakpoints.down(`sm`)]: {
    fontSize: `14px`,
  },
};

theme.typography.body2 = {
  ...theme.typography.body2,
  [theme.breakpoints.down(`sm`)]: {
    fontSize: 18,
  },
};

theme.typography.paragraphDefault = {
  ...theme.typography.paragraphDefault,
  [theme.breakpoints.down(`lg`)]: {
    fontSize: `16px`,
  },
  [theme.breakpoints.down(`sm`)]: {
    lineHeight: 1.4,
    fontSize: `14px`,
  },
};

theme.typography.paragraphLarge = {
  ...theme.typography.paragraphLarge,
  [theme.breakpoints.down(`sm`)]: {
    fontSize: `16px`,
    lineHeight: 1.5,
  },
};

theme.typography.subtitle2 = {
  ...theme.typography.subtitle2,
  [theme.breakpoints.down(`sm`)]: {
    fontSize: `10px`,
  },
};
theme.typography.subtitle1 = {
  ...theme.typography.subtitle1,
  [theme.breakpoints.down(`sm`)]: {
    fontSize: `8px`,
  },
};
theme.typography.subtitleLarge = {
  ...theme.typography.subtitleLarge,
  [theme.breakpoints.down(`sm`)]: {
    fontSize: `20px`,
    fontWeight: 500,
  },
};
theme.typography.pricingHeader = {
  ...theme.typography.pricingHeader,
  [theme.breakpoints.down(`sm`)]: {
    fontSize: `24px`,
    lineHeight: 1.33,
    fontWeight: 600,
    letterSpacing: `-0.16px`,
  },
};

export default responsiveFontSizes(theme);
