import React, { useEffect, useRef } from 'react';
import {
  usePopupState,
  bindToggle,
  bindPopover,
} from 'material-ui-popup-state/hooks';

import HoverPopover from 'material-ui-popup-state/HoverPopover';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import { BaseComponentProps, UrlLike } from '@/types';
import { NavItem } from '@/components/Header/Header';
import Link from '@/components/Link';
import { styled } from '@mui/material/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import useClickOutside from '../hooks/useClickOutside';
import { Chevron } from './Chevron';

interface DropdownMenuProps extends BaseComponentProps {
  title: string;
  items: NavItem[];
}

const isExternalUrl = (url: UrlLike): boolean => url.url.startsWith(`http`);

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  whiteSpace: `normal`,
  alignItems: `flex-start`,
  width: 376,
  padding: theme.spacing(2),
}));

const DropdownMenu = ({ title, items }: DropdownMenuProps): JSX.Element => {
  const popupState = usePopupState({
    variant: `popover`,
    popupId: `demoPopover`,
  });

  useEffect(() => {
    function handleResize() {
      // eslint-disable-next-line no-unused-expressions
      window.innerWidth < 1200 ? popupState.setOpen(false) : ``;
    }
    window.addEventListener(`resize`, handleResize);
    return () => {
      // unbind event listener on cleanup
      document.removeEventListener(`resize`, handleResize);
    };
  }, [popupState.setOpen]);

  const rootRef = useRef(null);
  useClickOutside(rootRef, () => popupState.setOpen(false));

  return (
    <Box sx={{ display: `flex`, alignItems: `stretch` }} ref={rootRef}>
      <Typography
        id={`header-${title}`}
        fontWeight={600}
        align="justify"
        component="span"
        {...bindToggle(popupState)}
        sx={{
          cursor: `pointer`,
          display: `flex`,
          gap: 1,
          alignItems: `center`,
          px: 2,
          transitionProperty: `all`,
          transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
          transitionDuration: `150ms`,
          color: popupState.isOpen ? `#231A1A` : `#858080`,
          '&:hover': {
            color: `#231A1A`,
            'div svg': {
              fill: `#231A1A`,
            },
          },
        }}
      >
        {title}
        <Box
          sx={{
            pb: 0.5,
            svg: {
              transform: popupState.isOpen ? `` : `rotate(180deg)`,
              transitionProperty: `transform`,
              transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
              transitionDuration: `150ms`,
              fill: popupState.isOpen ? `#231A1A` : `#858080`,
            },
          }}
        >
          <Chevron />
        </Box>
      </Typography>
      <HoverPopover
        {...bindPopover(popupState)}
        elevation={8}
        anchorOrigin={{
          vertical: `bottom`,
          horizontal: `center`,
        }}
        transformOrigin={{
          vertical: `top`,
          horizontal: `center`,
        }}
        sx={{
          '&>div': {
            p: 2,
            gap: 0.5,
            display: `flex`,
            flexDirection: `column`,
            borderRadius: `12px`,
          },
          a: {
            display: `flex`,
            alignItems: `flex-start`,
            gap: 0.75,
            color: `inherit`,
            textDecoration: `none`,
          },
        }}
      >
        {items.map((item) =>
          isExternalUrl(item.url) ? (
            <MenuItem
              component="a"
              key={item.title}
              href={item.url.url}
              target={item.url.target}
              sx={{
                display: `flex`,
                alignItems: `start`,
                flexDirection: `row`,
                gap: 0.75,
                color: `inherit`,
                textDecoration: `none`,
                py: 1.25,
                borderRadius: `12px`,
              }}
            >
              {item.icon && (
                <Box sx={{ maxWidth: 36, maxHeight: 36, mr: 1 }}>
                  <GatsbyImage image={item.icon} alt={item.title} />
                </Box>
              )}
              {item.title}
            </MenuItem>
          ) : (
            <StyledMenuItem
              sx={{ p: 0, borderRadius: `12px` }}
              key={item.title}
            >
              <Link
                to={item.url.url}
                sx={{
                  px: 2,
                  py: 1.25,
                  width: 408,
                  display: `flex`,
                  flexDirection: `row`,
                  alignItems: `start`,
                }}
              >
                {item.icon && (
                  <Box sx={{ maxWidth: 36, maxHeight: 36, mr: 1 }}>
                    <GatsbyImage image={item.icon} alt={item.title} />
                  </Box>
                )}
                <Box
                  sx={{ display: `flex`, flexDirection: `column`, gap: 0.5 }}
                >
                  <Typography
                    sx={{ fontWeight: 400, fontSize: 16, lineHeight: `22px` }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: 12,
                      lineHeight: `16px`,
                      color: `#858080`,
                    }}
                  >
                    {item.description.text}
                  </Typography>
                </Box>
              </Link>
            </StyledMenuItem>
          ),
        )}
      </HoverPopover>
    </Box>
  );
};

export default DropdownMenu;
