import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import Section from '@/components/Section';
import getBackgroundColor from '@/utils/getBackgroundColor';
import { GatsbyImage } from 'gatsby-plugin-image';
import Typography from '@/components/Typography';
import { Box } from '@mui/system';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import Animation from '@/components/Animation';

const FeatureSpotGrid = ({ slice }) => {
  const { items, primary } = slice;

  const {
    feature_spot_grid_style: featureSpotGridStyle,
    feature_spot_grid_layout: featureSpotGridLayout,
    feature_spot_grid_image: featureSpotGridImage,
    feature_spot_grid_title: featureSpotGridTitle,
    feature_spot_grid_description: featureSpotGridDescription,
  } = primary;

  const { backgroundColor, color, thirdColor } =
    getBackgroundColor(featureSpotGridStyle);
  const rightStyle = useMemo(() => {
    if (featureSpotGridLayout === 'Image On Right') {
      return { flexDirection: { xs: `column`, md: `row` } };
    }
    return {
      flexDirection: {
        xs: `column`,
        md: featureSpotGridLayout !== 'No Image' ? `row-reverse` : `column`,
      },
    };
  }, [featureSpotGridStyle]);

  const isAnimation = primary?.media_type === 'animation';
  const isImage =
    featureSpotGridLayout !== 'No Image' &&
    featureSpotGridImage.gatsbyImageData &&
    primary?.media_type !== 'animation';

  if (!primary) return <></>;

  return (
    <Section
      sx={{
        backgroundColor,
        color,
        px: { xs: 3, sm: 4 },
        py: { xs: 4, sm: 6, md: 8 },
      }}
    >
      <Box
        sx={{
          ...rightStyle,
          maxWidth: 'lg',
          width: 1,
          mx: 'auto',
          alignItems: `center`,
          justifyContent: `space-between`,
          display: `flex`,
          gap: 3,
        }}
      >
        <Box
          sx={{
            maxWidth: 600,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `center`,
            gap: 1,
          }}
        >
          {featureSpotGridTitle.richText.map(({ text, type }) => (
            <CustomTypography
              key={text}
              variant={type}
              customVariant="heading3"
              sx={{ color }}
            >
              {text}
            </CustomTypography>
          ))}
          {featureSpotGridDescription.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              customVariant="paragraph"
              variant={type}
              sx={{ color: thirdColor }}
            >
              {text}
            </CustomTypography>
          ))}
          <Box sx={{ display: `flex`, flexDirection: `column` }}>
            {items &&
              items.length > 0 &&
              items.map(
                ({
                  feature_spot_grid_feature_item: featureSpotGridFeatureItem,
                }) => (
                  <Typography
                    key={uuidv4()}
                    sx={{ mt: 2, display: `flex`, gap: 1 }}
                    variant="paragraphBold"
                  >
                    {featureSpotGridStyle === 'Black Background' ? (
                      <img src="/white-arrow.svg" alt="black-arrow" />
                    ) : (
                      <img src="/black-arrow.svg" alt="black-arrow" />
                    )}
                    {featureSpotGridFeatureItem}
                  </Typography>
                ),
              )}
          </Box>
        </Box>
        {isImage && (
          <GatsbyImage
            alt={featureSpotGridImage.alt}
            image={featureSpotGridImage.gatsbyImageData}
          />
        )}
        {isAnimation && (
          <Animation
            id={slice.id}
            loop={primary.animation_loop}
            animationTrigger={primary.animation_trigger}
            rawAnimationData={primary.json_animation_data}
          />
        )}
      </Box>
    </Section>
  );
};

export const query = graphql`
  fragment FeatureSpotGrid on PrismicFeatureDataBodyFeatureSpotGrid {
    id
    items {
      feature_spot_grid_feature_item
    }
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      feature_spot_grid_description {
        richText
      }
      feature_spot_grid_image {
        gatsbyImageData(height: 512)
        alt
      }
      feature_spot_grid_layout
      feature_spot_grid_style
      feature_spot_grid_title {
        richText
      }
    }
    slice_type
  }
  fragment EbookAndReportsPageFeatureSpotGrid on PrismicEbookAndReportsPageDataBodyFeatureSpotGrid {
    id
    items {
      feature_spot_grid_feature_item
    }
    primary {
      feature_spot_grid_description {
        richText
      }
      feature_spot_grid_image {
        gatsbyImageData(height: 512)
        alt
      }
      feature_spot_grid_layout
      feature_spot_grid_style
      feature_spot_grid_title {
        richText
      }
    }
    slice_type
  }

  fragment EbookAndReportPageFeatureSpotGrid on PrismicEbookAndReportPageDataBodyFeatureSpotGrid {
    id
    items {
      feature_spot_grid_feature_item
    }
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      feature_spot_grid_description {
        richText
      }
      feature_spot_grid_image {
        gatsbyImageData(height: 512)
        alt
      }
      feature_spot_grid_layout
      feature_spot_grid_style
      feature_spot_grid_title {
        richText
      }
    }
    slice_type
  }

  fragment LandingPageFeatureSpotGrid on PrismicLandingPageDataBodyFeatureSpotGrid {
    id
    items {
      feature_spot_grid_feature_item
    }
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      feature_spot_grid_description {
        richText
      }
      feature_spot_grid_image {
        gatsbyImageData(height: 512)
        alt
      }
      feature_spot_grid_layout
      feature_spot_grid_style
      feature_spot_grid_title {
        richText
      }
    }
    slice_type
  }

  fragment LandingPageAfterPricingFeatureSpotGrid on PrismicLandingPageDataBody1FeatureSpotGrid {
    id
    items {
      feature_spot_grid_feature_item
    }
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      feature_spot_grid_description {
        richText
      }
      feature_spot_grid_image {
        gatsbyImageData(height: 512)
        alt
      }
      feature_spot_grid_layout
      feature_spot_grid_style
      feature_spot_grid_title {
        richText
      }
    }
    slice_type
  }

  fragment CompanyPageFeatureSpotGrid on PrismicCompanyPageDataBodyFeatureSpotGrid {
    id
    items {
      feature_spot_grid_feature_item
    }
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      feature_spot_grid_description {
        richText
      }
      feature_spot_grid_image {
        gatsbyImageData(height: 512)
        alt
      }
      feature_spot_grid_layout
      feature_spot_grid_style
      feature_spot_grid_title {
        richText
      }
    }
    slice_type
  }

  fragment JobPageFeatureSpotGrid on PrismicJobPageDataBodyFeatureSpotGrid {
    id
    items {
      feature_spot_grid_feature_item
    }
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      feature_spot_grid_description {
        richText
      }
      feature_spot_grid_image {
        gatsbyImageData(height: 512)
        alt
      }
      feature_spot_grid_layout
      feature_spot_grid_style
      feature_spot_grid_title {
        richText
      }
    }
    slice_type
  }
`;

export default FeatureSpotGrid;
