import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import Section from '@/components/Section';
import Typography from '@/components/Typography/Typography';
import getBackgroundColor from '@/utils/getBackgroundColor';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import loadable from '@loadable/component';

const Odometer = loadable(() => import('@/components/Odometer'));

const Stats = ({ slice }) => {
  const { items, primary } = slice;
  const { statsSectionSubtitle, statsSectionTitle } = useMemo(() => {
    if (primary) {
      return {
        statsSectionSubtitle: primary?.stats_section_subtitle,
        statsSectionTitle: primary?.stats_section_title,
      };
    }
    return {
      statsSectionSubtitle: { richText: [] },
      statsSectionTitle: { richText: [] },
    };
  }, [primary]);
  const { backgroundColor, color, subColor } = getBackgroundColor(
    primary?.stats_section_style,
  );

  if (!primary) return <></>;

  return (
    <Section
      sx={{
        backgroundColor,
        py: { xs: 4, sm: 6, md: 8 },
        overflow: `hidden`,
        px: { xs: 3, sm: 4, md: 13.5 },
        backgroundImage:
          primary?.stats_section_style === 'Background Image' &&
          primary?.stats_section_background_image.url &&
          `url("${primary?.stats_section_background_image.url}")`,
      }}
    >
      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          gap: 7,
          maxWidth: 'xl',
          mx: 'auto',
          width: 1,
        }}
      >
        {statsSectionTitle.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            variant={type}
            customVariant="heading3"
            sx={{
              color,
              fontWeight: 600,
              fontSize: 24,
              lineHeight: `32px`,
            }}
          >
            {text}
          </CustomTypography>
        ))}
        <Box
          sx={{
            display: `flex`,
            justifyContent: `space-between`,
            flexWrap: `wrap`,
            flexDirection: { xs: `column`, md: `row` },
            gap: { xs: 6, lg: 4 },
          }}
        >
          {items &&
            items.length > 0 &&
            items.map(
              ({
                stat_description: statDescription,
                stat_number: statNumber,
              }) => (
                <Box
                  key={uuidv4()}
                  sx={{
                    maxWidth: 392,
                    display: `flex`,
                    flexDirection: `column`,
                    gap: 3,
                  }}
                >
                  <Typography
                    variant="bfText"
                    sx={{
                      color,
                      display: primary?.spinning_numbers && 'flex',
                      alignItems: primary?.spinning_numbers && 'flex-end',
                      marginBottom: primary?.spinning_numbers && '-25px',
                      marginTop: primary?.spinning_numbers && '-10px',
                    }}
                  >
                    {primary?.spinning_numbers ? (
                      <>
                        <Odometer value={parseFloat(statNumber)} />
                        {!parseInt(
                          statNumber.split('')[statNumber.length - 1],
                          10,
                        ) && (
                          <Box
                            component="span"
                            sx={{
                              display: 'block',
                              marginBottom: { xs: '13px', sm: '34px' },
                              marginLeft: '8px',
                            }}
                          >
                            {statNumber.split('')[statNumber.length - 1]}
                          </Box>
                        )}
                      </>
                    ) : (
                      statNumber
                    )}
                  </Typography>
                  <Typography
                    variant="heading3"
                    sx={{
                      color,
                      mt: -1,
                    }}
                  >
                    {statDescription}
                  </Typography>
                </Box>
              ),
            )}
        </Box>
        {statsSectionSubtitle.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            variant={type}
            customVariant="heading6"
            sx={{
              color: subColor,
              textAlign: { xl: 'center' },
            }}
          >
            {text}
          </CustomTypography>
        ))}
      </Box>
    </Section>
  );
};

export default Stats;

export const query = graphql`
  fragment HomepageStats on PrismicHomepageDataBodyStats {
    items {
      stat_number
      stat_description
    }
    slice_type
    primary {
      stats_section_title {
        richText
      }
      stats_section_subtitle {
        richText
      }
      stats_section_style
      stats_section_background_image {
        url
      }
      spinning_numbers
    }
  }
  fragment FeatureStats on PrismicFeatureDataBodyStats {
    items {
      stat_number
      stat_description
    }
    slice_type
    primary {
      stats_section_title {
        richText
      }
      stats_section_subtitle {
        richText
      }
      stats_section_style
      stats_section_background_image {
        url
      }
      spinning_numbers
    }
  }
  fragment LandingPageStats on PrismicLandingPageDataBodyStats {
    items {
      stat_number
      stat_description
    }
    slice_type
    primary {
      stats_section_title {
        richText
      }
      stats_section_subtitle {
        richText
      }
      stats_section_style
      stats_section_background_image {
        url
      }
      spinning_numbers
    }
  }

  fragment LandingPageAfterPricingStats on PrismicLandingPageDataBody1Stats {
    items {
      stat_number
      stat_description
    }
    slice_type
    primary {
      stats_section_title {
        richText
      }
      stats_section_subtitle {
        richText
      }
      stats_section_style
      stats_section_background_image {
        url
      }
      spinning_numbers
    }
  }

  fragment CompanyPageStats on PrismicCompanyPageDataBodyStats {
    items {
      stat_number
      stat_description
    }
    slice_type
    primary {
      stats_section_title {
        richText
      }
      stats_section_subtitle {
        richText
      }
      stats_section_style
      stats_section_background_image {
        url
      }
      spinning_numbers
    }
  }

  fragment JobPageStats on PrismicJobPageDataBodyStats {
    items {
      stat_number
      stat_description
    }
    slice_type
    primary {
      stats_section_title {
        richText
      }
      stats_section_subtitle {
        richText
      }
      stats_section_style
      stats_section_background_image {
        url
      }
      spinning_numbers
    }
  }
`;
