import React from 'react';

const html = `<div id="mc_embed_shell">
  <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
<style type="text/css">
   #mc_embed_signup{background:#fff; false;clear:left; font:14px Helvetica,Arial,sans-serif; max-width: 600px; width: 100%; margin-left: auto; margin-right: auto; }
   #mc_embed_signup h2 { margin-top: 0px; }
   #mc_embed_signup form { margin-top: -70px; }
   /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
     We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
 <form action="https://rosetta.us16.list-manage.com/subscribe/post?u=eaacde66a0ae979894aa47d6d&amp;id=c8792899ee&amp;f_id=00bb5ce0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
   <div id="mc_embed_signup_scroll"><h2>立即免費下載最新案例分析</h2>
     <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
     <div class="mc-field-group"><label for="mce-FNAME">姓名 <span class="asterisk">*</span></label><input type="text" name="FNAME" class="required text" id="mce-FNAME" required="" value=""></div><div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""></div><div class="mc-field-group"><label for="mce-PHONE">電話 <span class="asterisk">*</span></label><input type="text" name="PHONE" class="REQ_CSS" id="mce-PHONE" value=""></div><div class="mc-field-group"><label for="mce-MMERGE2">品牌名稱 <span class="asterisk">*</span></label><input type="text" name="MMERGE2" class="required text" id="mce-MMERGE2" required="" value=""></div><div class="mc-field-group input-group"><strong>產業 <span class="asterisk">*</span></strong><ul><li><input type="radio" name="MMERGE5" id="mce-MMERGE50" value="服飾"><label for="mce-MMERGE50">服飾</label></li><li><input type="radio" name="MMERGE5" id="mce-MMERGE51" value="美妝保養"><label for="mce-MMERGE51">美妝保養</label></li><li><input type="radio" name="MMERGE5" id="mce-MMERGE52" value="珠寶配件"><label for="mce-MMERGE52">珠寶配件</label></li><li><input type="radio" name="MMERGE5" id="mce-MMERGE53" value="鞋子包款"><label for="mce-MMERGE53">鞋子包款</label></li><li><input type="radio" name="MMERGE5" id="mce-MMERGE54" value="母嬰用品"><label for="mce-MMERGE54">母嬰用品</label></li><li><input type="radio" name="MMERGE5" id="mce-MMERGE55" value="其他"><label for="mce-MMERGE55">其他</label></li></ul></div>
   <div id="mce-responses" class="clearfalse">
     <div class="response" id="mce-error-response" style="display: none;"></div>
     <div class="response" id="mce-success-response" style="display: none;"></div>
   </div><div aria-hidden="true" style="position: absolute; left: -5000px;"><input type="text" name="b_eaacde66a0ae979894aa47d6d_c8792899ee" tabindex="-1" value=""></div><div class="clear"><input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Subscribe"></div>
 </div>
</form>
</div>
<script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[1]=FNAME;ftypes[1]=merge;,fnames[0]=EMAIL;ftypes[0]=merge;,fnames[4]=PHONE;ftypes[4]=merge;,fnames[2]=MMERGE2;ftypes[2]=merge;,fnames[5]=MMERGE5;ftypes[5]=merge;}(jQuery));var $mcj = jQuery.noConflict(true);</script></div>`;

const MailChimpForm = () => (
  // eslint-disable-next-line react/no-danger
  <section dangerouslySetInnerHTML={{ __html: html }} />
);

export default MailChimpForm;
