import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Link from '@/components/Link';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import usePricingContext from '@/hooks/usePricingContext';
import { StyledCell, StyledRow } from './AdditionalPricingSingleTable1';

const AdditionalPricingSingleTable = ({ slice }) => {
  const { primary, items } = slice;
  const { priceLoc } = usePricingContext();
  const {
    table1Row1Title,
    table1Row2Title,
    table1TableTitle,
    table1TableDescription,
    table2Row1Title,
    table2Row2Title,
    table2TableDescription,
    table2TableTitle,
    ctaLabel,
    ctaLink,
    ctaMixpanelEvent,
    description,
    title,
  } = useMemo(() => {
    if (primary) {
      return {
        table1Row1Title: primary.additional_pricing_double_table_1_row_1_title,
        table1Row2Title: primary.additional_pricing_double_table_1_row_2_title,
        table1TableTitle: primary.additional_pricing_double_table_1_table_title,
        table1TableDescription:
          primary.additional_pricing_double_table_1_table_description,
        table2Row1Title: primary.additional_pricing_double_table_2_row_1_title,
        table2Row2Title: primary.additional_pricing_double_table_2_row_2_title,
        table2TableDescription:
          primary.additional_pricing_double_table_2_table_description,
        table2TableTitle: primary.additional_pricing_double_table_2_table_title,
        ctaLabel: primary.additional_pricing_double_table_cta_label,
        ctaLink: primary.additional_pricing_double_table_cta_link,
        ctaMixpanelEvent:
          primary.additional_pricing_double_table_cta_mixpanel_event,
        description: primary.additional_pricing_double_table_description,
        title: primary.additional_pricing_double_table_title,
      };
    }
    return {};
  }, [primary]);

  const secondTableExists =
    items.filter(
      ({
        additional_pricing_double_table_2_row_1_item: table2Row1Item,
        additional_pricing_double_table_2_row_2: table2Row2ItemUS,
        additional_pricing_double_table_2_row_2_jp: table2Row2ItemJP,
        additional_pricing_double_table_2_row_2_tw: table2Row2ItemTW,
      }) =>
        // eslint-disable-next-line no-nested-ternary
        priceLoc === `JP`
          ? table2Row1Item && table2Row2ItemJP
          : priceLoc === `TW`
          ? table2Row1Item && table2Row2ItemTW
          : table2Row1Item && table2Row2ItemUS,
    ).length > 0;

  return (
    <Grid
      item
      xs={12}
      sm={6}
      sx={{ pr: { md: 1.5 }, display: `flex`, flexDirection: `column`, gap: 1 }}
    >
      {title.richText.map(({ text, type }) => (
        <CustomTypography
          key={uuidv4()}
          variant={type}
          customVariant="heading4"
        >
          {text}
        </CustomTypography>
      ))}
      {description.richText.map(({ text, type }) => (
        <CustomTypography
          key={uuidv4()}
          customVariant="paragraphMedium"
          variant={type}
          sx={{ color: `#544D4D` }}
        >
          {text}
        </CustomTypography>
      ))}
      {ctaLink.url && ctaLabel && (
        <Link
          to={ctaLink.url}
          href={ctaLink.url}
          target={ctaLink.target}
          dataEventAction={ctaMixpanelEvent}
          sx={{
            color: `#5298D5`,
            gap: 1,
            py: 1,
            fontWeight: 600,
            display: `flex`,
            alignItems: `center`,
            img: {
              width: 16,
              height: 12,
              transitionProperty: 'transform',
              transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
              transitionDuration: '0.15s',
            },
            '&:hover': {
              img: {
                transform: `translate(4px, 0px)`,
              },
            },
          }}
        >
          {ctaLabel}
          <img src="/blue-arrow.svg" alt="blue-arrow" />
        </Link>
      )}
      {table1TableTitle.richText.map(({ text, type }) => (
        <CustomTypography
          key={uuidv4()}
          variant={type}
          customVariant="headin4"
          sx={{ mt: 3, mb: 1 }}
        >
          {text}
        </CustomTypography>
      ))}
      {table1TableDescription.richText.map(({ text, type }) => (
        <CustomTypography
          key={uuidv4()}
          customVariant="paragraphMedium"
          variant={type}
          sx={{ color: `#544D4D` }}
        >
          {text}
        </CustomTypography>
      ))}
      <TableContainer component="div">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {table1Row1Title.richText.map(({ text, type }) => (
                  <CustomTypography
                    key={uuidv4()}
                    variant={type}
                    customVariant="heading6"
                  >
                    {text}
                  </CustomTypography>
                ))}
              </TableCell>
              <TableCell>
                {table1Row2Title.richText.map(({ text, type }) => (
                  <CustomTypography
                    key={uuidv4()}
                    variant={type}
                    customVariant="heading6"
                  >
                    {text}
                  </CustomTypography>
                ))}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items &&
              items.length > 0 &&
              items
                .filter(
                  ({
                    additional_pricing_double_table_1_row_1_item:
                      table1Row1Item,
                    additional_pricing_single_table_1_row_2_item:
                      table1Row2ItemUS,
                    additional_pricing_double_table_1_row_2_item_jp:
                      table1Row2ItemJP,
                    additional_pricing_double_table_1_row_2_item_tw:
                      table1Row2ItemTW,
                  }) =>
                    // eslint-disable-next-line no-nested-ternary
                    priceLoc === `JP`
                      ? table1Row1Item && table1Row2ItemJP
                      : priceLoc === `TW`
                      ? table1Row1Item && table1Row2ItemTW
                      : table1Row1Item && table1Row2ItemUS,
                )
                .map(
                  ({
                    additional_pricing_double_table_1_row_1_item:
                      table1Row1Item,
                    additional_pricing_single_table_1_row_2_item:
                      table1Row2ItemUS,
                    additional_pricing_double_table_1_row_2_item_jp:
                      table1Row2ItemJP,
                    additional_pricing_double_table_1_row_2_item_tw:
                      table1Row2ItemTW,
                  }) => (
                    <StyledRow key={uuidv4()}>
                      <StyledCell>{table1Row1Item}</StyledCell>
                      <StyledCell>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {priceLoc === `JP`
                          ? table1Row2ItemJP
                          : priceLoc === `TW`
                          ? table1Row2ItemTW
                          : table1Row2ItemUS}
                      </StyledCell>
                    </StyledRow>
                  ),
                )}
          </TableBody>
        </Table>
      </TableContainer>
      {secondTableExists && (
        <>
          {table2TableTitle.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              variant={type}
              customVariant="heading4"
              sx={{ mt: 3, mb: 1 }}
            >
              {text}
            </CustomTypography>
          ))}
          {table2TableDescription.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              customVariant="paragraphMedium"
              variant={type}
              sx={{ color: `#544D4D` }}
            >
              {text}
            </CustomTypography>
          ))}

          <TableContainer component="div">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {table2Row1Title.richText.map(({ text, type }) => (
                      <CustomTypography
                        key={uuidv4()}
                        variant={type}
                        customVariant="heading6"
                      >
                        {text}
                      </CustomTypography>
                    ))}
                  </TableCell>
                  <TableCell>
                    {table2Row2Title.richText.map(({ text, type }) => (
                      <CustomTypography
                        key={uuidv4()}
                        variant={type}
                        customVariant="heading6"
                      >
                        {text}
                      </CustomTypography>
                    ))}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items &&
                  items.length > 0 &&
                  items
                    .filter(
                      ({
                        additional_pricing_double_table_2_row_1_item:
                          table2Row1Item,
                        additional_pricing_double_table_2_row_2:
                          table2Row2ItemUS,
                        additional_pricing_double_table_2_row_2_jp:
                          table2Row2ItemJP,
                        additional_pricing_double_table_2_row_2_tw:
                          table2Row2ItemTW,
                      }) =>
                        // eslint-disable-next-line no-nested-ternary
                        priceLoc === `JP`
                          ? table2Row1Item && table2Row2ItemJP
                          : priceLoc === `TW`
                          ? table2Row1Item && table2Row2ItemTW
                          : table2Row1Item && table2Row2ItemUS,
                    )
                    .map(
                      ({
                        additional_pricing_double_table_2_row_1_item:
                          table2row1Item,
                        additional_pricing_double_table_2_row_2:
                          table2Row2ItemUS,
                        additional_pricing_double_table_2_row_2_jp:
                          table2Row2ItemJP,
                        additional_pricing_double_table_2_row_2_tw:
                          table2Row2ItemTW,
                      }) => (
                        <StyledRow key={uuidv4()}>
                          <StyledCell>{table2row1Item}</StyledCell>
                          <StyledCell>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {priceLoc === `JP`
                              ? table2Row2ItemJP
                              : priceLoc === `TW`
                              ? table2Row2ItemTW
                              : table2Row2ItemUS}
                          </StyledCell>
                        </StyledRow>
                      ),
                    )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Grid>
  );
};
export const query = graphql`
  fragment SingleTable on PrismicPricingPageDataBody3AdditionalPricingSingleTable {
    id
    items {
      additional_pricing_double_table_1_row_1_item
      additional_pricing_double_table_2_row_1_item
      additional_pricing_double_table_2_row_2
      additional_pricing_double_table_2_row_2_jp
      additional_pricing_double_table_2_row_2_tw
      additional_pricing_single_table_1_row_2_item
      additional_pricing_double_table_1_row_2_item_jp
      additional_pricing_double_table_1_row_2_item_tw
    }
    primary {
      additional_pricing_double_table_1_row_1_title {
        richText
      }
      additional_pricing_double_table_1_row_2_title {
        richText
      }
      additional_pricing_double_table_1_table_description {
        richText
      }
      additional_pricing_double_table_1_table_title {
        richText
      }
      additional_pricing_double_table_2_row_1_title {
        richText
      }
      additional_pricing_double_table_2_row_2_title {
        richText
      }
      additional_pricing_double_table_2_table_description {
        richText
      }
      additional_pricing_double_table_2_table_title {
        richText
      }
      additional_pricing_double_table_cta_label
      additional_pricing_double_table_cta
      additional_pricing_double_table_cta_link {
        url
        target
      }
      additional_pricing_double_table_description {
        richText
      }
      additional_pricing_double_table_title {
        richText
      }
    }
  }
`;
export default AdditionalPricingSingleTable;
