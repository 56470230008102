import React from 'react';
import MuiTypography, {
  TypographyProps as MuiTypographyProps,
} from '@mui/material/Typography';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';

interface TypographyProps extends MuiTypographyProps {
  variant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
}
const Typography = ({
  sx,
  children,
  variant,
  ...props
}: TypographyProps): JSX.Element => {
  const variantMapping = {
    heading1: `h1`,
    heading2: `h2`,
    heading3: `h3`,
    heading4: `h4`,
    heading5: `h5`,
    heading6: `h6`,
  };

  // Does the string contains any Japanese or Chinese characters?
  const containsLargerCharacters = Boolean(
    children?.toString().match(/[\u3400-\u9FBF]/),
  );

  // Is the text a heading?
  const isHeading = variant?.startsWith(`heading`);

  const fullSx = {
    ...sx,
    // if the text contains larger characters and is a heading, override the line height and letter spacing
    ...(containsLargerCharacters &&
      isHeading && {
        lineHeight: 1.3,
        letterSpacing: 0,
      }),
  };

  return (
    <MuiTypography
      variantMapping={variantMapping}
      variant={variant}
      sx={fullSx}
      {...props}
    >
      {children}
    </MuiTypography>
  );
};

Typography.defaultProps = {
  variant: `span`,
};
export default Typography;
