import theme from '@/theme';
import { Box, Button } from '@mui/material';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export const Tags = ({
  tags,
  selectedTag,
  setSelectedTag,
}: {
  tags: string[];
  selectedTag: string | null;
  setSelectedTag: React.Dispatch<React.SetStateAction<string | null>>;
}): JSX.Element => (
  <>
    {tags.length > 0 && (
      <Box sx={{ backgroundColor: `#0A0101` }}>
        <Box
          sx={{
            display: `flex`,
            maxWidth: `xl`,
            width: 1,
            mx: `auto`,
            columnGap: { xs: 4, sm: 6, lg: 7.5 },
            overflowX: `scroll`,
            color: `#F8F8F8`,
            paddingX: 4,
            fontSize: `12px`,
            fontWeight: 800,
            letterSpacing: `1.5px`,
            lineHeight: 1.25,
            textTransform: `uppercase`,
            justifyContent: {
              xs: `auto`,
              sm: `space-between`,
              md: `center`,
            },
          }}
          role="radiogroup"
          aria-controls="posts-panel"
          aria-label="Filter posts by tag"
        >
          <Button
            key={uuidv4()}
            sx={{
              all: `unset`,
              cursor: `pointer`,
              whiteSpace: `nowrap`,
              textAlign: `center`,
              paddingY: 3,
              '&:hover': {
                backgroundColor: `unset`,
              },
              '&[aria-checked="true"]': {
                color: theme.palette.primary.main,
              },
            }}
            onClick={() => setSelectedTag(null)}
            role="radio"
            aria-checked={selectedTag === null}
          >
            All
          </Button>
          {tags.map((tag) => (
            <Button
              key={uuidv4()}
              sx={{
                all: `unset`,
                cursor: `pointer`,
                whiteSpace: `nowrap`,
                textAlign: `center`,
                paddingY: 3,
                '&:hover': {
                  backgroundColor: `unset`,
                },
                '&[aria-checked="true"]': {
                  color: theme.palette.primary.main,
                },
              }}
              onClick={() => setSelectedTag(tag)}
              role="radio"
              aria-checked={selectedTag === tag}
            >
              {tag}
            </Button>
          ))}
        </Box>
      </Box>
    )}
  </>
);
