import theme from '@/theme';
import { TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import React from 'react';
import { OverridableStringUnion } from '@mui/types';
import { TypographyProps as MuiTypographyProps } from '@mui/material/Typography';
import Typography from './Typography';

interface CustomTypographyProps extends MuiTypographyProps {
  variant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
  customVariant?: Variant;
}

export const CustomTypography: React.FC<CustomTypographyProps> = ({
  sx,
  children,
  variant,
  customVariant,
  ...props
}) => {
  // Does the string contains any Japanese or Chinese characters?
  const containsLargerCharacters = Boolean(
    children
      ?.toString()
      .match(
        /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/,
      ),
  );

  // Is the text a heading?
  const isHeading = variant?.startsWith(`heading`);

  const sxFull = {
    ...theme.typography[customVariant || `span`],
    // if the text contains larger characters and is a heading, override the line height and letter spacing
    ...(containsLargerCharacters &&
      isHeading && {
        lineHeight: 1.3,
        letterSpacing: 0,
      }),
    ...sx,
  };
  return (
    <Typography variant={variant} sx={sxFull} {...props}>
      {children}
    </Typography>
  );
};

CustomTypography.defaultProps = {
  variant: `span`,
  customVariant: `span`,
};
