import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Box, styled } from '@mui/material';

import Section from '@/components/Section';
import ButtonLink from '@/components/ButtonLink';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import { v4 as uuidv4 } from 'uuid';
import { ButtonUnstyled } from '@mui/material/node_modules/@mui/base';
import Animation from '@/components/Animation';
import SplashSectionForm from '@/components/SplashSectionForm';
import getBackgroundColor from '@/utils/getBackgroundColor';
import { PrismicRichText } from '@prismicio/react';

const SplashSection = ({ slice, context }) => {
  const { items, primary } = slice;
  const isPlainText =
    primary?.splash_section_style &&
    primary?.splash_section_style.includes('Plain Text');
  const isVertical =
    primary?.splash_section_style &&
    primary?.splash_section_style.includes('Vertical');
  const hasBackgroundImage =
    primary?.splash_section_style &&
    primary?.splash_section_style.includes('Background');
  const isImageOnly =
    primary?.splash_section_style &&
    primary?.splash_section_style.includes('Image Only');
  const scrollLink = context && context.scrollLink;
  const isFullWidthContent = primary?.full_width_content;

  const { backgroundColor, color } = getBackgroundColor(
    slice.primary.splash_section_background_color,
  );

  const PrimaryLink = styled(ButtonUnstyled)(({ theme }) => ({
    cursor: `pointer`,
    color: `#0A0101`,
    background: `#EBB0CF`,
    border: `1px solid #D45398`,
    boxSizing: `border-box`,
    boxShadow: `2px 2px 0px #D45398`,
    borderRadius: `12px`,
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
    textDecoration: `none`,
    fontFamily: `Manrope,Roboto,Helvetica,Arial,sans-serif`,
    ':hover': {
      boxShadow: `4px 4px 0px #D45398`,
      marginRight: theme.spacing(0.25),
      marginBottom: theme.spacing(0.25),
      marginLeft: theme.spacing(-0.25),
      marginTop: theme.spacing(-0.25),
    },
    ':active': {
      boxShadow: `1px 1px 0px #D45398`,
      marginLeft: theme.spacing(0.125),
      marginTop: theme.spacing(0.125),
      marginRight: theme.spacing(-0.125),
      marginBottom: theme.spacing(-0.125),
    },
  }));

  const SecondaryLink = styled(ButtonUnstyled)(({ theme }) => ({
    cursor: `pointer`,
    color: `#FFFFFF`,
    background: `#0A0101`,
    border: `1px solid #FFFFFF`,
    boxSizing: `border-box`,
    boxShadow: `2px 2px 0px #FFFFFF`,
    borderRadius: `12px`,
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
    textDecoration: `none`,
    fontFamily: `Manrope,Roboto,Helvetica,Arial,sans-serif`,
    ':hover': {
      boxShadow: `4px 4px 0px #FFFFFF`,
      marginRight: theme.spacing(0.25),
      marginBottom: theme.spacing(0.25),
      marginLeft: theme.spacing(-0.25),
      marginTop: theme.spacing(-0.25),
    },
    ':active': {
      boxShadow: `1px 1px 0px #FFFFFF`,
      marginLeft: theme.spacing(0.125),
      marginTop: theme.spacing(0.125),
      marginRight: theme.spacing(-0.125),
      marginBottom: theme.spacing(-0.125),
    },
  }));

  const DownloadLink = styled(ButtonUnstyled)(({ theme }) => ({
    textDecoration: `none`,
    fontFamily: `Manrope,Roboto,Helvetica,Arial,sans-serif`,
    alignSelf: `flex-start`,
    width: { xs: 1, sm: 'auto' },
    textAlign: 'center',
    cursor: `pointer`,
    boxSizing: `border-box`,
    background: 'linear-gradient(119.82deg, #E7F5D7 12.14%, #E9F3F9 84.11%);',
    borderRadius: `12px`,
    padding: `${theme.spacing(1.75)} ${theme.spacing(3)}`,
    border: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: '9px',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '-0.05px',
    color: `#0A0101`,
    img: {
      marginLeft: '12px',
    },
    // md and down
    [theme.breakpoints.down('md')]: {
      marginTop: '20px',
    },
  }));

  const StyledLink = useMemo(() => {
    if (
      slice?.primary?.splash_section_background_color === 'Black Background'
    ) {
      return SecondaryLink;
    }
    return PrimaryLink;
  });

  const { sectionStyle, headLineStyle, buttonsStyle, gridStyle } =
    useMemo(() => {
      const baseStyle = {
        sectionStyle: {
          overflow: 'hidden',
        },
        gridStyle: {
          justifyContent: 'space-between',
          display: `flex`,
          maxWidth: 'xl',
          width: 1,
          mx: 'auto',
          my: { xs: 0, md: 2, lg: 0 },
        },
        headLineStyle: {
          display: `flex`,
          flexDirection: `column`,
          width: 1,
        },
        buttonsStyle: {
          display: `flex`,
          gap: 2,
        },
      };
      if (hasBackgroundImage) {
        return {
          sectionStyle: {
            ...baseStyle.sectionStyle,
            backgroundColor,
            backgroundImage: `url("${primary?.splash_section_image?.url}")`,
            backgroundPosition: `center`,
            backgroundSize: `cover`,
            pt: { xs: 61.75, sm: 62.125, md: 48.75, lg: 47.25 },
            pb: { xs: 6, sm: 8 },
            px: { xs: 3, sm: 4, lg: 13.5 },
          },
          gridStyle: {
            ...baseStyle.gridStyle,
            flexDirection: `column-reverse`,
            alignItems: `center`,
          },
          headLineStyle: {
            ...baseStyle.headLineStyle,
            textAlign: `center`,
            gap: { xs: 1, sm: 2 },
            color: color ?? `#ffffff`,
            maxWidth: 800,
          },
          buttonsStyle: {
            ...baseStyle.buttonsStyle,
            justifyContent: `center`,
            gap: 2,
            flexDirection: { xs: `column`, sm: `row` },
          },
        };
      }
      if (isVertical) {
        return {
          sectionStyle: {
            ...baseStyle.sectionStyle,
            backgroundColor,
            pt: { xs: 4, sm: 5 },
            pb: { xs: 4, sm: 6, md: '91px', lg: '71px' },
            px: { xs: 3, sm: 4, lg: 13.5 },
            mb: {
              xs: slice?.primary?.turn_bottom_margin_off ? 0 : 4,
              sm: slice?.primary?.turn_bottom_margin_off ? 0 : 6,
            },
          },
          gridStyle: {
            ...baseStyle.gridStyle,
            flexDirection: `column-reverse`,
            alignItems: `center`,
            pb: { xs: 1, sm: 0 },
          },
          headLineStyle: {
            ...baseStyle.headLineStyle,
            color,
            textAlign: `center`,
            mt: 3,
            gap: { xs: 1, sm: 2 },
            maxWidth: isFullWidthContent ? 1 : 600,
          },
          buttonsStyle: {
            ...baseStyle.buttonsStyle,
            justifyContent: `center`,
            gap: 2,
            flexDirection: { xs: `column`, sm: `row` },
          },
        };
      }
      return {
        sectionStyle: {
          ...baseStyle.sectionStyle,
          backgroundColor,
          pt: { xs: 0 },
          pb: {
            xs: slice?.primary?.turn_bottom_margin_off ? 4 : 0,
            sm: slice?.primary?.turn_bottom_margin_off ? 6 : 0,
          },
          px: { xs: 3, sm: 4, lg: 13.5 },
          mb: {
            xs: slice?.primary?.turn_bottom_margin_off ? 0 : 4,
            sm: slice?.primary?.turn_bottom_margin_off ? 0 : 6,
          },
        },
        gridStyle: {
          ...baseStyle.gridStyle,
          flexWrap: { xs: `wrap-reverse`, md: 'nowrap' },
          pb: { xs: 1, sm: 0 },
        },
        headLineStyle: {
          ...baseStyle.headLineStyle,
          color,
          alignSelf: `center`,
          maxWidth: isFullWidthContent ? 1 : 600,
        },
        buttonsStyle: {
          ...baseStyle.buttonsStyle,
          justifyContent: `flex-start`,
        },
      };
    }, [isVertical, hasBackgroundImage]);

  // Lottie animation
  const isAnimation = primary?.media_type === 'animation';
  const isImage =
    primary?.splash_section_image?.gatsbyImageData &&
    primary?.media_type !== 'animation';

  if (!primary) return <></>;

  if (isImageOnly)
    return (
      <Section
        sx={{
          width: '100%',
          height: '658px',
          backgroundImage: `url("${primary?.splash_section_image?.url}")`,
          overflow: 'hidden',
          backgroundPosition: `center`,
          backgroundSize: `cover`,
        }}
      />
    );

  if (isPlainText)
    return (
      <Section
        sx={{
          px: { xs: 3, sm: 4, lg: 13.5 },
          pt: { xs: 4.25 },
          pb: { xs: 7, sm: 5 },
          overflow: 'hidden',
        }}
      >
        <Box sx={{ maxWidth: 'xl', width: 1, mx: 'auto' }}>
          {primary?.splash_section_headline?.richText?.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              customVariant="heading1"
              variant={type}
              sx={{
                textAlign: 'center',
                fontWeight: { xs: 600, sm: 800 },
              }}
            >
              {text}
            </CustomTypography>
          ))}
        </Box>
      </Section>
    );

  return (
    <Section
      sx={{
        ...sectionStyle,
      }}
    >
      <Box
        sx={{
          ...gridStyle,
        }}
      >
        <Box sx={{ ...headLineStyle }}>
          {primary.splash_section_tag &&
            primary.splash_section_tag.richText.map(({ text }) => (
              <CustomTypography
                key={uuidv4()}
                variant="paragraph"
                customVariant="heading6"
                sx={{
                  display: `flex`,
                  justifyContent: `center`,
                  color: `#544D4D`,
                }}
              >
                {text}
              </CustomTypography>
            ))}
          {primary.splash_section_headline.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              customVariant="heading1"
              variant={type}
              sx={{
                mb: 2,
                // if context.page is home, use white color
                fontSize:
                  context.page === 'homepage'
                    ? { md: context.lang === 'ja-jp' ? '50px' : '64px' }
                    : '48px',
              }}
            >
              {text}
            </CustomTypography>
          ))}
          {primary.splash_section_description.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              customVariant="paragraphLarge"
              variant={type}
              sx={{
                mb: 3,
              }}
            >
              {text}
            </CustomTypography>
          ))}
          {primary.form_id ? (
            <SplashSectionForm formId={primary.form_id} />
          ) : (
            <Box sx={{ ...buttonsStyle }}>
              {items &&
                items.length > 0 &&
                items.map(
                  ({
                    splash_section_button_label: splashSectionButtonLabel,
                    splash_section_button_link: splashSectionButtonLink,
                    splash_section_button_style: splashSectionButtonStyle,
                    splash_section_button_mixpanel_event:
                      splashSectionButtonMixpanelEvent,
                  }) =>
                    splashSectionButtonLabel &&
                    splashSectionButtonLink.url && (
                      <ButtonLink
                        key={uuidv4()}
                        to={splashSectionButtonLink.url}
                        target={splashSectionButtonLink.target}
                        sx={{
                          alignSelf: `flex-start`,
                          width: { xs: 1, sm: 'auto' },
                          textAlign: 'center',
                        }}
                        type={splashSectionButtonStyle}
                        dataEventAction={splashSectionButtonMixpanelEvent}
                      >
                        {splashSectionButtonLabel}
                      </ButtonLink>
                    ),
                )}
              {scrollLink && (
                <StyledLink
                  sx={{ fontSize: `1rem`, fontWeight: 600 }}
                  onClick={(e) => {
                    e.preventDefault();
                    const destination = document.querySelector(
                      `#${context.scrollLink.to}`,
                    );
                    if (destination) {
                      destination.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }
                  }}
                >
                  {context.scrollLink.text}
                </StyledLink>
              )}
            </Box>
          )}
          {primary.show_download_button &&
            primary.download_button_label.text &&
            primary.download_button_link.url && (
              <Box>
                <DownloadLink
                  href={primary.download_button_link.url}
                  target={primary.download_button_link.target}
                  as="a"
                >
                  <PrismicRichText
                    field={primary.download_button_label.richText}
                    components={{
                      paragraph: ({ children }) => <span>{children}</span>,
                    }}
                  />
                  <img src="/black-arrow.svg" alt="black-arrow" />
                </DownloadLink>
              </Box>
            )}
        </Box>
        {!hasBackgroundImage && (
          <Box
            sx={{
              img: {
                width: {
                  md: isVertical && !isFullWidthContent ? 600 : 1,
                  // eslint-disable-next-line no-nested-ternary
                  xl: isFullWidthContent ? 1 : isVertical ? 600 : 736,
                },
                maxHeight: isVertical && !isFullWidthContent ? 400 : 'auto',
              },
              maxHeight: isAnimation ? 700 : 'auto',
            }}
          >
            {isImage && (
              <GatsbyImage
                image={primary.splash_section_image.gatsbyImageData}
                alt={primary.splash_section_image.alt}
              />
            )}
            {isAnimation && (
              <Animation
                id={slice.id}
                loop={primary?.animation_loop}
                animationTrigger={primary?.animation_trigger}
                rawAnimationData={primary?.json_animation_data}
              />
            )}
          </Box>
        )}
      </Box>
    </Section>
  );
};

export const query = graphql`
  fragment HomepageSplashSection on PrismicHomepageDataBodySplashSection {
    primary {
      animation_loop
      animation_trigger
      form_id
      media_type
      full_width_content
      json_animation_data {
        text
      }
      splash_section_image {
        alt
        copyright
        gatsbyImageData
        url
      }
      splash_section_style
      splash_section_background_color
      splash_section_headline {
        richText
        text
      }
      splash_section_description {
        richText
      }
      turn_bottom_margin_off
      download_button_label {
        richText
        text
      }
      download_button_link {
        url
        target
      }
      show_download_button
    }
    items {
      splash_section_button_label
      splash_section_button_style
      splash_section_button_mixpanel_event
      splash_section_button_link {
        url
        target
      }
    }
  }

  fragment FeatureSplashSection on PrismicFeatureDataBodySplashSection {
    primary {
      animation_loop
      animation_trigger
      media_type
      full_width_content
      json_animation_data {
        text
      }
      splash_section_image {
        alt
        copyright
        gatsbyImageData
        url
      }
      splash_section_style
      splash_section_background_color
      splash_section_tag {
        richText
      }
      splash_section_headline {
        richText
      }
      splash_section_description {
        richText
      }
      turn_bottom_margin_off
    }
    items {
      splash_section_button_label
      splash_section_button_style
      splash_section_button_mixpanel_event
      splash_section_button_link {
        url
        target
      }
    }
  }

  fragment ResourceSplashSection on PrismicResourcePageDataBodySplashSection {
    primary {
      animation_loop
      animation_trigger
      media_type
      full_width_content
      json_animation_data {
        text
      }
      splash_section_image {
        alt
        copyright
        gatsbyImageData
        url
      }
      splash_section_style
      splash_section_background_color
      splash_section_tag {
        richText
      }
      splash_section_headline {
        richText
      }
      splash_section_description {
        richText
      }
      turn_bottom_margin_off
    }
    items {
      splash_section_button_label
      splash_section_button_style
      splash_section_button_mixpanel_event
      splash_section_button_link {
        url
        target
      }
    }
  }

  fragment EbookAndReportsSplashSection on PrismicEbookAndReportsPageDataBodySplashSection {
    primary {
      animation_loop
      animation_trigger
      media_type
      full_width_content
      json_animation_data {
        text
      }
      splash_section_image {
        alt
        copyright
        gatsbyImageData
        url
      }
      splash_section_style
      splash_section_background_color
      splash_section_tag {
        richText
      }
      splash_section_headline {
        richText
      }
      splash_section_description {
        richText
      }
      turn_bottom_margin_off
    }
    items {
      splash_section_button_label
      splash_section_button_style
      splash_section_button_mixpanel_event
      splash_section_button_link {
        url
        target
      }
    }
  }

  fragment BlogHomePageSplashSection on PrismicBlogHomePageDataBodySplashSection {
    primary {
      animation_loop
      animation_trigger
      media_type
      full_width_content
      json_animation_data {
        text
      }
      splash_section_image {
        alt
        copyright
        gatsbyImageData
        url
      }
      splash_section_style
      splash_section_background_color
      splash_section_tag {
        richText
      }
      splash_section_headline {
        richText
      }
      splash_section_description {
        richText
      }
      turn_bottom_margin_off
    }
    items {
      splash_section_button_label
      splash_section_button_style
      splash_section_button_mixpanel_event
      splash_section_button_link {
        url
        target
      }
    }
  }

  fragment CaseStudiesPageSplashSection on PrismicCaseStudiesPageDataBodySplashSection {
    primary {
      animation_loop
      animation_trigger
      media_type
      full_width_content
      json_animation_data {
        text
      }
      splash_section_image {
        alt
        copyright
        gatsbyImageData
        url
      }
      splash_section_style
      splash_section_background_color
      splash_section_tag {
        richText
      }
      splash_section_headline {
        richText
      }
      splash_section_description {
        richText
      }
      turn_bottom_margin_off
    }
    items {
      splash_section_button_label
      splash_section_button_style
      splash_section_button_mixpanel_event
      splash_section_button_link {
        url
        target
      }
    }
  }

  fragment PressPageSplashSection on PrismicPressPageDataBodySplashSection {
    primary {
      animation_loop
      animation_trigger
      media_type
      full_width_content
      json_animation_data {
        text
      }
      splash_section_image {
        alt
        copyright
        gatsbyImageData
        url
      }
      splash_section_style
      splash_section_background_color
      splash_section_tag {
        richText
      }
      splash_section_headline {
        richText
      }
      splash_section_description {
        richText
      }
      turn_bottom_margin_off
    }
    items {
      splash_section_button_label
      splash_section_button_style
      splash_section_button_mixpanel_event
      splash_section_button_link {
        url
        target
      }
    }
  }

  fragment FourOhFourSplashSection on Prismic404DataBodySplashSection {
    primary {
      splash_section_image {
        alt
        copyright
        gatsbyImageData
        url
      }
      full_width_content
      splash_section_style
      splash_section_background_color
      splash_section_tag {
        richText
      }
      splash_section_headline {
        richText
      }
      splash_section_description {
        richText
      }
      turn_bottom_margin_off
    }
    items {
      splash_section_button_label
      splash_section_button_style
      splash_section_button_mixpanel_event
      splash_section_button_link {
        url
        target
      }
    }
  }

  fragment LandingPageSplashSection on PrismicLandingPageDataBodySplashSection {
    primary {
      animation_loop
      animation_trigger
      media_type
      full_width_content
      json_animation_data {
        text
      }
      splash_section_image {
        alt
        copyright
        gatsbyImageData
        url
      }
      splash_section_style
      splash_section_background_color
      splash_section_tag {
        richText
      }
      splash_section_headline {
        richText
      }
      splash_section_description {
        richText
      }
      turn_bottom_margin_off
    }
    items {
      splash_section_button_label
      splash_section_button_style
      splash_section_button_mixpanel_event
      splash_section_button_link {
        url
        target
      }
    }
  }

  fragment CompanyPageSplashSection on PrismicCompanyPageDataBodySplashSection {
    primary {
      animation_loop
      animation_trigger
      media_type
      full_width_content
      json_animation_data {
        text
      }
      splash_section_image {
        alt
        copyright
        gatsbyImageData
        url
      }
      splash_section_style
      splash_section_background_color
      splash_section_tag {
        richText
      }
      splash_section_headline {
        richText
      }
      splash_section_description {
        richText
      }
      turn_bottom_margin_off
    }
    items {
      splash_section_button_label
      splash_section_button_style
      splash_section_button_mixpanel_event
      splash_section_button_link {
        url
        target
      }
    }
  }

  fragment JobPageSplashSection on PrismicJobPageDataBodySplashSection {
    primary {
      animation_loop
      animation_trigger
      media_type
      full_width_content
      json_animation_data {
        text
      }
      splash_section_image {
        alt
        copyright
        gatsbyImageData
        url
      }
      splash_section_style
      splash_section_background_color
      splash_section_tag {
        richText
      }
      splash_section_headline {
        richText
      }
      splash_section_description {
        richText
      }
      turn_bottom_margin_off
    }
    items {
      splash_section_button_label
      splash_section_button_style
      splash_section_button_mixpanel_event
      splash_section_button_link {
        url
        target
      }
    }
  }
`;

export default SplashSection;
