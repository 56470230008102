/* eslint-disable no-restricted-syntax */
/* eslint-disable no-empty */

import { HEntry } from '@/types';

function level(e: HTMLHeadingElement): number {
  return parseInt(e.tagName[1], 10);
}

export function getNestedHeadings(
  headings: readonly HTMLHeadingElement[],
): HEntry[] {
  const sentinel: HEntry = { text: ``, id: ``, level: 0 };
  const traversalStack: HEntry[] = [sentinel];

  for (const h of headings) {
    const hLevel = level(h);
    for (
      let last = traversalStack[traversalStack.length - 1];
      hLevel <= last.level;
      traversalStack.pop(), last = traversalStack[traversalStack.length - 1]
    ) {}

    const last = traversalStack[traversalStack.length - 1];
    last.items = last.items || [];
    last.items.push({
      text: h.textContent || ``,
      id: h.id,
      level: hLevel,
    });
    traversalStack.push(last.items[last.items.length - 1]);
  }

  return sentinel.items || [];
}
