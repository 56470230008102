type BackgroundAndTextColor = {
  backgroundColor: string;
  color: string;
  subColor?: string;
  thirdColor?: string;
  fourthColor?: string;
};

const getBackgroundColor = (
  backgroundColorText: string,
): BackgroundAndTextColor => {
  const result = {
    backgroundColor: `#fff`,
    color: `#0A0101`,
    subColor: `#858080`,
    thirdColor: `#544D4D`,
    fourthColor: `#858080`,
  };
  if (backgroundColorText === `Black Background`) {
    result.backgroundColor = `#0A0101`;
    result.color = `#fff`;
    result.subColor = `#CECCCC`;
    result.thirdColor = `#E7E6E6`;
    result.fourthColor = `#B6B3B3`;
  }
  if (backgroundColorText === `Light Gray Background`) {
    result.backgroundColor = `#F8F8F8`;
  }
  if (backgroundColorText === `Dark Gray Background`) {
    result.backgroundColor = `#231A1A`;
    result.color = `#fff`;
  }
  if (backgroundColorText === `Background Image`) {
    result.backgroundColor = `#0A0101`;
    result.color = `#fff`;
    result.subColor = `#CECCCC`;
  }

  return result;
};

export default getBackgroundColor;
