import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import { Box } from '@mui/material';

import Section from '@/components/Section';
import Typography from '@/components/Typography';
import { HiArrowRight } from 'react-icons/hi';
import { GatsbyImage } from 'gatsby-plugin-image';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import { ButtonUnstyled } from '@mui/base';
import { linkLabels } from '@/data/linkLabels';
// import { GatsbyImage } from 'gatsby-plugin-image';

const CaseStudyInfo = ({ slice, context }) => {
  const { primary, items } = slice;
  const {
    problemTitle,
    problemDescription,
    solutionTitle,
    solutionDescription,
    resultsTitle,
  } = useMemo(() => {
    if (primary) {
      return {
        problemTitle: primary.problem_title,
        problemDescription: primary.problem_description,
        solutionTitle: primary.solution_title,
        solutionDescription: primary.solution_description,
        resultsTitle: primary.results_title,
      };
    }
    return {};
  }, [primary]);
  return (
    <Section
      sx={{
        overflow: `hidden`,
        py: 0,
        px: 0,
      }}
    >
      <Box
        sx={{
          background: {
            lg: `linear-gradient(90deg, #F8F8F8 50%, #231A1A 50%)`,
          },
          width: 1,
          px: { xs: 0, lg: 13.5 },
        }}
      >
        <Box
          sx={{
            display: { xs: `flex`, lg: `grid` },
            flexDirection: `column`,
            gridTemplateColumns: { lg: `1fr 1.1fr` },
            gridAutoFlow: `row`,
            justifyContent: `center`,
            width: 1,
            maxWidth: 'xl',
            mx: 'auto',
          }}
        >
          <ProblemAndSolution
            problemTitle={problemTitle}
            problemDescription={problemDescription}
            solutionTitle={solutionTitle}
            solutionDescription={solutionDescription}
            lang={context.lang}
          />
          <Results
            resultsTitle={resultsTitle}
            items={items}
            lang={context.lang}
          />
        </Box>
      </Box>
    </Section>
  );
};

const ProblemAndSolution = ({
  problemTitle,
  problemDescription,
  solutionTitle,
  solutionDescription,
  lang,
}) => (
  <Box
    sx={{
      display: `flex`,
      flexDirection: `column`,
      gap: 4,
      py: 5,
      backgroundColor: `#F8F8F8`,
      pl: { xs: 3, sm: 4, md: 13.5, lg: 0 },
      pr: { xs: 3, sm: 4, md: 13.5, lg: 3 },
    }}
  >
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        gap: 2,
        maxWidth: 391,
      }}
    >
      {problemTitle.richText.map(({ text, type }) => (
        <CustomTypography
          customVariant="heading3"
          key={uuidv4()}
          variant={type}
        >
          {text}
        </CustomTypography>
      ))}
      {problemDescription.richText.map(({ text, type }) => (
        <CustomTypography
          customVariant="paragraphLarge"
          key={uuidv4()}
          variant={type}
        >
          {text}
        </CustomTypography>
      ))}

      <SectionButton to="Problem">
        {linkLabels[lang || 'en-us']}
        <HiArrowRight />
      </SectionButton>
    </Box>
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        gap: 2,
        maxWidth: 391,
      }}
    >
      {solutionTitle.richText.map(({ text, type }) => (
        <CustomTypography
          customVariant="heading3"
          key={uuidv4()}
          variant={type}
        >
          {text}
        </CustomTypography>
      ))}
      {solutionDescription.richText.map(({ text, type }) => (
        <CustomTypography
          customVariant="paragraphLarge"
          key={uuidv4()}
          variant={type}
        >
          {text}
        </CustomTypography>
      ))}
      <SectionButton to="Solution">
        {linkLabels[lang || 'en-us']}
        <HiArrowRight />
      </SectionButton>
    </Box>
  </Box>
);

const Results = ({ resultsTitle, items, lang }) => (
  <Box
    sx={{
      backgroundColor: `#231A1A`,
      color: `#ffffff`,
      py: 5,
      pl: { xs: 3, sm: 4, md: 13.5, lg: 3 },
      pr: { xs: 3, sm: 4, md: 13.5, lg: 0 },
    }}
  >
    <Box
      sx={{
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `space-between`,
        height: 1,
      }}
    >
      <Box sx={{ display: `flex`, flexDirection: `column`, gap: 3 }}>
        {resultsTitle.richText.map(({ text, type }) => (
          <CustomTypography
            customVariant="heading3"
            key={uuidv4()}
            variant={type}
          >
            {text}
          </CustomTypography>
        ))}
        <Box
          sx={{
            display: `grid`,
            gridTemplateColumns: {
              sm: `repeat(3, 175px)`,
              md: `repeat(3, 180px)`,
            },
            gridAutoFlow: `row`,
            gap: { xs: 3, sm: '4px', lg: '40px', xl: '83px' },
            justifyContent: `space-between`,
          }}
        >
          {items &&
            items.length > 0 &&
            items.map((item) => <Result key={uuidv4()} item={item} />)}
        </Box>
      </Box>
      <SectionButton to="Result">
        {linkLabels[lang || 'en-us']}
        <HiArrowRight />
      </SectionButton>
    </Box>
  </Box>
);

const Result = ({ item }) => (
  <Box
    sx={{
      display: `flex`,
      flexDirection: `column`,
      gap: 1,
      justifyContent: `start`,
      '.gatsby-image-wrapper': {
        maxWidth: 48,
      },
    }}
  >
    {item.result_item_type === 'Icon' ? (
      <>
        {item.result_item_icon.gatsbyImageData && (
          <GatsbyImage
            alt={item.result_item_icon.alt}
            image={item.result_item_icon.gatsbyImageData}
          />
        )}
      </>
    ) : (
      <Typography variant="textJumbo" sx={{ fontSize: `48px` }}>
        {item.result_item_number}
      </Typography>
    )}
    {item.result_item_description.richText.map(({ text, type }) => (
      <CustomTypography
        variant={type}
        key={uuidv4()}
        customVariant="paragraphLarge"
      >
        {text}
      </CustomTypography>
    ))}
  </Box>
);

const SectionButton = ({ children, to }) => (
  <Box
    sx={{
      button: { background: `none`, border: `none`, cursor: `pointer`, px: 0 },
    }}
  >
    <ButtonUnstyled
      onClick={(e) => {
        e.preventDefault();
        const destination = document.querySelector(`#${to}`);
        if (destination) {
          destination.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }}
    >
      <Typography
        variant="paragraphMedium"
        sx={{
          color: '#5298D5',
          fontWeight: 600,
          display: `flex`,
          alignItems: `center`,
          gap: 1,
          fontFamily: `Manrope,Roboto,Helvetica,Arial,sans-serif`,
          svg: {
            transitionProperty: 'transform',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDuration: '0.15s',
          },
          '&:hover': {
            svg: {
              transform: `translate(4px, 0px)`,
            },
          },
        }}
      >
        {children}
      </Typography>
    </ButtonUnstyled>
  </Box>
);

export default CaseStudyInfo;

export const query = graphql`
  fragment CaseStudyInfo on PrismicCaseStudyPageDataBodyCaseStudyInfo {
    id
    slice_type
    primary {
      problem_title {
        richText
      }
      problem_description {
        richText
      }
      solution_title {
        richText
      }
      solution_description {
        richText
      }
      results_title {
        richText
      }
    }
    items {
      result_item_type
      result_item_number
      result_item_icon {
        gatsbyImageData
        alt
      }
      result_item_description {
        richText
      }
    }
  }
`;
