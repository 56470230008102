import mixpanel from 'mixpanel-browser';
import page from './page';
import click from './click';

export function getDistinctId(): any {
  return mixpanel.get_distinct_id();
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function initEventConfig() {
  mixpanel.init(process.env.GATSBY_MIXPANEL_TOKEN as string, {
    api_transport: `sendBeacon`,
    persistence: `localStorage`,
    secure_cookie: true,
    api_host: `https://api-eu.mixpanel.com`,
    upgrade: true,
    debug: process.env.NODE_ENV === `development`,
    ignore_dnt: true,
    cross_subdomain_cookie: true,
  });

  const sp = new URLSearchParams(window.location.search);
  const rosettaUID = sp.get(`roid`);

  if (rosettaUID) {
    mixpanel.identify(rosettaUID);
  }
}

const event = {
  page,
  click,
};

export default event;
