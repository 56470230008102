import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import { PrismicRichText } from '@prismicio/react';
import { Box } from '@mui/material';

import Section from '@/components/Section';
import getBackgroundColor from '@/utils/getBackgroundColor';
import Typography from '@/components/Typography';
import { CustomTypography } from '@/components/Typography/CustomTypography';

const FAQs = ({ slice }) => {
  const { items, primary } = slice;
  const { faqsStyle, faqsTitle, columnCount } = useMemo(() => {
    if (primary) {
      return {
        faqsStyle: primary.faqs_style,
        faqsTitle: primary.faqs_title,
        columnCount:
          primary.column_count === '3'
            ? { xs: `repeat(1, 1fr)`, md: `repeat(3, 1fr)` }
            : { xs: `repeat(1, 1fr)`, md: `repeat(2, 1fr)` },
      };
    }
    return {};
  }, [primary]);
  const { backgroundColor, color, thirdColor } = getBackgroundColor(faqsStyle);
  if (!primary) return <></>;
  return (
    <Section
      sx={{ backgroundColor, px: { xs: 3, sm: 4 }, py: { xs: 3, sm: 4 } }}
    >
      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
          maxWidth: 'xl',
          width: 1,
          mx: 'auto',
        }}
      >
        {faqsTitle.richText.map(({ type, text }) => (
          <CustomTypography
            key={uuidv4()}
            variant={type}
            customVariant="heading3"
            sx={{ color }}
          >
            {text}
          </CustomTypography>
        ))}
        <Box
          sx={{
            mt: 5,
            display: `grid`,
            gridTemplateColumns: columnCount,
            justifyContent: `center`,
            gap: 3,
          }}
        >
          {items &&
            items.length > 0 &&
            items.map(
              ({
                faqs_item_question: faqsItemQuestion,
                faqs_item_answer: faqsItemAnswer,
              }) => (
                <Box key={uuidv4()} sx={{ maxWidth: 392 }}>
                  <Typography sx={{ color }} variant="paragraphBold">
                    {faqsItemQuestion}
                  </Typography>
                  <Typography
                    sx={{ color: thirdColor, a: { color: `#5298D5` } }}
                    variant="textSmall"
                  >
                    <PrismicRichText field={faqsItemAnswer.richText} />
                  </Typography>
                </Box>
              ),
            )}
        </Box>
      </Box>
    </Section>
  );
};

export default FAQs;

export const query = graphql`
  fragment FeatureFaqs on PrismicFeatureDataBodyFaqs {
    id
    items {
      faqs_item_answer {
        richText
      }
      faqs_item_question
    }
    primary {
      faqs_style
      faqs_title {
        richText
      }
      column_count
    }
    slice_type
  }
  fragment ResourceFaqs on PrismicResourcePageDataBodyFaqs {
    id
    items {
      faqs_item_answer {
        richText
      }
      faqs_item_question
    }
    primary {
      faqs_style
      faqs_title {
        richText
      }
      column_count
    }
    slice_type
  }

  fragment LandingPageFaqs on PrismicLandingPageDataBodyFaqs {
    id
    items {
      faqs_item_answer {
        richText
      }
      faqs_item_question
    }
    primary {
      faqs_style
      faqs_title {
        richText
      }
      column_count
    }
    slice_type
  }

  fragment LandingPageAfterPricingFaqs on PrismicLandingPageDataBody1Faqs {
    id
    items {
      faqs_item_answer {
        richText
      }
      faqs_item_question
    }
    primary {
      faqs_style
      faqs_title {
        richText
      }
      column_count
    }
    slice_type
  }

  fragment CompanyPageFaqs on PrismicCompanyPageDataBodyFaqs {
    id
    items {
      faqs_item_answer {
        richText
      }
      faqs_item_question
    }
    primary {
      faqs_style
      faqs_title {
        richText
      }
      column_count
    }
    slice_type
  }

  fragment JobPageFaqs on PrismicJobPageDataBodyFaqs {
    id
    items {
      faqs_item_answer {
        richText
      }
      faqs_item_question
    }
    primary {
      faqs_style
      faqs_title {
        richText
      }
      column_count
    }
    slice_type
  }
`;
