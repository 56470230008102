export type FeatureFlag = 'None' | 'Coming Soon' | 'New' | 'Beta';
const getFeatureFlagColor = (featureFlag: FeatureFlag): string => {
  switch (featureFlag) {
    case `Coming Soon`: {
      return `#E7E6E6`;
    }
    case `New`: {
      return `#B1D0EC`;
    }
    case `Beta`: {
      return `#D0ECB1`;
    }
    default: {
      return `#E7E6E6`;
    }
  }
};

export default getFeatureFlagColor;
