/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import { Box } from '@mui/material';
import Typography from '@/components/Typography';
import { GatsbyImage } from 'gatsby-plugin-image';
import ButtonLink from '@/components/ButtonLink';
import FeatureGroupFlag from '@/components/FeatureGroupFlag';
import usePricingContext from '@/hooks/usePricingContext';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import Tooltip from '@/components/Tooltip/Tooltip';
import kebabCase from 'lodash/kebabCase';

/**
 * Utils
 */
function getPricingWebAppLink(link, pricingType) {
  try {
    const url = new URL(link);
    url.searchParams.set('billing-cycle', pricingType);
    return url.href;
  } catch (error) {
    return link;
  }
}

/**
 * Component
 */
const PrimaryPricingTier = (props) => {
  const { slice, context } = props;
  const { primary, items } = slice;
  const { type: pricingType, priceLoc } = usePricingContext();
  const {
    ctaLabel,
    ctaLink,
    ctaStyle,
    ctaMixpanelEvent,
    description,
    fromText,
    icon,
    monthlyAmountUS,
    monthlyAmountJP,
    monthlyAmountTW,
    monthlyPeriodLabel,
    title,
    yearlyAmountUS,
    yearlyAmountJP,
    yearlyAmountTW,
    yearlyPeriodLabel,
    tooltipText,
    tooltipId,
  } = useMemo(() => {
    if (primary) {
      return {
        ctaLabel: primary.primary_pricing_tier_cta_label,
        ctaLink: primary.primary_pricing_tier_cta_link,
        ctaStyle: primary.primary_pricing_tier_cta_style,
        ctaMixpanelEvent: primary.primary_pricing_tier_cta_mixpanel_event,
        description: primary.primary_pricing_tier_description,
        fromText: primary.primary_pricing_tier_from_text,
        icon: primary.primary_pricing_tier_icon,
        monthlyAmountUS: primary.primary_pricing_tier_monthly_amount,
        monthlyAmountJP: primary.primary_pricing_tier_monthly_amount_jp,
        monthlyAmountTW: primary.primary_pricing_tier_monthly_amount_tw,
        monthlyPeriodLabel: primary.primary_pricing_tier_monthly_period_label,
        title: primary.primary_pricing_tier_title,
        yearlyAmountUS: primary.primary_pricing_tier_yearly_amount,
        yearlyAmountJP: primary.primary_pricing_tier_yearly_amount_jp,
        yearlyAmountTW: primary.primary_pricing_tier_yearly_amount_tw,
        yearlyPeriodLabel: primary.primary_pricing_tier_yearly_period_label,
        tooltipText: primary.primary_pricing_tier_tooltip_text?.html,
        tooltipId: primary.primary_pricing_tier_tooltip_text?.text,
      };
    }
    return {};
  }, [primary]);

  const monthlyAmount =
    priceLoc === `JP`
      ? monthlyAmountJP
      : priceLoc === `TW`
      ? monthlyAmountTW
      : monthlyAmountUS;

  const yearlyAmount =
    priceLoc === `JP`
      ? yearlyAmountJP
      : priceLoc === `TW`
      ? yearlyAmountTW
      : yearlyAmountUS;

  const amount = pricingType === 'monthly' ? monthlyAmount : yearlyAmount;
  const isFree =
    amount.toLowerCase() === 'free' || amount === '無料' || amount === '免費';

  const currency =
    priceLoc === `JP` ? `JPY ¥` : priceLoc === `TW` ? `NT$` : `USD $`;

  if (!primary) return <></>;

  return (
    <Box
      sx={{
        position: 'relative',
        flexGrow: 1,
        flexBasis: 1,
        width: 1,
        maxWidth: 480,
        height: 1,
      }}
    >
      <Box
        sx={{
          background: `#FFF`,
          border: `1px solid #0A0101`,
          boxShadow: `4px 4px 0px #0A0101`,
          borderRadius: `12px`,
          maxWidth: 257,
          minWidth: 203,
          width: `55%`,
          height: 112,
          pt: 2.5,
          pr: 2,
          pl: 1,
          position: `absolute`,
          right: 0,
          top: `-72px`,
          zIndex: -1,
        }}
      >
        {description.richText.map(({ text }) => (
          <Typography
            key={uuidv4()}
            sx={{
              fontWeight: 600,
              fontSize: 12,
              lineHeight: `16px`,
              display: `block`,
              textAlign: `end`,
            }}
          >
            {text}
          </Typography>
        ))}
      </Box>
      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `space-between`,
          gap: 2,
          background: '#FFF',
          border: '1px solid #0A0101',
          boxShadow: '4px 4px 0px #0A0101',
          borderRadius: `12px`,
          minHeight: 634,
          pb: 3,
          height: 1,
        }}
      >
        <Box sx={{ display: `flex`, flexDirection: `column`, gap: 2 }}>
          <Box
            sx={{ display: `flex`, alignItems: `center`, gap: 1, p: 3, pb: 0 }}
          >
            {icon.gatsbyImageData && (
              <Box sx={{ width: 40, height: 40 }}>
                <GatsbyImage alt={icon.alt} image={icon.gatsbyImageData} />
              </Box>
            )}
            <Box>
              {title.richText.map(({ text, type }) => (
                <CustomTypography
                  key={uuidv4()}
                  variant={type}
                  customVariant="heading2"
                  sx={{
                    fontSize: {
                      xs: 48,
                      md: 34,
                      lg: context.length > 3 ? 24 : 40,
                      xl: context.length > 3 ? 28 : 40,
                    },
                  }}
                >
                  {text}
                </CustomTypography>
              ))}
            </Box>
            <Tooltip id={kebabCase(tooltipId)} text={tooltipText} />
          </Box>
          <Box
            sx={{
              px: 1,
              py: 2,
              backgroundColor: `#F8F8F8`,
              display: `flex`,
              flexDirection: `column`,
              alignItems: `center`,
              gap: 0.5,
            }}
          >
            <Typography variant="smallMuted">{fromText}</Typography>
            <Box sx={{ display: `flex`, gap: 1 }}>
              {!isFree && amount && (
                <Typography
                  variant="paragraphBold"
                  sx={{
                    pt: {
                      xs: 1,
                      md: context.length > 3 && priceLoc === `JP` ? 0.5 : 1,
                      xl: 1,
                    },
                  }}
                >
                  {currency}
                </Typography>
              )}
              <Typography
                sx={{
                  fontSize: {
                    xs: 64,
                    md: context.length > 3 ? 64 : 44,
                    lg:
                      context.length > 3 && priceLoc === `JP`
                        ? 30
                        : context.length > 3
                        ? 40
                        : 48,
                    xl:
                      context.length > 3 && priceLoc === `JP`
                        ? 36
                        : context.length > 3
                        ? 48
                        : 64,
                  },
                  lineHeight: 1,
                  fontWeight: 800,
                  letterSpacing: `-2.16px`,
                }}
              >
                {!amount || priceLoc === null ? `` : amount}
              </Typography>
              {!isFree && amount && (
                <>
                  <Typography sx={{ alignSelf: `flex-end`, pb: 1 }}>
                    {`/ ${
                      pricingType === 'monthly'
                        ? monthlyPeriodLabel
                        : yearlyPeriodLabel
                    }`}
                  </Typography>
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              px: 3,
              py: 1,
              maxWidth: 390,
              mx: { xs: 0, xl: context.length > 3 ? 2 : `45px` },
              display: `flex`,
              flexDirection: `column`,
            }}
          >
            {items &&
              items.length > 0 &&
              items.map(
                ({
                  primary_pricing_tier_feature: feature,
                  primary_pricing_tier_feature_flag: featureFlag,
                }) => (
                  <Box
                    key={uuidv4()}
                    sx={{
                      display: `flex`,
                      gap: 1,
                      minHeight: 40,
                      py: `11px`,
                      alignItems: `center`,
                    }}
                  >
                    <img src="/black-arrow.svg" alt="black-arrow" />
                    <Typography variant="textSmall">{feature}</Typography>
                    <FeatureGroupFlag featureFlag={featureFlag} />
                  </Box>
                ),
              )}
          </Box>
        </Box>
        {ctaLabel && (
          <ButtonLink
            to={getPricingWebAppLink(ctaLink.url, pricingType)}
            target={ctaLink.target}
            type={ctaStyle}
            dataEventAction={ctaMixpanelEvent}
            sx={{
              alignSelf: 'center',
              width: 232,
              textAlign: `center`,
            }}
          >
            {ctaLabel}
          </ButtonLink>
        )}
      </Box>
    </Box>
  );
};

export const query = graphql`
  fragment PrimaryPricingTier on PrismicPricingPageDataBodyPrimaryPricingTier {
    id
    items {
      primary_pricing_tier_feature
      primary_pricing_tier_feature_flag
    }
    primary {
      primary_pricing_tier_cta_label
      primary_pricing_tier_cta_link {
        target
        url
      }
      primary_pricing_tier_cta_style
      primary_pricing_tier_cta_mixpanel_event
      primary_pricing_tier_currency
      primary_pricing_tier_description {
        richText
      }
      primary_pricing_tier_from_text
      primary_pricing_tier_icon {
        gatsbyImageData(width: 40)
        alt
      }
      primary_pricing_tier_monthly_amount
      primary_pricing_tier_monthly_amount_jp
      primary_pricing_tier_monthly_amount_tw
      primary_pricing_tier_monthly_period_label
      primary_pricing_tier_title {
        richText
      }
      primary_pricing_tier_yearly_amount
      primary_pricing_tier_yearly_amount_jp
      primary_pricing_tier_yearly_amount_tw
      primary_pricing_tier_yearly_period_label
      primary_pricing_tier_tooltip_text {
        html
        text
      }
    }
  }
  fragment LandingPagePrimaryPricingTier on PrismicLandingPageDataPricingBodyPrimaryPricingTier {
    id
    items {
      primary_pricing_tier_feature
      primary_pricing_tier_feature_flag
    }
    primary {
      primary_pricing_tier_cta_label
      primary_pricing_tier_cta_link {
        target
        url
      }
      primary_pricing_tier_cta_style
      primary_pricing_tier_cta_mixpanel_event
      primary_pricing_tier_currency
      primary_pricing_tier_description {
        richText
      }
      primary_pricing_tier_from_text
      primary_pricing_tier_icon {
        gatsbyImageData(width: 40)
        alt
      }
      primary_pricing_tier_monthly_amount
      primary_pricing_tier_monthly_amount_jp
      primary_pricing_tier_monthly_amount_tw
      primary_pricing_tier_monthly_period_label
      primary_pricing_tier_title {
        richText
      }
      primary_pricing_tier_yearly_amount
      primary_pricing_tier_yearly_amount_jp
      primary_pricing_tier_yearly_amount_tw
      primary_pricing_tier_yearly_period_label
      primary_pricing_tier_tooltip_text {
        html
        text
      }
    }
  }
`;
export default PrimaryPricingTier;
