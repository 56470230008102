import React from 'react';
import { Box, keyframes, Stack, Hidden } from '@mui/material';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';

import { CustomTypography } from '@/components/Typography/CustomTypography';

const carousel = (index) => keyframes`
  from {
    transform: translateX(${index * 100}%);
  }
  to {
    transform: translateX(${-100 + 100 * index}%);
  }
`;

const Logos = ({ items }) => (
  <>
    {items &&
      items.length > 0 &&
      items.map(({ client_logo_image: clientLogoImage }) => (
        <Box
          key={uuidv4()}
          sx={{
            minHeight: 56,
            display: `flex`,
            alignItems: `strech`,
            px: { xs: 4, sm: 2, md: 4, lg: 0 },
            py: { xs: 2, sm: 1, md: 2, lg: 0 },
          }}
        >
          {clientLogoImage.gatsbyImageData && (
            <GatsbyImage
              image={clientLogoImage.gatsbyImageData}
              alt={clientLogoImage.alt}
            />
          )}
        </Box>
      ))}
  </>
);

const StyledStack = styled(Stack)(({ theme }) => ({
  flexDirection: `row`,
  position: `absolute`,
  paddingLeft: theme.spacing(2),
  gap: theme.spacing(2),
  [theme.breakpoints.up(`sm`)]: {
    paddingLeft: theme.spacing(10),
    gap: theme.spacing(10),
  },
  width: `120%`,
  [theme.breakpoints.up(1450)]: {
    width: `100%`,
  },
}));

const ClientsGrid = ({ slice }) => {
  const {
    items,
    primary: { client_logos_subtitle: clientLogosSubtitle },
  } = slice;
  return (
    <Box
      sx={{
        mx: 'auto',
        mb: { xs: 4, sm: 6, md: 12.5, xl: 5 },
        maxWidth: 'xl',
        overflow: 'hidden',
      }}
    >
      <Hidden lgDown>
        <Box
          className="clients"
          sx={{
            my: 3,
            display: `flex`,
            alignItems: `center`,
            position: `relative`,
            height: { xs: 37.5, sm: 64 },
            overflow: `hidden`,
            '&::after': {
              content: `''`,
              background: `linear-gradient(to right,#fff 0,rgba(255,255,255,0.1) 10%,rgba(255,255,255,0.1) 90%,#fff 100%)`,
              position: `absolute`,
              left: 0,
              top: 0,
              right: 0,
              bottom: 0,
            },
          }}
        >
          <StyledStack sx={{ animation: `${carousel(0)} 30s infinite linear` }}>
            <Logos items={items} />
          </StyledStack>
          <StyledStack sx={{ animation: `${carousel(1)} 30s infinite linear` }}>
            <Logos items={items} />
          </StyledStack>
        </Box>
      </Hidden>
      <Hidden lgUp>
        <Box
          className="clients"
          sx={{
            display: `grid`,
            flexWrap: `wrap`,
            mx: `auto`,
            gridTemplateColumns: { xs: `repeat(2, 1fr)`, sm: `repeat(3, 1fr)` },
            maxWidth: { xs: 'sm', md: 'md' },
            justifyContent: `center`,
            mb: { xs: 1, sm: 2, md: 1, lg: 2 },
          }}
        >
          <Logos items={items} />
        </Box>
      </Hidden>
      {clientLogosSubtitle.richText.map(({ text, type }) => (
        <CustomTypography
          key={uuidv4()}
          variant={type}
          customVariant="heading6"
          sx={{
            display: `flex`,
            justifyContent: `center`,
            color: `#B6B3B3`,
            px: { xs: 3, sm: 4 },
          }}
        >
          {text}
        </CustomTypography>
      ))}
    </Box>
  );
};

export default ClientsGrid;

export const query = graphql`
  fragment ClientsGrid on PrismicHomepageDataBodyClientsGrid {
    items {
      client_logo_image {
        gatsbyImageData
        alt
      }
    }
    slice_type
    primary {
      client_logos_subtitle {
        richText
      }
    }
  }
  fragment LandingPageClientsGrid on PrismicLandingPageDataBodyClientsGrid {
    items {
      client_logo_image {
        gatsbyImageData
        alt
      }
    }
    slice_type
    primary {
      client_logos_subtitle {
        richText
      }
    }
  }
  fragment LandingPageAfterPricingClientsGrid on PrismicLandingPageDataBody1ClientsGrid {
    items {
      client_logo_image {
        gatsbyImageData
        alt
      }
    }
    slice_type
    primary {
      client_logos_subtitle {
        richText
      }
    }
  }
`;
