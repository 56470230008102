import React from 'react';
import Section from '@/components/Section';
import { graphql } from 'gatsby';
import { Box } from '@mui/material';
import Typography from '@/components/Typography';

const DownloadAllImages = ({ slice }) => {
  const { primary } = slice;
  if (!primary) return <></>;
  return (
    <Section sx={{ px: { xs: 3, sm: 4 }, py: { xs: 4, sm: 6, md: 7.5 } }}>
      <Box
        sx={{
          maxWidth: 'sm',
          width: 1,
          mx: 'auto',
          display: 'flex',
          justifyContent: `space-between`,
          backgroundColor: `#F8F8F8`,
          py: { xs: 5, sm: 6 },
          px: 4,
          gap: { xs: `85px`, sm: 3 },
        }}
      >
        <Box
          sx={{
            display: `flex`,
            alignItems: `center`,
            gap: 2,
            img: {
              display: { xs: `none`, sm: `block` },
            },
          }}
        >
          <img src="/picture-icon.svg" alt={primary.download_label} />
          <Typography variant="heading3">{primary.download_label}</Typography>
        </Box>
        <a href={primary.download_link.url}>
          <img
            width="32px"
            height="32px"
            src="/download-button-small.svg"
            alt={primary.download_label}
          />
        </a>
      </Box>
    </Section>
  );
};

export const query = graphql`
  fragment PressDownloadAllImages on PrismicPressPostDataBodyDownloadAllImages {
    id
    primary {
      download_link {
        url
      }
      download_label
    }
    slice_type
  }
`;

export default DownloadAllImages;
