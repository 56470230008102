import React from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import Section from '@/components/Section';
import Typography from '@/components/Typography';
import { GatsbyImage } from 'gatsby-plugin-image';
import getBackgroundColor from '@/utils/getBackgroundColor';
import { Box } from '@mui/material';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import Animation from '@/components/Animation';

const CenteredContentSpot = ({ slice }) => {
  const { primary } = slice;

  const centredContentSpotTitle = primary && primary.centred_content_spot_title;
  const centredContentSpotDescription =
    primary && primary.centred_content_spot_description;
  const centredContentSpotImage = primary && primary.centred_content_spot_image;
  const centredContentSpotStyle = primary && primary.centred_content_spot_style;
  const centredContentSpotImageCaption =
    primary && primary.centred_content_spot_image_caption;

  const { backgroundColor, color } = getBackgroundColor(
    centredContentSpotStyle,
  );

  const isAnimation = primary?.media_type === 'animation';
  const isImage =
    centredContentSpotImage &&
    centredContentSpotImage.gatsbyImageData &&
    primary?.media_type !== 'animation';

  if (!primary) return <></>;

  return (
    <Section
      sx={{
        backgroundColor,
        py: { xs: 4, sm: 6, md: 8 },
        px: { xs: 3, sm: 4 },
      }}
    >
      <Box
        sx={{
          maxWidth: 'lg',
          width: 1,
          mx: 'auto',
          flexDirection: `column`,
          alignItems: `center`,
          justifyContent: `center`,
          display: `flex`,
          gap: 2,
        }}
      >
        {centredContentSpotTitle &&
          centredContentSpotTitle.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              variant={type}
              customVariant="heading2"
              sx={{ color, textAlign: 'center' }}
            >
              {text}
            </CustomTypography>
          ))}
        {centredContentSpotDescription &&
          centredContentSpotDescription.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              variant={type}
              customVariant="paragraphLargeFixed"
              sx={{ color, textAlign: 'center' }}
            >
              {text}
            </CustomTypography>
          ))}
        <Box sx={{ maxWidth: 960, maxHeight: isAnimation ? 600 : `auto` }}>
          {isImage && (
            <GatsbyImage
              alt={centredContentSpotImage.alt}
              image={centredContentSpotImage.gatsbyImageData}
            />
          )}
          {isAnimation && (
            <Animation
              id={slice.id}
              loop={primary.animation_loop}
              animationTrigger={primary.animation_trigger}
              rawAnimationData={primary.json_animation_data}
            />
          )}
        </Box>
        {centredContentSpotImageCaption &&
          centredContentSpotImageCaption.richText.map(({ type, text }) => (
            <Typography
              key={uuidv4()}
              variant={type}
              sx={{ color, textAlign: 'center' }}
            >
              {text}
            </Typography>
          ))}
      </Box>
    </Section>
  );
};

export const query = graphql`
  fragment FeatureCenteredContentSpot on PrismicFeatureDataBodyCenteredContentSpot {
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      centred_content_spot_title {
        richText
      }
      centred_content_spot_style
      centred_content_spot_image {
        alt
        gatsbyImageData
      }
      centred_content_spot_image_caption {
        richText
      }
      centred_content_spot_description {
        richText
      }
    }
  }

  fragment LandingPageCenteredContentSpot on PrismicLandingPageDataBodyCenteredContentSpot {
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      centred_content_spot_title {
        richText
      }
      centred_content_spot_style
      centred_content_spot_image {
        alt
        gatsbyImageData
      }
      centred_content_spot_image_caption {
        richText
      }
      centred_content_spot_description {
        richText
      }
    }
  }

  fragment LandingPageAfterPricingCenteredContentSpot on PrismicLandingPageDataBody1CenteredContentSpot {
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      centred_content_spot_title {
        richText
      }
      centred_content_spot_style
      centred_content_spot_image {
        alt
        gatsbyImageData
      }
      centred_content_spot_image_caption {
        richText
      }
      centred_content_spot_description {
        richText
      }
    }
  }

  fragment CompanyPageCenteredContentSpot on PrismicCompanyPageDataBodyCenteredContentSpot {
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      centred_content_spot_title {
        richText
      }
      centred_content_spot_style
      centred_content_spot_image {
        alt
        gatsbyImageData
      }
      centred_content_spot_image_caption {
        richText
      }
      centred_content_spot_description {
        richText
      }
    }
  }

  fragment JobPageCenteredContentSpot on PrismicJobPageDataBodyCenteredContentSpot {
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      centred_content_spot_title {
        richText
      }
      centred_content_spot_style
      centred_content_spot_image {
        alt
        gatsbyImageData
      }
      centred_content_spot_image_caption {
        richText
      }
      centred_content_spot_description {
        richText
      }
    }
  }

  fragment HomePageCenteredContentSpot on PrismicHomepageDataBodyCenteredContentSpot {
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      centred_content_spot_title {
        richText
      }
      centred_content_spot_style
      centred_content_spot_image {
        alt
        gatsbyImageData
      }
      centred_content_spot_image_caption {
        richText
      }
      centred_content_spot_description {
        richText
      }
    }
  }
`;

export default CenteredContentSpot;
