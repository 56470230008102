/* eslint-disable no-unused-expressions */
import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Box } from '@mui/material';
import { BaseComponentProps, Nav } from '@/types';
import Header, { GroupNav } from '@/components/Header/Header';
import Footer from '@/components/Footer/Footer';
import '@/styles/index.css';
import '@/styles/odometer-theme-default.css';
import { LanguageBannerContext } from '@/contexts/languageContext';

export interface LayoutProps extends BaseComponentProps {
  title: string;
  description: string;
  ogTitle: string;
  ogDescription: string;
  ogImageUrl: string;
  lang: string;
  altLangs: {
    id: string;
    type: string;
    lang: string;
    uid: string;
    // eslint-disable-next-line camelcase
    link_type: 'Document';
    tags: string[];
  }[];
}
const Layout = ({
  children,
  title,
  description,
  ogTitle,
  ogDescription,
  ogImageUrl,
  lang,
  altLangs,
}: LayoutProps): JSX.Element => {
  const { site, layoutEN, layoutZH, layoutJA } = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          description
          image
        }
      }
      layoutEN: prismicLayout(lang: { eq: "en-us" }) {
        ...LayoutFragment
      }
      layoutZH: prismicLayout(lang: { eq: "zh-tw" }) {
        ...LayoutFragment
      }
      layoutJA: prismicLayout(lang: { eq: "ja-jp" }) {
        ...LayoutFragment
      }
    }
  `);
  const { siteMetadata } = site;

  const layoutData = useMemo(() => {
    if (lang === `zh-tw`) {
      return layoutZH.data;
    }
    if (lang === `ja-jp`) {
      return layoutJA.data;
    }
    return layoutEN.data;
  }, [layoutZH, layoutJA, layoutEN]);

  // header data
  const headerData = useMemo(
    () => ({
      altLangs,
      lang,
      headerLogo: {
        gatsbyImageData: layoutData.header_logo.gatsbyImageData,
        alt: layoutData.header_logo.alt,
        link: layoutData.header_logo_link,
      },
      headerLogoMobile: {
        gatsbyImageData: layoutData.header_logo_mobile.gatsbyImageData,
        alt: layoutData.header_logo_mobile.alt,
      },
      navs: layoutData.body1.map(
        ({
          primary,
          items,
        }: {
          primary: Record<string, any>;
          items: [] | null;
        }) => {
          let result;
          if (items) {
            result = {} as GroupNav;
            result.title = primary.group_header_link_title;
            result.items = items.map(
              ({
                group_header_link_item_label,
                group_header_link_item_url,
                group_header_link_item_icon,
                group_header_link_item_description,
              }: Record<string, any>) => ({
                title: group_header_link_item_label,
                url: {
                  url: group_header_link_item_url.url,
                  target: group_header_link_item_url.target,
                },
                icon: group_header_link_item_icon.gatsbyImageData,
                description: {
                  type: group_header_link_item_description?.richText[0]?.type,
                  text: group_header_link_item_description?.richText[0]?.text,
                },
              }),
            );
          } else {
            result = {} as Nav;
            result.title = primary.single_header_link_label;
            result.url = {
              url: primary.single_header_link_url.url,
              target: primary.single_header_link_url.target,
            };
          }
          return result;
        },
      ),
      tertiaryCTA: {
        label: layoutData.header_tertiary_cta_label,
        url: {
          url: layoutData.header_tertiary_cta_link.url,
          target: layoutData.header_tertiary_cta_link.target,
        },
        mixpanelEvent: layoutData.header_tertiary_cta_mixpanel_event,
      },
      secondaryCTA: {
        label: layoutData.header_secondary_cta_label,
        url: {
          url: layoutData.header_secondary_cta_link.url,
          target: layoutData.header_secondary_cta_link.target,
        },
        mixpanelEvent: layoutData.header_secondary_cta_mixpanel_event,
      },
      mainCTA: {
        label: layoutData.header_main_cta_label,
        url: {
          url: layoutData.header_main_cta_link.url,
          target: layoutData.header_main_cta_link.target,
        },
        mixpanelEvent: layoutData.header_main_cta_mixpanel_event,
      },
    }),
    [layoutData],
  );

  // footer data
  const footerData = useMemo(
    () => ({
      altLangs,
      lang,
      footerLogo: {
        gatsbyImageData: layoutData.header_logo.gatsbyImageData,
        alt: layoutData.header_logo.alt,
        link: layoutData.footer_logo_link,
      },
      address: layoutData.logo_subtext.richText.map(
        ({ text }: { text: string }) => text,
      ),
      socialLinks: layoutData.social_links.map(
        ({
          social_icon,
          social_link_url,
          social_link_type,
        }: Record<string, any>) => ({
          icon: {
            gatsbyImageData: social_icon.gatsbyImageData,
            alt: social_icon.alt,
          },
          url: { url: social_link_url.url, target: social_link_url.target },
          type: social_link_type,
        }),
      ),
      footerGrids: layoutData.body.map(
        ({
          primary: {
            double_footer_grid_title: doubleFooterGridTitle,
            default_footer_grid_title: defaultFooterGridTitle,
          },
          items,
        }: {
          primary: Record<string, any>;
          items: [];
        }) => ({
          title: {
            text: doubleFooterGridTitle
              ? doubleFooterGridTitle.richText[0].text
              : defaultFooterGridTitle.richText[0].text,
            type: doubleFooterGridTitle
              ? doubleFooterGridTitle.richText[0].type
              : defaultFooterGridTitle.richText[0].type,
          },
          navs: items.map(
            ({
              double_footer_grid_link_label: doubleFooterGridLinklabel,
              default_footer_grid_link_label: defaultFooterGridLinklabel,
              double_footer_grid_link_url: doubleFooterGridLinkUrl,
              default_footer_grid_link_url: defaultFooterGridLinkUrl,
            }) => ({
              title: doubleFooterGridLinklabel || defaultFooterGridLinklabel,
              url: doubleFooterGridLinkUrl || defaultFooterGridLinkUrl,
            }),
          ),
        }),
      ),
    }),
    [layoutData],
  );

  const { isOpen } = React.useContext(LanguageBannerContext);

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: lang.slice(0, 2),
        }}
      >
        <meta charSet="utf-8" />
        <title>{`Rosetta.ai - ${title || siteMetadata.title}`}</title>
        <meta
          name="description"
          content={description || siteMetadata.description}
        />
        <meta property="og:site_name" content="Rosetta.ai" />
        <meta property="og:image" content={ogImageUrl || siteMetadata.image} />
        <meta property="og:title" content={ogTitle || siteMetadata.title} />
        <meta
          property="og:description"
          content={ogDescription || siteMetadata.description}
        />
      </Helmet>
      <Header {...headerData} />
      <Box sx={{ mt: { xs: !isOpen ? 0 : 19.25, md: !isOpen ? 0 : 12.5 } }}>
        {children}
      </Box>
      <Footer {...footerData} />
    </>
  );
};

export const query = graphql`
  fragment LayoutFragment on PrismicLayout {
    _previewable
    data {
      header_logo {
        alt
        copyright
        gatsbyImageData(height: 27)
      }
      header_logo_mobile {
        alt
        copyright
        gatsbyImageData(height: 26)
      }
      header_logo_link {
        url
        target
      }
      footer_logo_link {
        url
        target
      }
      body1 {
        ... on PrismicLayoutDataBody1HeaderLinkGroup {
          id
          primary {
            group_header_link_title
          }
          items {
            group_header_link_item_url {
              url
              target
            }
            group_header_link_item_label
            group_header_link_item_icon {
              gatsbyImageData
            }
            group_header_link_item_description {
              richText
            }
          }
        }
        ... on PrismicLayoutDataBody1HeaderLinkSingle {
          id
          primary {
            single_header_link_label
            single_header_link_url {
              url
            }
          }
        }
      }
      header_tertiary_cta_label
      header_tertiary_cta_mixpanel_event
      header_tertiary_cta_link {
        target
        url
      }
      header_secondary_cta_label
      header_secondary_cta_mixpanel_event
      header_secondary_cta_link {
        target
        url
      }
      header_main_cta_label
      header_main_cta_mixpanel_event
      header_main_cta_link {
        target
        url
      }
      body {
        ... on PrismicLayoutDataBodyDefaultFooterGrid {
          id
          slice_type
          items {
            default_footer_grid_link_label
            default_footer_grid_link_url {
              url
              target
            }
          }
          primary {
            default_footer_grid_title {
              richText
            }
          }
        }
        ... on PrismicLayoutDataBodyDoubleFooterGrid {
          id
          slice_type
          items {
            double_footer_grid_link_label
            double_footer_grid_link_url {
              url
              target
            }
          }
          primary {
            double_footer_grid_title {
              richText
            }
          }
        }
      }
      logo_subtext {
        richText
      }
      social_links {
        social_icon {
          gatsbyImageData(height: 20)
          alt
        }
        social_link_url {
          url
          target
        }
        social_link_type
      }
    }
  }
`;
export default Layout;
