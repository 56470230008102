import mixpanel from 'mixpanel-browser';

const sendButtonClickEvent = (label: string) => {
  mixpanel.track(`Button Click`, {
    label,
  });
};

const getStarted = (): void => {
  window.fbq?.(`track`, `AddToCart`);
  sendButtonClickEvent(`GetStarted`);
};
const startYour14DayTrial = (): void => {
  sendButtonClickEvent(`StartYour14DayTrial`);
};
const freeTrial = (): void => {
  sendButtonClickEvent(`14DayFreeTrial`);
};
const requestDemo = (): void => {
  sendButtonClickEvent(`RequestDemo`);
};
const contactUs = (): void => {
  sendButtonClickEvent(`ContactUs`);
};
const joinWaitingList = (): void => {
  sendButtonClickEvent(`JoinWaitingList`);
};
const seePricing = (): void => {
  sendButtonClickEvent(`SeePricing`);
};
const signUpForFree = (): void => {
  sendButtonClickEvent(`SignUpForFree`);
};
const getStartedForFree = (): void => {
  sendButtonClickEvent(`GetStartedForFree`);
};

const chooseYourPlan = (): void => {
  sendButtonClickEvent(`ChooseYourPlan`);
};

const buttonClickEvent = {
  getStarted,
  startYour14DayTrial,
  freeTrial,
  requestDemo,
  contactUs,
  joinWaitingList,
  seePricing,
  signUpForFree,
  getStartedForFree,
  chooseYourPlan,
};

const event = {
  button: buttonClickEvent,
};

export default event;
