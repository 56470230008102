import React from 'react';
import Section from '@/components/Section';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from '@mui/material';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import Animation from '@/components/Animation';

const ImageWithCaption = ({ slice }) => {
  const { primary } = slice;

  const isAnimation = primary?.media_type === 'animation';
  const isImage =
    primary?.image?.gatsbyImageData && primary?.media_type !== 'animation';

  if (!primary) return <></>;

  return (
    <Section sx={{ px: { xs: 3, sm: 4 }, py: 5 }}>
      <Box
        sx={{
          maxWidth: 'sm',
          width: 1,
          mx: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          textAlign: 'center',
        }}
      >
        {isImage && (
          <GatsbyImage
            alt={primary.image.alt}
            image={primary.image.gatsbyImageData}
          />
        )}
        {isAnimation && (
          <Animation
            id={slice.id}
            loop={primary.animation_loop}
            animationTrigger={primary.animation_trigger}
            rawAnimationData={primary.json_animation_data}
          />
        )}
        {primary?.caption?.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            customVariant="paragraphMedium"
            variant={type}
          >
            {text}
          </CustomTypography>
        ))}
      </Box>
    </Section>
  );
};

export const query = graphql`
  fragment FeatureImgaeWithCaption on PrismicFeatureDataBodyImageWithCaption {
    id
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      caption {
        richText
      }
      image {
        gatsbyImageData
        alt
      }
    }
    slice_type
  }
  fragment BlogPostImgaeWithCaption on PrismicBlogPostDataBodyImageWithCaption {
    id
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      caption {
        richText
      }
      image {
        gatsbyImageData
        alt
      }
    }
    slice_type
  }
  fragment LandingPageImageWithCaption on PrismicLandingPageDataBodyImageWithCaption {
    id
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      caption {
        richText
      }
      image {
        gatsbyImageData
        alt
      }
    }
    slice_type
  }

  fragment LandingPageAfterPricingImageWithCaption on PrismicLandingPageDataBody1ImageWithCaption {
    id
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      caption {
        richText
      }
      image {
        gatsbyImageData
        alt
      }
    }
    slice_type
  }

  fragment CompanyPageImageWithCaption on PrismicCompanyPageDataBodyImageWithCaption {
    id
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      caption {
        richText
      }
      image {
        gatsbyImageData
        alt
      }
    }
    slice_type
  }

  fragment JobPageImageWithCaption on PrismicJobPageDataBodyImageWithCaption {
    id
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      caption {
        richText
      }
      image {
        gatsbyImageData
        alt
      }
    }
    slice_type
  }
`;

export default ImageWithCaption;
