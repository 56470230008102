/* eslint-disable react/require-default-props */
import React, { MouseEvent } from 'react';
import MuiLink, { LinkBaseProps } from '@mui/material/Link';
import { Link as GatsbyLink, GatsbyLinkProps, navigate } from 'gatsby';
import event, { getDistinctId } from '@/event';
import { getPageLang } from '@/data/languages';

/**
 * Utils
 */
function getLngCode() {
  const lng = getPageLang();

  switch (lng) {
    case `ja-jp`:
      return `ja`;
    case `zh-tw`:
    case `en`:
      return lng;
    default:
      return `en`;
  }
}
type LinkType = 'internal' | 'external' | 'webapp';
const getLinkType = (href?: string): LinkType => {
  try {
    if (!href || href.startsWith(`/`)) return `internal`;

    const url = new URL(href);
    switch (url.hostname) {
      case `rosetta.ai`:
        return `internal`;
      case `dashboard.rosetta.ai`:
        return `webapp`;
      default:
        return `external`;
    }
  } catch (error) {
    return `external`;
  }
};

/**
 * Main Components
 */
const Link = React.forwardRef<
  HTMLAnchorElement,
  GatsbyLinkProps<Record<string, unknown>> &
    // eslint-disable-next-line react/require-default-props
    LinkBaseProps & {
      component?: GatsbyLink<unknown>;
      dataEventAction?: string;
    }
>((props, ref) => {
  const linkType = getLinkType(props.href);
  const { dataEventAction, href, ...rest } = props;

  const triggerMixpanelEvent = (eventName: string) => {
    switch (eventName) {
      case `btnClick_GetStarted`:
        event.click.button.getStarted();
        break;
      case `btnClick_StartYour14DayTrial`:
        event.click.button.startYour14DayTrial();
        break;
      case `btnClick_14DayFreeTrial`:
        event.click.button.freeTrial();
        break;
      case `btnClick_RequestDemo`:
        event.click.button.requestDemo();
        break;
      case `btnClick_ContactUs`:
        event.click.button.contactUs();
        break;
      case `btnClick_JoinWaitingList`:
        event.click.button.joinWaitingList();
        break;
      case `btnClick_SeePricing`:
        event.click.button.seePricing();
        break;
      case `btnClick_SignUpForFree`:
        event.click.button.signUpForFree();
        break;
      case `btnClick_GetStartedForFree`:
        event.click.button.getStartedForFree();
        break;
      case `btnClick_ChooseYourPlan`:
        event.click.button.chooseYourPlan();
        break;
      default:
    }
  };

  const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
    if (props.onClick) {
      props.onClick(e);
    }

    if (linkType === `webapp`) {
      e.preventDefault();

      // trigger mixpanel event
      if (dataEventAction) triggerMixpanelEvent(dataEventAction);

      // navigate to webapp
      const url = new URL(e.currentTarget.href);
      url.searchParams.set(`lng`, getLngCode());
      url.searchParams.append(`mp`, getDistinctId());
      window.open(url, `_blank`);
    }
  };

  const handleInternalLinkWithActionClick = (
    e: MouseEvent<HTMLAnchorElement>,
  ) => {
    e.preventDefault();

    // trigger mixpanel event
    if (dataEventAction) triggerMixpanelEvent(dataEventAction);

    // navigate to url
    const targetUrl = props.to;
    navigate(targetUrl);
  };

  if (dataEventAction && linkType === `internal`) {
    return (
      <MuiLink
        underline="none"
        color="inherit"
        {...rest}
        href={href}
        ref={ref}
        onClick={handleInternalLinkWithActionClick}
      />
    );
  }

  if (linkType === `internal`) {
    return (
      <MuiLink
        component={GatsbyLink}
        underline="none"
        {...rest}
        href={href}
        ref={ref}
        color="inherit"
      />
    );
  }

  return (
    <MuiLink
      underline="none"
      color="inherit"
      {...rest}
      href={href}
      ref={ref}
      onClick={handleClick}
    />
  );
});

export default Link;
