/* eslint-disable camelcase */
import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import { Box, Typography, Divider } from '@mui/material';

import { BaseComponentProps, Nav, UrlLike } from '@/types';
import Link from '@/components/Link';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { RiInstagramFill } from 'react-icons/ri';
import { LanguageSwitcher } from './LanguageSwitcher';

export interface FooterProps extends BaseComponentProps {
  footerLogo: { gatsbyImageData: IGatsbyImageData; alt: string; link: UrlLike };
  address: string[];
  socialLinks: Array<{
    icon: { gatsbyImageData: IGatsbyImageData; alt: string };
    url: UrlLike;
    type: string;
  }>;
  footerGrids: Array<{
    title: { type: string; text: string };
    navs: Nav[];
  }>;
  lang: string;
  altLangs: {
    id: string;
    type: string;
    lang: string;
    uid: string;
    link_type: 'Document';
    tags: string[];
  }[];
}

const socialIcon: any = {
  Facebook: (
    <Box sx={{ svg: { width: 10.15, height: 18.25 } }}>
      <FaFacebookF />
    </Box>
  ),
  Twitter: (
    <Box sx={{ svg: { width: 19.51, height: 16.25 } }}>
      <FaTwitter />
    </Box>
  ),
  Instagram: (
    <Box sx={{ svg: { width: 20, height: 20 } }}>
      <RiInstagramFill />
    </Box>
  ),
  LinkedIn: (
    <Box sx={{ svg: { width: 18, height: 18 } }}>
      <FaLinkedinIn />
    </Box>
  ),
};

const Footer = ({
  footerLogo,
  address,
  socialLinks,
  footerGrids,
  lang,
  altLangs,
}: FooterProps): JSX.Element => (
  <Box component="footer" sx={{ borderTop: `1px solid rgb(231,230,230)` }}>
    <Box sx={{ px: { xs: 3, sm: 4, md: 13.5 }, py: 5 }}>
      <Box
        sx={{
          gap: 8,
          overflow: `hidden`,
          maxWidth: `xl`,
          width: 1,
          mx: `auto`,
        }}
      >
        <Box
          sx={{
            display: `flex`,
            justifyContent: `space-between`,
            flexDirection: { xs: `column`, lg: `row` },
            flexWrap: `wrap`,
            gap: { xs: 5, sm: 9 },
          }}
        >
          <Box
            className="company-info"
            sx={{
              display: `flex`,
              flexDirection: `column`,
              justifyContent: { xs: `start`, lg: `space-between` },
            }}
          >
            <Box sx={{ display: `flex`, flexDirection: `column`, gap: 2 }}>
              <Link
                to={footerLogo.link.url}
                sx={{
                  display: `flex`,
                  alignItems: `center`,
                  width: { xs: (288 * 28) / 72, lg: (288 * 36) / 72 },
                }}
              >
                <GatsbyImage
                  image={footerLogo.gatsbyImageData}
                  alt={footerLogo.alt}
                />
              </Link>
              <Box sx={{ mb: { xs: 2, lg: 0 } }}>
                {address.map((string) => (
                  <Typography
                    key={string}
                    className="address"
                    sx={{
                      color: `#9D9999`,
                      fontWeight: 400,
                      fontSize: { xs: 12 },
                      lineHeight: 1.5,
                      textTransform: `none`,
                      letterSpacing: `-0.03px`,
                    }}
                  >
                    {string}
                  </Typography>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                gap: 2,
                display: `flex`,
                alignItems: `center`,
                '.gatsby-image-wrapper': {
                  height: 21,
                  width: 20,
                },
                svg: {
                  fill: `#858080`,
                  cursor: `pointer`,
                  transitionProperty: `all`,
                  transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
                  transitionDuration: `150ms`,
                  '&:hover': {
                    fill: `#231A1A`,
                  },
                },
              }}
            >
              {socialLinks.map(({ url, type }) => (
                <a key={url.url} href={url.url} target={url.target}>
                  {socialIcon[type]}
                </a>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: `flex`,
              flexWrap: `wrap`,
              rowGap: { xs: `32px`, sm: `72px`, md: `38px` },
              columnGap: { sm: `182px`, md: `38px` },
              flexDirection: { xs: `column`, sm: `row` },
            }}
          >
            <Box
              sx={{
                display: `flex`,
                flexDirection: `column`,
                gap: 5,
              }}
            >
              {footerGrids
                .filter((item, i) => i < 2)
                .map(({ title, navs }) => (
                  <Box
                    key={title.text}
                    sx={{
                      width: 170,
                      display: `flex`,
                      flexDirection: `column`,
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant={title.type as any}
                      sx={{ mb: 1, color: `#B6B3B3` }}
                    >
                      {title.text}
                    </Typography>
                    {navs.map(({ title: navTitle, url: { url } }) => (
                      <Typography key={navTitle} variant="textLarge">
                        <Link
                          to={url}
                          href={url}
                          sx={{
                            color: `#858080`,
                            '&:hover': {
                              color: `#231A1A`,
                            },
                            transitionProperty: `all`,
                            transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
                            transitionDuration: `150ms`,
                          }}
                        >
                          {navTitle}
                        </Link>
                      </Typography>
                    ))}
                  </Box>
                ))}
            </Box>
            {footerGrids
              .filter((item, i) => i > 1)
              .map(({ title, navs }) => (
                <Box
                  key={title.text}
                  sx={{
                    width: 170,
                    display: `flex`,
                    flexDirection: `column`,
                    gap: 2,
                  }}
                >
                  <Typography
                    variant={title.type as any}
                    sx={{ mb: 1, color: `#B6B3B3` }}
                  >
                    {title.text}
                  </Typography>
                  {navs.map(({ title: navTitle, url: { url } }) => (
                    <Typography key={navTitle} variant="textLarge">
                      <Link
                        to={url}
                        href={url}
                        sx={{
                          color: `#858080`,
                          '&:hover': {
                            color: `#231A1A`,
                          },
                          transitionProperty: `all`,
                          transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
                          transitionDuration: `150ms`,
                        }}
                      >
                        {navTitle}
                      </Link>
                    </Typography>
                  ))}
                </Box>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
    <Divider />
    <Box
      sx={{
        px: { xs: 3, sm: 4, md: 13.5 },
        display: `flex`,
        alignItems: { xs: `start`, md: `center` },
        justifyContent: `space-between`,
        flexDirection: { xs: `column-reverse`, md: `row` },
        my: { xs: 4, md: 5.375 },
        gap: 3,
      }}
    >
      <Box
        sx={{
          display: `flex`,
          flexWrap: `wrap`,
          gap: { xs: 3, sm: 6 },
          overflow: `hidden`,
          maxWidth: `xl`,
          width: 1,
          mx: `auto`,
        }}
      >
        <Typography variant="textLarge" sx={{ color: `#B6B3B3` }}>
          © {new Date().getFullYear()} • Rosetta, Inc • All rights reserved
        </Typography>
      </Box>
      <LanguageSwitcher lang={lang} altLangs={altLangs} />
    </Box>
  </Box>
);

export default Footer;
