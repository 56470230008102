import SplashSection from './SplashSection';
import ClientsGrid from './ClientsGrid';
import Stats from './Stats';
import Banner from './Banner';
import InlineGrid from './InlineGrid';
import ContentSpot from './ContentSpot';
import Platforms from './Platforms';
import TextGrid from './TextGrid';
import Tabs from './Tabs';
import NotificationBanner from './NotificationBanner';
import CenteredContentSpot from './CenteredContentSpot';
import FeatureSpotGrid from './FeatureSpotGrid';
import FAQs from './FAQs';
import Slides from './Slides';
import Video from './Video';
import RichText from './RichText';
import ImageWithCaption from './ImageWithCaption';
import PrimaryPricingTier from './PrimaryPricingTier';
import SecondaryPricingTier from './SecondaryPricingTier';
import AdditionalPricingSingleTable1 from './AdditionalPricingSingleTable1';
import AdditionalPricingSingleTable from './AdditionalPricingSingleTable';
import FeaturedPosts from './FeaturedPosts';
import CaseStudyDescription from './CaseStudyDescription';
import Quote from './Quote';
import CaseStudyInfo from './CaseStudyInfo';
import CaseStudyRichText from './CaseStudyRichText';
import PressRichText from './PressRichText';
import PressImageWithCaption from './PressImageWithCaption';
import PressContacts from './PressContacts';
import DownloadAllImages from './DownloadAllImages';
import Form from './Form';
import Testimonials from './Testimonials';
import LottieTest from './LottieTest';
import Tags from './Tags';
import MailChimpForm from './MailChimpForm';
import PipedriveForm from './PipedriveForm';
import RelatedPosts from './RelatedPosts';
import TypeFormEmbed from './TypeFormEmbed';
import Cta from './Cta';

export default {
  splash_section: SplashSection,
  clients_grid: ClientsGrid,
  stats: Stats,
  banner: Banner,
  inline_grid: InlineGrid,
  content_spot: ContentSpot,
  platforms: Platforms,
  text_grid: TextGrid,
  tabs: Tabs,
  notification_banner: NotificationBanner,
  centered_content_spot: CenteredContentSpot,
  feature_spot_grid: FeatureSpotGrid,
  faqs: FAQs,
  slides: Slides,
  video: Video,
  rich_text: RichText,
  image_with_caption: ImageWithCaption,
  primary_pricing_tier: PrimaryPricingTier,
  secondary_pricing_tier: SecondaryPricingTier,
  additional_pricing_single_table_1: AdditionalPricingSingleTable1,
  additional_pricing_single_table: AdditionalPricingSingleTable,
  featured_posts: FeaturedPosts,
  case_study_description: CaseStudyDescription,
  quote: Quote,
  case_study_info: CaseStudyInfo,
  case_study_rich_text: CaseStudyRichText,
  press_rich_text: PressRichText,
  press_image_with_caption: PressImageWithCaption,
  press_contacts: PressContacts,
  download_all_images: DownloadAllImages,
  form: Form,
  testimonials: Testimonials,
  lottie_test: LottieTest,
  tags: Tags,
  mailchimp_form: MailChimpForm,
  pipedrive_form: PipedriveForm,
  related_posts: RelatedPosts,
  typeform_embed: TypeFormEmbed,
  cta: Cta,
};
