/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box } from '@mui/material';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';
import Section from '../Section';
import Link from '../Link';

type NextPrevPostsProps = {
  lang: string;
  prevUrl: string;
  nextUrl: string;
  prevTitle: string;
  nextTitle: string;
  // eslint-disable-next-line react/require-default-props
  caseStudyPage?: boolean;
};

export const NextPrevPosts = ({
  lang,
  prevUrl,
  nextUrl,
  prevTitle,
  nextTitle,
  caseStudyPage = false,
}: NextPrevPostsProps): React.ReactNode => (
  <Section
    sx={{
      px: { xs: 3, sm: 4 },
      py: { lg: 5 },
      pb: 5,
      display: `flex`,
      flexDirection: `column`,
    }}
  >
    <Box
      sx={{
        maxWidth: caseStudyPage ? 600 : 800,
        mx: `auto`,
        display: `flex`,
        flexDirection: { xs: `column`, sm: `row` },
        justifyContent: `space-between`,
        width: 1,
        gap: 3,
      }}
    >
      {prevUrl && prevTitle ? (
        <Link
          to={prevUrl}
          sx={{
            display: `flex`,
            flexDirection: `column`,
            maxWidth: { xs: `none`, sm: 350 },
            width: 1,
            gap: 2,
            backgroundColor: `#F8F8F8`,
            p: 2,
          }}
        >
          <Box
            component="span"
            sx={{ display: `flex`, alignItems: `center`, gap: 1 }}
          >
            <HiArrowLeft />
            {` `}
            {lang === `zh-tw`
              ? `上一篇`
              : lang === `ja-jp`
              ? `前の記事へ`
              : `Previous`}
          </Box>
          <span>{prevTitle}</span>
        </Link>
      ) : (
        <Box />
      )}
      {nextUrl && nextTitle ? (
        <Link
          to={nextUrl}
          sx={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `flex-end`,
            textAlign: `right`,
            maxWidth: { xs: `none`, sm: 350 },
            width: 1,
            gap: 2,
            backgroundColor: `#F8F8F8`,
            p: 2,
          }}
        >
          <Box
            component="span"
            sx={{ display: `flex`, alignItems: `center`, gap: 1 }}
          >
            {lang === `zh-tw`
              ? `下一篇`
              : lang === `ja-jp`
              ? `次の記事へ`
              : `Next`}
            {` `}
            <HiArrowRight />
          </Box>
          <span>{nextTitle}</span>
        </Link>
      ) : (
        <Box />
      )}
    </Box>
  </Section>
);
