import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { v4 as uuidv4 } from 'uuid';

import { Box, Tabs as MuiTabs, Tab as MuiTab } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';

import Section from '@/components/Section';
import Typography from '@/components/Typography';
import getBackgroundColor from '@/utils/getBackgroundColor';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import Link from '@/components/Link';

const allProps = (index) => ({
  id: `casestudy-tab-${index}`,
  'aria-controls': `casestudy-tabpanel-${index}`,
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`casestudy-tabpanel-${index}`}
      aria-labelledby={`casestudy-tab-${index}`}
      {...other}
      sx={{
        display: `flex`,
        flexDirection: `column`,
        gap: 3,
        alignItems: `center`,
        textAlign: `center `,
        overflow: `hidden`,
      }}
    >
      {value === index && children}
    </Box>
  );
};

const Tabs = ({ slice }) => {
  const [value, setValue] = useState(0);
  const {
    items,
    primary: {
      tabs_section_style: tabsSectionStyle,
      tabs_section_title: tabsSectionTitle,
      tabs_section_description: tabsSectionDescription,
    },
  } = slice;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const { backgroundColor, color, subColor } =
    getBackgroundColor(tabsSectionStyle);

  if (!slice.primary) return <></>;

  return (
    <Section
      sx={{
        flexDirection: `column`,
        backgroundColor,
        alignItems: `center`,
        gap: 5,
        overflow: `hidden`,
        px: { xs: 3, sm: 10, md: 13.5 },
        pt: { xs: 4, sm: 6, md: 8 },
        pb: { xs: 5, sm: 6, md: 8 },
      }}
    >
      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
          maxWidth: 808,
        }}
      >
        {tabsSectionTitle.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            customVariant="heading4"
            variant={type}
            sx={{ color, textAlign: `center` }}
          >
            {text}
          </CustomTypography>
        ))}
        {tabsSectionDescription.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            customVariant="heading4"
            variant={type}
            sx={{ color, textAlign: `center`, lineHeight: 1.4 }}
          >
            {text}
          </CustomTypography>
        ))}
      </Box>
      <Box sx={{ maxWidth: 808, color, height: 1 }}>
        <MuiTabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="secondary"
          variant="standard"
          centered
          sx={{
            mb: 3,
          }}
        >
          {items &&
            items.length > 0 &&
            items.map(({ tab_item_title: tabItemTitle }, index) => (
              <MuiTab
                key={uuidv4()}
                label={tabItemTitle}
                sx={{
                  color: `#B6B3B3`,
                  opacity: 1,
                  textTransform: `none`,
                  fontSize: { xs: `14px`, sm: `24px` },
                  lineHeight: { xs: 1.5, sm: 1.33 },
                  fontWeight: 600,
                  letterSpacing: 0,
                  borderBottom: `1px solid #E7E6E6`,
                  mb: `-0.01rem`,
                  pt: { xs: `26px`, sm: 2 },
                  pb: { xs: `30px`, sm: 4 },
                  width: { md: 200 },
                  '&.Mui-selected': {
                    color: `#544D4D`,
                  },
                }}
                {...allProps(index)}
              />
            ))}
        </MuiTabs>
        <SwipeableViews
          onChangeIndex={handleChangeIndex}
          index={value}
          axis="x"
        >
          {items &&
            items.length > 0 &&
            items.map(
              (
                {
                  tab_item_post: tabItemPost,
                  tab_item_primary_cta_label: tabItemPrimaryCtaLabel,
                  tab_item_primary_cta_link: tabItemPrimaryCtaLink,
                  tab_item_primary_cta_mixpanel_event:
                    tabItemPrimaryCtaMixpanelEvent,
                  tab_item_secondary_cta_label: tabItemSecondaryCtaLabel,
                  tab_item_secondary_cta_link: tabItemSecondaryCtaLink,
                  tab_item_secondary_cta_mixpanel_event:
                    tabItemSecondaryCtaMixpanelEvent,
                },
                index,
              ) => (
                <TabPanel key={uuidv4()} value={value} index={index}>
                  {tabItemPost.document && (
                    <>
                      {tabItemPost.document.data
                        .case_study_background_image && (
                        <GatsbyImage
                          alt={
                            tabItemPost.document.data
                              .case_study_background_image.alt
                          }
                          image={
                            tabItemPost.document.data
                              .case_study_background_image.gatsbyImageData
                          }
                        />
                      )}
                      {tabItemPost.document.data.blog_post_image && (
                        <GatsbyImage
                          alt={tabItemPost.document.data.blog_post_image.alt}
                          image={
                            tabItemPost.document.data.blog_post_image
                              .gatsbyImageData
                          }
                        />
                      )}
                      {tabItemPost.document.data.case_study_headline &&
                        tabItemPost.document.data.case_study_headline.richText.map(
                          ({ text }) => (
                            <CustomTypography
                              key={uuidv4()}
                              customVariant="heading3"
                              variant="heading3"
                              sx={{ color, mb: -0.5 }}
                            >
                              {text}
                            </CustomTypography>
                          ),
                        )}
                      {tabItemPost.document.data.case_study_description &&
                        tabItemPost.document.data.case_study_description.richText.map(
                          ({ text, type }) => (
                            <CustomTypography
                              key={uuidv4()}
                              customVariant="paragraph"
                              variant={type}
                              sx={{ color }}
                            >
                              {text}
                            </CustomTypography>
                          ),
                        )}
                      {tabItemPost.document.data.blog_post_title &&
                        tabItemPost.document.data.blog_post_title.richText.map(
                          ({ text, type }) => (
                            <Typography
                              key={uuidv4()}
                              variant={type}
                              sx={{ color }}
                            >
                              {text}
                            </Typography>
                          ),
                        )}
                      {tabItemPost.document.data.blog_post_description &&
                        tabItemPost.document.data.blog_post_description.richText.map(
                          ({ text, type }) => (
                            <CustomTypography
                              key={uuidv4()}
                              customVariant="paragraph"
                              variant={type}
                              sx={{ color }}
                            >
                              {text}
                            </CustomTypography>
                          ),
                        )}
                    </>
                  )}
                  <Box
                    sx={{
                      display: `flex`,
                      gap: 3,
                      justifyContent: `center`,
                      mt: 0.5,
                    }}
                  >
                    <Link
                      href={tabItemPrimaryCtaLink.url}
                      target={tabItemPrimaryCtaLink.target}
                      dataEventAction={tabItemPrimaryCtaMixpanelEvent}
                      sx={{
                        color: `#5298D5`,
                        gap: 1,
                        fontWeight: 600,
                        display: `flex`,
                        alignItems: `center`,
                        img: {
                          width: 16,
                          height: 12,
                          transitionProperty: 'transform',
                          transitionTimingFunction:
                            'cubic-bezier(0.4, 0, 0.2, 1)',
                          transitionDuration: '0.15s',
                        },
                        '&:hover': {
                          img: {
                            transform: `translate(4px, 0px)`,
                          },
                        },
                      }}
                    >
                      {tabItemPrimaryCtaLabel}
                      <img src="/blue-arrow.svg" alt="blue-arrow" />
                    </Link>
                    <Link
                      href={tabItemSecondaryCtaLink.url}
                      target={tabItemSecondaryCtaLink.target}
                      dataEventAction={tabItemSecondaryCtaMixpanelEvent}
                      sx={{
                        color: subColor,
                        display: `flex`,
                        gap: 1,
                        fontWeight: 600,
                        alignItems: `center`,
                        img: {
                          width: 16,
                          height: 12,
                          transitionProperty: 'transform',
                          transitionTimingFunction:
                            'cubic-bezier(0.4, 0, 0.2, 1)',
                          transitionDuration: '0.15s',
                        },
                        '&:hover': {
                          img: {
                            transform: `translate(4px, 0px)`,
                          },
                        },
                      }}
                    >
                      {tabItemSecondaryCtaLabel}
                      <img src="/gray-arrow.svg" alt="gray-arrow" />
                    </Link>
                  </Box>
                </TabPanel>
              ),
            )}
        </SwipeableViews>
      </Box>
    </Section>
  );
};

export default Tabs;

export const query = graphql`
  fragment HomepageTabs on PrismicHomepageDataBodyTabs {
    id
    items {
      tab_item_icon {
        gatsbyImageData
        alt
      }
      tab_item_post {
        url
        target
        document {
          ... on PrismicBlogPost {
            id
            data {
              blog_post_image {
                gatsbyImageData
                alt
              }
              blog_post_description {
                richText
              }
              blog_post_title {
                richText
              }
            }
          }
          ... on PrismicCaseStudyPage {
            data {
              case_study_background_image {
                gatsbyImageData
                alt
              }
              case_study_headline {
                richText
              }
              case_study_description {
                richText
              }
            }
          }
        }
      }
      tab_item_primary_cta_label
      tab_item_primary_cta_mixpanel_event
      tab_item_primary_cta_link {
        target
        url
      }
      tab_item_secondary_cta_label
      tab_item_secondary_cta_mixpanel_event
      tab_item_secondary_cta_link {
        target
        url
      }
      tab_item_title
    }
    primary {
      tabs_section_description {
        richText
      }
      tabs_section_style
      tabs_section_title {
        richText
      }
    }
    slice_type
  }

  fragment FeatureTabs on PrismicFeatureDataBodyTabs {
    id
    items {
      tab_item_icon {
        gatsbyImageData
        alt
      }
      tab_item_post {
        url
        target
        raw
        document {
          ... on PrismicBlogPost {
            id
            data {
              blog_post_image {
                gatsbyImageData
                alt
              }
              blog_post_description {
                richText
              }
              blog_post_title {
                richText
              }
            }
          }
          ... on PrismicCaseStudyPage {
            data {
              case_study_background_image {
                gatsbyImageData
                alt
              }
              case_study_headline {
                richText
              }
              case_study_description {
                richText
              }
            }
          }
        }
      }
      tab_item_primary_cta_label
      tab_item_primary_cta_mixpanel_event
      tab_item_primary_cta_link {
        target
        url
      }
      tab_item_secondary_cta_label
      tab_item_secondary_cta_mixpanel_event
      tab_item_secondary_cta_link {
        target
        url
      }
      tab_item_title
    }
    primary {
      tabs_section_description {
        richText
      }
      tabs_section_style
      tabs_section_title {
        richText
      }
    }
    slice_type
  }

  fragment LandingPageAfterPricingTabs on PrismicLandingPageDataBody1Tabs {
    id
    items {
      tab_item_icon {
        gatsbyImageData
        alt
      }
      tab_item_post {
        url
        target
        raw
        document {
          ... on PrismicBlogPost {
            id
            data {
              blog_post_image {
                gatsbyImageData
                alt
              }
              blog_post_description {
                richText
              }
              blog_post_title {
                richText
              }
            }
          }
          ... on PrismicCaseStudyPage {
            data {
              case_study_background_image {
                gatsbyImageData
                alt
              }
              case_study_headline {
                richText
              }
              case_study_description {
                richText
              }
            }
          }
        }
      }
      tab_item_primary_cta_label
      tab_item_primary_cta_mixpanel_event
      tab_item_primary_cta_link {
        target
        url
      }
      tab_item_secondary_cta_label
      tab_item_secondary_cta_mixpanel_event
      tab_item_secondary_cta_link {
        target
        url
      }
      tab_item_title
    }
    primary {
      tabs_section_description {
        richText
      }
      tabs_section_style
      tabs_section_title {
        richText
      }
    }
    slice_type
  }

  fragment LandingPageTabs on PrismicLandingPageDataBodyTabs {
    id
    items {
      tab_item_icon {
        gatsbyImageData
        alt
      }
      tab_item_post {
        url
        target
        raw
        document {
          ... on PrismicBlogPost {
            id
            data {
              blog_post_image {
                gatsbyImageData
                alt
              }
              blog_post_description {
                richText
              }
              blog_post_title {
                richText
              }
            }
          }
          ... on PrismicCaseStudyPage {
            data {
              case_study_background_image {
                gatsbyImageData
                alt
              }
              case_study_headline {
                richText
              }
              case_study_description {
                richText
              }
            }
          }
        }
      }
      tab_item_primary_cta_label
      tab_item_primary_cta_mixpanel_event
      tab_item_primary_cta_link {
        target
        url
      }
      tab_item_secondary_cta_label
      tab_item_secondary_cta_mixpanel_event
      tab_item_secondary_cta_link {
        target
        url
      }
      tab_item_title
    }
    primary {
      tabs_section_description {
        richText
      }
      tabs_section_style
      tabs_section_title {
        richText
      }
    }
    slice_type
  }
`;
