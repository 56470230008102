import { ButtonUnstyled } from '@mui/base';
import { styled } from '@mui/system';
import React from 'react';

const StyledCloseButton = styled(ButtonUnstyled)`
  border: none;
  padding: 0px;
  background: none;
  cursor: pointer;
  padding-top: 6px;
`;

export const CloseButton = ({
  clickHandler,
}: {
  clickHandler: (bool: boolean) => void;
}): JSX.Element => (
  <StyledCloseButton onClick={clickHandler}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.88 8L15.6 2.28C15.8533 2.04 16 1.70667 16 1.33333C16 0.6 15.4 0 14.6667 0C14.2933 0 13.96 0.146667 13.72 0.386667L8 6.12L2.28 0.386667C2.04 0.146667 1.70667 0 1.33333 0C0.6 0 0 0.6 0 1.33333C0 1.70667 0.146667 2.04 0.386667 2.28L6.12 8L0.4 13.72C0.146667 13.96 0 14.2933 0 14.6667C0 15.4 0.6 16 1.33333 16C1.70667 16 2.04 15.8533 2.28 15.6133L8 9.88L13.72 15.6C13.96 15.8533 14.2933 16 14.6667 16C15.4 16 16 15.4 16 14.6667C16 14.2933 15.8533 13.96 15.6133 13.72L9.88 8Z"
        fill="#544D4D"
      />
    </svg>
  </StyledCloseButton>
);
