import { ButtonUnstyled } from '@mui/base';
import { Box, styled } from '@mui/material';
import React from 'react';

export const HamburgerIcon = (): JSX.Element => (
  <Box
    sx={{
      display: `flex`,
      flexDirection: `column`,
      justifyContent: `space-between`,
      width: `24px`,
      height: `14.4px`,
    }}
  >
    <Box
      sx={{
        height: `2.4px`,
        width: 1,
        backgroundColor: `#544D4D`,
      }}
    />
    <Box
      sx={{
        height: `2.4px`,
        width: 1,
        backgroundColor: `#544D4D`,
      }}
    />
    <Box
      sx={{
        height: `2.4px`,
        width: 1,
        backgroundColor: `#544D4D`,
      }}
    />
  </Box>
);

const StyledHamburgerButton = styled(ButtonUnstyled)`
  border: none;
  padding: 0px;
  background: none;
  cursor: pointer;
`;

export const HamburgerButton = ({
  clickHandler,
}: {
  clickHandler: (bool: boolean) => void;
}): JSX.Element => (
  <StyledHamburgerButton onClick={clickHandler}>
    <HamburgerIcon />
  </StyledHamburgerButton>
);
