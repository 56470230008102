import { navigate } from 'gatsby';

export const languages: { [key: string]: string } = {
  'en-us': `English`, // EN
  'zh-tw': `繁體中文`, // ZH
  'ja-jp': `日本語`, // JA
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function parseLng(lng: string) {
  const fullLangName = Object.keys(languages).find((item) =>
    item.includes(lng),
  );
  // Return early if a fullLang name cannot be found
  // Its likely that the users language is not supported
  if (!fullLangName) return;

  // eslint-disable-next-line consistent-return
  return fullLangName;
}

export const getStorageLng = (): string | null => localStorage.getItem(`lng`);
export const setStorageLng = (lng: string): void =>
  localStorage.setItem(`lng`, lng || `en-us`);

export function getBrowserLng(): string | undefined {
  // find the browser language
  const lngInStorage = getStorageLng();
  if (lngInStorage && lngInStorage in languages) return lngInStorage;
  const navigatorLng = navigator.language.substring(0, 2);
  const lng = parseLng(navigatorLng);
  return lng;
}

export function getPageLang(): string | null {
  const urlLang = window.location.pathname;

  const urlLangString = urlLang.slice(1, 6);
  if (urlLangString in languages) return urlLangString;

  return null;
}

function isLngMatch(targetLng: 'en' | 'ja' | 'zh', lng: string) {
  return lng.slice(0, 2) === targetLng;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function redirect() {
  const targetLng = getBrowserLng();
  const pageLng = getPageLang();
  // redirect to the route for the browser language
  // Only if route does not exist in list of excluded routes
  // And browser language is not English
  if (
    targetLng &&
    !isLngMatch(`en`, targetLng) &&
    !pageLng &&
    targetLng !== pageLng
  ) {
    navigate(`/${targetLng}${window.location.pathname}`);
  }
}
