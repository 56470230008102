import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import { Box } from '@mui/material';

import Section from '@/components/Section';
import getBackgroundColor from '@/utils/getBackgroundColor';
import Typography from '@/components/Typography';
import { CustomTypography } from '@/components/Typography/CustomTypography';

const CaseStudyDescription = ({ slice }) => {
  const { primary } = slice;
  const { title, description, descriptionStyle } = useMemo(() => {
    if (primary) {
      return {
        title: primary.title,
        description: primary.description,
        descriptionStyle: primary.description_style,
      };
    }
    return {};
  }, [primary]);
  const { backgroundColor, color } = getBackgroundColor(descriptionStyle);
  return (
    <Section
      sx={{
        backgroundColor,
        color,
        overflow: `hidden`,
        px: { xs: 3, sm: 4, md: 13.5 },
        py: { xs: 5, md: 7.375, lg: 5.875 },
      }}
    >
      <Box
        sx={{
          maxWidth: 'xl',
          mx: 'auto',
          width: 1,
        }}
      >
        <Box
          sx={{
            maxWidth: 800,
            mr: 'auto',
            display: `flex`,
            flexDirection: `column`,
            gap: 2,
          }}
        >
          {title.richText.map(({ text, type }) => (
            <Typography key={uuidv4()} variant={type}>
              {text}
            </Typography>
          ))}
          {description.richText.map(({ text, type }) => (
            <CustomTypography
              customVariant="paragraphLarge"
              key={uuidv4()}
              variant={type}
            >
              {text}
            </CustomTypography>
          ))}
        </Box>
      </Box>
    </Section>
  );
};
export default CaseStudyDescription;

export const query = graphql`
  fragment CaseStudyDescription on PrismicCaseStudyPageDataBodyCaseStudyDescription {
    id
    primary {
      title {
        richText
      }
      description {
        richText
      }
      description_style
    }
    slice_type
  }
`;
