import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { v4 as uuidv4 } from 'uuid';

import { Box } from '@mui/material';

import Section from '@/components/Section';
import Link from '@/components/Link';
import getBackgroundColor from '@/utils/getBackgroundColor';
import { getLinkProps } from '@/utils/getLinkProps';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import Animation from '@/components/Animation';

const ContentSpot = ({ slice }) => {
  const { items, primary } = slice;

  if (!primary) return <></>;

  const {
    content_spot_style: contentSpotStyle,
    content_spot_image: contentSpotImage,
    content_spot_title: contentSpotTitle,
    content_spot_description: contentSpotDescription,
    reverse_order: reverseOrder,
  } = primary;

  const isAnimation = primary?.media_type === 'animation';
  const isImage =
    contentSpotImage.gatsbyImageData && primary?.media_type !== 'animation';

  const { backgroundColor, color } = getBackgroundColor(contentSpotStyle);
  return (
    <Section
      sx={{
        backgroundColor,
        overflow: `hidden`,
        px: { xs: 3, sm: 4, md: 13.5 },
        pb: { xs: 5, sm: 7, md: 9 },
        pt: { xs: 4, sm: 6, md: 8 },
      }}
    >
      <Box
        sx={{
          maxWidth: 'xl',
          mx: 'auto',
          display: `flex`,
          gap: 3,
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: {
            xs: 'column',
            lg: reverseOrder ? 'row-reverse' : 'row',
          },
        }}
      >
        <Box
          sx={{
            mx: 'auto',
            width: 1,
            maxWidth: { md: 680 },
            minWidth: { xs: 0, lg: 500, xl: 600 },
          }}
        >
          {isImage && (
            <GatsbyImage
              alt={contentSpotImage.alt}
              image={contentSpotImage.gatsbyImageData}
            />
          )}
          {isAnimation && (
            <Animation
              id={slice.id}
              loop={primary.animation_loop}
              animationTrigger={primary.animation_trigger}
              rawAnimationData={primary.json_animation_data}
            />
          )}
        </Box>
        <Box
          sx={{
            display: `flex`,
            flexDirection: `column`,
            gap: 2,
            maxWidth: 496,
          }}
        >
          {contentSpotTitle.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              variant={type}
              customVariant="heading2"
              sx={{ color }}
            >
              {text}
            </CustomTypography>
          ))}
          {contentSpotDescription.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              variant={type}
              customVariant="paragraphLargeFixed"
              sx={{ color }}
            >
              {text}
            </CustomTypography>
          ))}
          <Box sx={{ display: `flex`, gap: 3 }}>
            {items &&
              items.length > 0 &&
              items.map(
                (
                  {
                    content_spot_cta_link: contentSpotCtaLink,
                    content_spot_cta_label: contentSpotCtaLabel,
                    content_spot_cta_mixpanel_event:
                      contentSpotCtaMixpanelEvent,
                  },
                  index,
                ) =>
                  contentSpotCtaLink.url && (
                    <Link
                      key={uuidv4()}
                      {...getLinkProps(
                        contentSpotCtaLink.target,
                        contentSpotCtaLink.url,
                      )}
                      dataEventAction={contentSpotCtaMixpanelEvent}
                      sx={{
                        color: index ? color : `#5298D5`,
                        gap: 1,
                        fontWeight: 600,
                        display: `flex`,
                        alignItems: `center`,
                        img: {
                          width: 16,
                          height: 12,
                          transitionProperty: 'transform',
                          transitionTimingFunction:
                            'cubic-bezier(0.4, 0, 0.2, 1)',
                          transitionDuration: '0.15s',
                        },
                        '&:hover': {
                          img: {
                            transform: `translate(4px, 0px)`,
                          },
                        },
                      }}
                    >
                      {contentSpotCtaLabel}
                      {index ? (
                        <img src="/white-arrow.svg" alt="white-arrow" />
                      ) : (
                        <img src="/blue-arrow.svg" alt="blue-arrow" />
                      )}
                    </Link>
                  ),
              )}
          </Box>
        </Box>
      </Box>
    </Section>
  );
};

export default ContentSpot;

export const query = graphql`
  fragment HomepageContentSpot on PrismicHomepageDataBodyContentSpot {
    id
    items {
      content_spot_cta_label
      content_spot_cta_mixpanel_event
      content_spot_cta_link {
        url
        target
      }
    }
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      content_spot_title {
        richText
      }
      content_spot_style
      content_spot_image {
        alt
        gatsbyImageData
      }
      content_spot_description {
        richText
      }
      reverse_order
    }
    slice_type
  }

  fragment FeatureContentSpot on PrismicFeatureDataBodyContentSpot {
    id
    items {
      content_spot_cta_label
      content_spot_cta_mixpanel_event
      content_spot_cta_link {
        url
        target
      }
    }
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      content_spot_title {
        richText
      }
      content_spot_style
      content_spot_image {
        alt
        gatsbyImageData
      }
      content_spot_description {
        richText
      }
      reverse_order
    }
    slice_type
  }

  fragment CaseStudyContentSpot on PrismicCaseStudyPageDataBodyContentSpot {
    id
    items {
      content_spot_cta_label
      content_spot_cta_mixpanel_event
      content_spot_cta_link {
        url
        target
      }
    }
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      content_spot_title {
        richText
      }
      content_spot_style
      content_spot_image {
        alt
        gatsbyImageData(width: 600)
      }
      content_spot_description {
        richText
      }
      reverse_order
    }
    slice_type
  }

  fragment LandingPageContentSpot on PrismicLandingPageDataBodyContentSpot {
    id
    items {
      content_spot_cta_label
      content_spot_cta_mixpanel_event
      content_spot_cta_link {
        url
        target
      }
    }
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      content_spot_title {
        richText
      }
      content_spot_style
      content_spot_image {
        alt
        gatsbyImageData(width: 600)
      }
      content_spot_description {
        richText
      }
      reverse_order
    }
    slice_type
  }

  fragment LandingPageAfterPricingContentSpot on PrismicLandingPageDataBody1ContentSpot {
    id
    items {
      content_spot_cta_label
      content_spot_cta_mixpanel_event
      content_spot_cta_link {
        url
        target
      }
    }
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      content_spot_title {
        richText
      }
      content_spot_style
      content_spot_image {
        alt
        gatsbyImageData(width: 600)
      }
      content_spot_description {
        richText
      }
      reverse_order
    }
    slice_type
  }

  fragment CompanyPageContentSpot on PrismicCompanyPageDataBodyContentSpot {
    id
    items {
      content_spot_cta_label
      content_spot_cta_mixpanel_event
      content_spot_cta_link {
        url
        target
      }
    }
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      content_spot_title {
        richText
      }
      content_spot_style
      content_spot_image {
        alt
        gatsbyImageData(width: 600)
      }
      content_spot_description {
        richText
      }
      reverse_order
    }
    slice_type
  }

  fragment JobPageContentSpot on PrismicJobPageDataBodyContentSpot {
    id
    items {
      content_spot_cta_label
      content_spot_cta_mixpanel_event
      content_spot_cta_link {
        url
        target
      }
    }
    primary {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      content_spot_title {
        richText
      }
      content_spot_style
      content_spot_image {
        alt
        gatsbyImageData(width: 600)
      }
      content_spot_description {
        richText
      }
      reverse_order
    }
    slice_type
  }
`;
