import React, { useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { v4 as uuidv4 } from 'uuid';

import { Box } from '@mui/material';

import Section from '@/components/Section';
import getBackgroundColor from '@/utils/getBackgroundColor';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import { getVideoSectionColors } from '@/utils/getVideoSectionColors';

const Slides = ({ slice }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const { items, primary } = slice;
  const { slidesStyle, slidesTitle } = useMemo(() => {
    if (primary) {
      return {
        slidesStyle: primary?.slides_style,
        slidesTitle: primary?.slides_title,
      };
    }
    return {};
  }, [primary]);

  const handleSlideIndexChange = (index) => {
    setSlideIndex(index);
  };

  const { backgroundColor } = getBackgroundColor(slidesStyle);

  const sectionTheme = slidesStyle?.includes('Black') ? 'dark' : 'light';
  const { titleColor, descriptionColor, unselectedColor, notchColor } =
    getVideoSectionColors(sectionTheme);

  if (!primary) return <></>;
  return (
    <Section
      sx={{
        backgroundColor,
        px: { xs: 3, sm: 4 },
      }}
    >
      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          gap: 4,
          alignItems: `center`,
          maxWidth: 'xl',
          mx: 'auto',
          width: { lg: 1 },
        }}
      >
        {slidesTitle.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            variant={type}
            customVariant="heading3"
            sx={{ color: titleColor }}
          >
            {text}
          </CustomTypography>
        ))}
        <Box
          sx={{
            display: `flex`,
            gap: 3,
            flexDirection: { xs: `column`, lg: `row` },
            width: 1,
            justifyContent: { xs: `left`, lg: `center` },
          }}
        >
          <Box sx={{ position: `relative` }}>
            {items &&
              items.length > 0 &&
              items.map(
                ({ slide_item_image: slideItemImage }, index) =>
                  index === slideIndex && (
                    <Box key={uuidv4()}>
                      <GatsbyImage
                        alt={slideItemImage.alt}
                        image={slideItemImage.gatsbyImageData}
                      />
                    </Box>
                  ),
              )}
          </Box>
          <Box
            sx={{
              display: `flex`,
              flexDirection: 'column',
              gap: 3,
              maxWidth: { lg: 552 },
              pr: 3,
              overflow: `auto`,
              justifyContent: `center`,
            }}
          >
            {items &&
              items.length > 0 &&
              items.map(
                (
                  {
                    slide_item_description: slideItemDescription,
                    slide_item_title: slideItemTitle,
                  },
                  index,
                ) => (
                  <Box
                    onClick={() => handleSlideIndexChange(index)}
                    sx={{
                      pl: 2,
                      borderLeft:
                        slideIndex === index && `8px ${notchColor} solid`,
                      maxWidth: { sm: 520, lg: 'none' },
                      cursor: `pointer`,
                    }}
                    key={uuidv4()}
                  >
                    {slideItemTitle.richText.map(({ text, type }) => (
                      <CustomTypography
                        key={uuidv4()}
                        variant={type}
                        customVariant="heading3"
                        sx={{
                          color:
                            slideIndex === index ? titleColor : unselectedColor,
                        }}
                      >
                        {text}
                      </CustomTypography>
                    ))}
                    {slideItemDescription.richText.map(({ text, type }) => (
                      <CustomTypography
                        key={uuidv4()}
                        customVariant="paragraphMedium"
                        variant={type}
                        sx={{
                          color:
                            slideIndex === index
                              ? descriptionColor
                              : unselectedColor,
                        }}
                      >
                        {text}
                      </CustomTypography>
                    ))}
                  </Box>
                ),
              )}
          </Box>
        </Box>
      </Box>
    </Section>
  );
};

export const query = graphql`
  fragment FeatureSlides on PrismicFeatureDataBodySlides {
    id
    items {
      slide_item_description {
        richText
      }
      slide_item_image {
        alt
        gatsbyImageData(height: 405)
      }
      slide_item_title {
        richText
      }
    }
    primary {
      slides_style
      slides_title {
        richText
      }
    }
    slice_type
  }

  fragment LandingPageSlides on PrismicLandingPageDataBodySlides {
    id
    items {
      slide_item_description {
        richText
      }
      slide_item_image {
        alt
        gatsbyImageData(height: 405)
      }
      slide_item_title {
        richText
      }
    }
    primary {
      slides_style
      slides_title {
        richText
      }
    }
    slice_type
  }

  fragment LandingPageAfterPricingSlides on PrismicLandingPageDataBody1Slides {
    id
    items {
      slide_item_description {
        richText
      }
      slide_item_image {
        alt
        gatsbyImageData(height: 405)
      }
      slide_item_title {
        richText
      }
    }
    primary {
      slides_style
      slides_title {
        richText
      }
    }
    slice_type
  }

  fragment CompanyPageSlides on PrismicCompanyPageDataBodySlides {
    id
    items {
      slide_item_description {
        richText
      }
      slide_item_image {
        alt
        gatsbyImageData(height: 405)
      }
      slide_item_title {
        richText
      }
    }
    primary {
      slides_style
      slides_title {
        richText
      }
    }
    slice_type
  }

  fragment JobPageSlides on PrismicJobPageDataBodySlides {
    id
    items {
      slide_item_description {
        richText
      }
      slide_item_image {
        alt
        gatsbyImageData(height: 405)
      }
      slide_item_title {
        richText
      }
    }
    primary {
      slides_style
      slides_title {
        richText
      }
    }
    slice_type
  }
`;

export default Slides;
