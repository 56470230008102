import React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { v4 as uuidv4 } from 'uuid';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import Section from '@/components/Section';
import Typography from '@/components/Typography';
import { GatsbyImage } from 'gatsby-plugin-image';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import getBackgroundColor from '@/utils/getBackgroundColor';
import { NextPrevPosts } from '@/components/NextPrevPosts/NextPrevPosts';

const StyledBox = styled(Box)(({ theme, color }) => ({
  ...theme.typography.paragraphLarge,
  h1: {
    ...theme.typography.heading1,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
  },
  h2: {
    ...theme.typography.heading2,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
  },
  h3: {
    ...theme.typography.heading3,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
  },
  h4: {
    ...theme.typography.heading4,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
  },
  h5: {
    ...theme.typography.heading5,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
  },
  h6: {
    ...theme.typography.heading6,
    color,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
  },
  a: {
    color: `#5298D5`,
  },
}));

const CaseStudyRichText = ({ slice, slices, context }) => {
  // Check if this is the last rich text slice
  const richTextSlices = slices.filter(
    (s) => s.slice_type === slice.slice_type,
  );
  const lastRichTextSlice = richTextSlices[richTextSlices.length - 1];
  const isLastRichTextSlice = slice.id === lastRichTextSlice.id;

  const { primary, items } = slice;

  const videoStyles = {
    '[data-oembed-type="video"]': {
      position: 'relative',
      paddingBottom: '56.25%',
      height: 0,
      marginBlockStart: '1em',
      marginBlockEnd: '1em',
      iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 1,
        height: 1,
      },
    },
  };

  const imageStyles = {
    img: { width: 1 },
    '.block-img': {
      lineHeight: 0,
    },
  };

  const { backgroundColor, color } = getBackgroundColor(primary?.section_style);

  if (!primary) return <></>;

  return (
    <>
      <Section
        sx={{
          px: { xs: 3, sm: 4 },
          py: { xs: 3, sm: 8 },
          backgroundColor,
          color,
        }}
        id={primary.section_title_type}
      >
        <Box
          sx={{
            maxWidth: 'sm',
            width: 1,
            mx: 'auto',
            ...imageStyles,
            ...videoStyles,
          }}
        >
          <Box sx={{ pb: items && items.length > 0 ? 4 : 1 }}>
            <Box sx={{ display: `flex`, alignItems: `center`, gap: 2 }}>
              {primary.section_title_icon.gatsbyImageData && (
                <GatsbyImage
                  alt={primary.section_title_icon.alt}
                  image={primary.section_title_icon.gatsbyImageData}
                />
              )}
              {primary.section_title.richText.map(({ text, type }) => (
                <CustomTypography
                  key={uuidv4()}
                  variant={type}
                  customVariant="heading6"
                  sx={{ color: `#B6B3B3` }}
                >
                  {text}
                </CustomTypography>
              ))}
            </Box>
            {items && items.length > 0 && (
              <Box
                sx={{
                  display: `flex`,
                  flexDirection: { xs: `column`, sm: `row` },
                  mt: 4,
                  gap: 3,
                }}
              >
                {items.map((item) => (
                  <Result key={uuidv4()} item={item} />
                ))}
              </Box>
            )}
          </Box>
          <StyledBox color={color}>
            <PrismicRichText field={primary.text_body.richText} />
          </StyledBox>
        </Box>
      </Section>
      {(context.next || context.prev) && isLastRichTextSlice && (
        <NextPrevPosts
          lang={context.lang}
          prevUrl={context.prev?.url}
          prevTitle={context?.prev?.data?.case_study_headline?.text}
          nextUrl={context.next?.url}
          nextTitle={context.next?.data.case_study_headline?.text}
          caseStudyPage
        />
      )}
    </>
  );
};

const Result = ({ item }) => (
  <Box
    sx={{
      display: `flex`,
      flexDirection: `column`,
      gap: 1,
      justifyContent: `start`,
      '.gatsby-image-wrapper': {
        maxWidth: 48,
      },
    }}
  >
    {item.result_type === 'Number' ? (
      <Typography variant="textJumbo" sx={{ fontSize: { xs: `48px` } }}>
        {item.result_number}
      </Typography>
    ) : (
      <>
        {item.result_icon.gatsbyImageData && (
          <GatsbyImage
            alt={item.result_icon.alt}
            image={item.result_icon.gatsbyImageData}
          />
        )}
      </>
    )}
    {item.result_description.richText.map(({ text, type }) => (
      <CustomTypography
        key={uuidv4()}
        customVariant="paragraphLarge"
        variant={type}
      >
        {text}
      </CustomTypography>
    ))}
  </Box>
);

export const query = graphql`
  fragment CaseStudyRichText on PrismicCaseStudyPageDataBodyCaseStudyRichText {
    id
    slice_type
    items {
      result_description {
        richText
      }
      result_icon {
        alt
        gatsbyImageData
      }
      result_number
      result_type
    }
    primary {
      text_body {
        richText
      }
      section_style
      section_title {
        richText
      }
      section_title_icon {
        gatsbyImageData
        alt
      }
      section_title_type
    }
  }
`;

export default CaseStudyRichText;
