import { useFormspark } from '@formspark/use-formspark';
import { ButtonUnstyled } from '@mui/base';
import { FormControl, InputBase, styled, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

const SplashSectionForm = ({
  formId = ``,
}: {
  formId: string;
}): JSX.Element => {
  const [submitMessage, setSubmitMessage] = React.useState(``);
  const [submit, submitting] = useFormspark({
    formId,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    // Reset data and set loading true
    setSubmitMessage(``);

    // Handle honeypot error
    if (data.honeypot) {
      setSubmitMessage(`Error 321!`);
      return;
    }

    // Remove honeypot field from data
    Object.keys(data).forEach((key) => {
      if (key === `honeypot`) {
        // eslint-disable-next-line no-param-reassign
        delete data[key];
      }
    });

    await submit(data)
      .then(() => {
        // update url
        if (window && window.location) {
          window.location.href = `https://dashboard.rosetta.ai/register?email=${data.email}`;
        }
      })
      .catch(() => {
        setSubmitMessage(`Error while submitting form!`);
      });
  };

  const PrimaryLink = styled(ButtonUnstyled)(({ theme }) => ({
    cursor: `pointer`,
    color: `#0A0101`,
    background: `#EBB0CF`,
    border: `1px solid #D45398`,
    boxSizing: `border-box`,
    boxShadow: `2px 2px 0px #D45398`,
    borderRadius: `12px`,
    fontWeight: 600,
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
    textDecoration: `none`,
    fontFamily: `Manrope,Roboto,Helvetica,Arial,sans-serif`,
    whiteSpace: `nowrap`,
    fontSize: `16px`,
    lineHeight: `22px`,
    letterSpacing: `0.4px`,
    ':hover': {
      boxShadow: `4px 4px 0px #D45398`,
      marginRight: theme.spacing(0.25),
      marginBottom: theme.spacing(0.25),
      marginLeft: theme.spacing(-0.25),
      marginTop: theme.spacing(-0.25),
    },
    ':active': {
      boxShadow: `1px 1px 0px #D45398`,
      marginLeft: theme.spacing(0.125),
      marginTop: theme.spacing(0.125),
      marginRight: theme.spacing(-0.125),
      marginBottom: theme.spacing(-0.125),
    },
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-labelledby="form-submit-text">
      <Box
        sx={{
          display: `flex`,
          alignItems: `center`,
          width: `100%`,
          borderRadius: `12px`,
          px: {
            xs: 0,
            sm: 1.25,
            md: 0,
            xl: 1.25,
          },
          pt: 1,
          pb: 1.25,
          flexDirection: { xs: `column`, sm: `row`, md: `column`, xl: `row` },
          border: {
            xs: `none`,
            sm: `1px solid #E7E6E6`,
            md: `none`,
            xl: `1px solid #E7E6E6`,
          },
        }}
      >
        <Box sx={{ width: `100%` }}>
          <FormControl sx={{ width: `100%` }}>
            <InputBase
              id="email"
              type="text"
              placeholder="Enter your email address here"
              aria-required="true"
              aria-label="email address"
              autoComplete="email"
              defaultValue=""
              error={!!errors.email}
              aria-describedby="email-error-text"
              {...register(`email`, {
                required: `Email is required`,
                pattern: {
                  value:
                    // eslint-disable-next-line no-useless-escape
                    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                  message: `Please enter a valid email`,
                },
              })}
              sx={{
                width: `100%`,
                p: 0.5,
                fontSize: `16px`,
                lineHeight: 1.5,
                letterSpacing: `-0.04px`,
                border: {
                  xs: `1px solid #E7E6E6`,
                  sm: `none`,
                  md: `1px solid #E7E6E6`,
                  xl: `none`,
                },
                borderRadius: `12px`,
                px: {
                  xs: 2,
                  sm: 1.75,
                  md: 2,
                  xl: 1.75,
                },
                py: {
                  xs: 1.5,
                  sm: 0,
                  md: 1.5,
                  xl: 0,
                },
                mb: {
                  xs: 1.5,
                  sm: 0,
                  md: 1.5,
                  xl: 0,
                },
              }}
            />
          </FormControl>
        </Box>

        {/* Honeypot */}
        <TextField
          {...register(`honeypot`)}
          id="honeypot"
          defaultValue=""
          autoComplete="off"
          aria-hidden
          sx={{
            display: `none`,
            gridColumn: `span 2 / span 2`,
          }}
        />

        {/* Submit */}
        <PrimaryLink
          type="submit"
          disabled={submitting}
          sx={{
            width: {
              xs: `100%`,
              sm: `auto`,
              md: `100%`,
              xl: `auto`,
            },
          }}
        >
          Start 30-day free trial
        </PrimaryLink>
      </Box>
      <Box
        sx={{
          fontSize: 12,
          lineHeight: 1.33,
          ml: 0.5,
          letterSpacing: `-0.084px`,
          color: `#858080`,
          [`& > p[role="alert"]`]: {
            color: `red`,
          },
        }}
      >
        <p>
          By entering your email, you agree to receive marketing emails from
          Rosetta AI.
        </p>
        {errors.email?.message && (
          <p role="alert" id="email-error-text">
            {errors.email?.message}
          </p>
        )}
        {submitMessage && (
          <p role="alert" id="form-submit-text">
            {submitMessage}
          </p>
        )}
      </Box>
    </form>
  );
};

export default SplashSectionForm;
