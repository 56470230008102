import React, { useState } from 'react';
import { BaseComponentProps } from '@/types';

import { PricingType, Provider } from '@/contexts/pricingContext';

const PricingContextProvider = ({
  children,
}: BaseComponentProps): JSX.Element => {
  const [type, setType] = useState<PricingType>(`yearly`);
  const [priceLoc, setPriceLoc] = useState<string | null>(null);
  const setPricingType = (val: PricingType) => {
    if (type !== val) {
      setType(val);
    }
  };
  const contextValue = {
    type,
    setType: setPricingType,
    priceLoc,
  };

  React.useEffect(() => {
    const executeFetch = async () => {
      await fetch(`/edge-functions/get-country-code`)
        .then((response) => response.json())
        .then((res) => {
          if (res?.country?.toUpperCase() === `JP`) {
            setPriceLoc(`JP`);
          } else if (res?.country?.toUpperCase() === `TW`) {
            setPriceLoc(`TW`);
          } else {
            setPriceLoc(`US`);
          }
        })
        .catch(() => setPriceLoc(`US`));
    };
    executeFetch();
  }, []);

  return <Provider value={contextValue}>{children}</Provider>;
};

export default PricingContextProvider;
