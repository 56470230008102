import React, { useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import Section from '@/components/Section';
import getBackgroundColor from '@/utils/getBackgroundColor';
import { Box } from '@mui/material';
import { getVideoSectionColors } from '@/utils/getVideoSectionColors';
import { CustomTypography } from '@/components/Typography/CustomTypography';

const Video = ({ slice }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const { items, primary } = slice;
  const { videoStyle, videoTitle } = useMemo(() => {
    if (primary) {
      return {
        videoStyle: primary?.video_style,
        videoTitle: primary?.video_title,
      };
    }
    return {};
  }, [primary]);

  const handleSlideIndexChange = (index) => {
    setSlideIndex(index);
  };

  const { backgroundColor, color } = getBackgroundColor(videoStyle);

  const sectionTheme = primary?.video_style?.includes('Black')
    ? 'dark'
    : 'light';
  const { titleColor, descriptionColor, unselectedColor, notchColor } =
    getVideoSectionColors(sectionTheme);

  if (!primary) return <></>;

  return (
    <Section
      sx={{
        backgroundColor,
        px: { xs: 3, sm: 4 },
      }}
    >
      <Box
        sx={{
          display: `flex`,
          flexDirection: `column`,
          gap: 4,
          alignItems: `center`,
          maxWidth: 'xl',
          mx: 'auto',
          width: { lg: 1 },
        }}
      >
        {videoTitle?.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            variant={type}
            customVariant="heading3"
            sx={{ color }}
          >
            {text}
          </CustomTypography>
        ))}
        <Box
          sx={{
            display: `flex`,
            alignItems: `start`,
            gap: { xs: 5, lg: 3 },
            flexDirection: { xs: `column`, lg: `row` },
            width: 1,
            justifyContent: { xs: `left`, lg: `center` },
          }}
        >
          <Box
            sx={{
              position: `relative`,
              width: { xs: 1, lg: 'auto' },
            }}
          >
            {items &&
              items.length > 0 &&
              items.map(
                ({ video_item_url: videoItemUrl }, index) =>
                  index === slideIndex && (
                    <Box
                      key={uuidv4()}
                      sx={{
                        position: 'relative',
                        pb: '56.25%',
                        height: 0,
                        overflow: 'hidden',
                        width: { xs: 1, lg: '720px' },
                        iframe: {
                          width: 1,
                          height: 1,
                          top: 0,
                          left: 0,
                          position: 'absolute',
                        },
                      }}
                    >
                      <Box
                        dangerouslySetInnerHTML={{ __html: videoItemUrl?.html }}
                      />
                    </Box>
                  ),
              )}
          </Box>
          <Box
            sx={{
              display: `flex`,
              flexDirection: 'column',
              gap: 3,
              maxWidth: { lg: 552 },
              pr: 3,
              overflow: `auto`,
              maxHeight: { xs: 372, lg: 405 },
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                outline: '1px solid #E7E6E6',
                borderRadius: '100px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#E7E6E6',
              },
            }}
          >
            {items &&
              items.length > 0 &&
              items.map(
                (
                  {
                    video_item_description: videoItemDescription,
                    video_item_title: videoItemTitle,
                  },
                  index,
                ) => (
                  <Box
                    key={uuidv4()}
                    onClick={() => handleSlideIndexChange(index)}
                    sx={{
                      pl: 2,
                      borderLeft:
                        slideIndex === index && `8px ${notchColor} solid`,
                      maxWidth: { sm: 520, lg: 'none' },
                      cursor: `pointer`,
                    }}
                  >
                    {videoItemTitle.richText.map(({ text, type }) => (
                      <CustomTypography
                        key={uuidv4()}
                        variant={type}
                        customVariant="heading3"
                        sx={{
                          color:
                            slideIndex === index ? titleColor : unselectedColor,
                        }}
                      >
                        {text}
                      </CustomTypography>
                    ))}
                    {videoItemDescription.richText.map(({ text, type }) => (
                      <CustomTypography
                        key={uuidv4()}
                        customVariant="paragraphMedium"
                        variant={type}
                        sx={{
                          color:
                            slideIndex === index
                              ? descriptionColor
                              : unselectedColor,
                        }}
                      >
                        {text}
                      </CustomTypography>
                    ))}
                  </Box>
                ),
              )}
          </Box>
        </Box>
      </Box>
    </Section>
  );
};

export const query = graphql`
  fragment FeatureVideo on PrismicFeatureDataBodyVideo {
    id
    items {
      video_item_description {
        richText
      }
      video_item_title {
        richText
      }
      video_item_url {
        html
      }
    }
    primary {
      video_style
      video_title {
        richText
      }
    }
    slice_type
  }

  fragment ResourceVideo on PrismicResourcePageDataBodyVideo {
    id
    items {
      video_item_description {
        richText
      }
      video_item_title {
        richText
      }
      video_item_url {
        html
      }
    }
    primary {
      video_style
      video_title {
        richText
      }
    }
    slice_type
  }

  fragment LandingPageVideo on PrismicLandingPageDataBodyVideo {
    id
    items {
      video_item_description {
        richText
      }
      video_item_title {
        richText
      }
      video_item_url {
        html
      }
    }
    primary {
      video_style
      video_title {
        richText
      }
    }
    slice_type
  }

  fragment LandingPageAfterPricingVideo on PrismicLandingPageDataBody1Video {
    id
    items {
      video_item_description {
        richText
      }
      video_item_title {
        richText
      }
      video_item_url {
        html
      }
    }
    primary {
      video_style
      video_title {
        richText
      }
    }
    slice_type
  }

  fragment CompanyPageVideo on PrismicCompanyPageDataBodyVideo {
    id
    items {
      video_item_description {
        richText
      }
      video_item_title {
        richText
      }
      video_item_url {
        html
      }
    }
    primary {
      video_style
      video_title {
        richText
      }
    }
    slice_type
  }

  fragment JobPageVideo on PrismicJobPageDataBodyVideo {
    id
    items {
      video_item_description {
        richText
      }
      video_item_title {
        richText
      }
      video_item_url {
        html
      }
    }
    primary {
      video_style
      video_title {
        richText
      }
    }
    slice_type
  }
`;
export default Video;
