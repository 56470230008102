/* eslint-disable no-nested-ternary */
import React from 'react';
import { graphql } from 'gatsby';
import { Box } from '@mui/material';
import Section from '@/components/Section';
import { v4 as uuidv4 } from 'uuid';
import { Post } from '@/components/Posts/Post';
import { CustomTypography } from '@/components/Typography/CustomTypography';

const RelatedPosts = ({ slice, context }) => {
  const { items } = slice;

  const { allPosts, allCaseStudies } = context;

  let relatedItems = [];
  let title = '';

  if (allPosts?.length) {
    relatedItems = items
      .map((item) => allPosts.find((post) => post.url === item.post.url))
      .map((post) => ({
        title: post.data.blog_post_title,
        description: post.data.blog_post_description,
        image: post.data.blog_post_image,
        lang: post.lang,
        url: post.url,
      }));
    title =
      context.lang === `zh-tw`
        ? '延伸閱讀推薦'
        : context.lang === `ja-jp`
        ? '関連記事'
        : 'Related Posts';
  }

  if (allCaseStudies?.length) {
    relatedItems = items
      .map((item) => allCaseStudies.find((post) => post.url === item.post.url))
      .map((post) => ({
        title: post.data.case_study_headline,
        description: post.data.case_study_description,
        image: post.data.case_study_background_image,
        lang: post.lang,
        url: post.url,
      }));
    title =
      context.lang === `zh-tw`
        ? '其他客戶成功案例'
        : context.lang === `ja-jp`
        ? 'その他のお客様事例'
        : 'Related Case Studies';
  }

  if (!relatedItems || relatedItems.length === 0) return null;

  const columns = relatedItems.length > 3 ? 3 : 2;

  return (
    <Section
      sx={{
        px: { xs: 3, sm: 4 },
        py: { xs: 4, sm: 6, md: 8 },
        pt: { xs: 2, sm: 4, md: 4 },
        display: `flex`,
        flexDirection: `column`,
      }}
      id="posts"
    >
      <CustomTypography
        key={uuidv4()}
        variant="paragraph"
        customVariant="heading2"
        sx={{
          maxWidth: 800,
          mx: `auto`,
          width: 1,
          mb: 4,
          textAlign: `center`,
          fontWeight: 600,
        }}
      >
        {title}
      </CustomTypography>
      <Box
        sx={{
          display: `grid`,
          maxWidth: 800,
          width: 1,
          mx: `auto`,
          gridTemplateColumns: { lg: `repeat(${columns}, 1fr)` },
          columnGap: 3,
          rowGap: { xs: 6, lg: 16 },
          justifyContent: `center`,
        }}
        id="posts-panel"
      >
        {relatedItems.map((post, i) => (
          <Post key={uuidv4()} post={post} i={i} lang={post.lang} />
        ))}
      </Box>
    </Section>
  );
};

export const query = graphql`
  fragment PostRelatedPosts on PrismicBlogPostDataBodyRelatedPosts {
    items {
      post {
        id
        lang
        url
      }
    }
  }
  fragment CaseStudyRelatedPosts on PrismicCaseStudyPageDataBodyRelatedPosts {
    items {
      post {
        id
        lang
        url
      }
    }
  }
`;

export default RelatedPosts;
