/* eslint-disable import/prefer-default-export, react/prop-types */
import React from 'react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { PrismicProvider } from '@prismicio/react';

import { repositoryConfigs } from '@/utils/prismicPreviews';
import { linkResolver } from '../../src/utils/linkResolver';
import TopLayout from './TopLayout';

export const wrapRootElement = ({ element }) => (
  <PrismicProvider linkResolver={linkResolver}>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      <TopLayout>{element}</TopLayout>
    </PrismicPreviewProvider>
  </PrismicProvider>
);
