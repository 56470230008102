/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import { Box } from '@mui/material';

import Section from '@/components/Section';
import getBackgroundColor from '@/utils/getBackgroundColor';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import '@/styles/swiper.css';
import { Pagination } from 'swiper';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';
import { TestimonialsSlide } from '@/components/Testimonials/TestimonialsSlide';

const Testimonials = ({ slice }) => {
  const { items, primary } = slice;
  const { backgroundColor, color, fourthColor } = getBackgroundColor(
    primary?.testimonials_style,
  );
  const pagination = {
    clickable: true,
    renderBullet(index, className) {
      return `<span class="${className}"></span>`;
    },
  };
  const [swiper, setSwiper] = useState({});

  if (!primary) return <></>;

  return (
    <Section
      sx={{
        px: { xs: 3, sm: 4, md: 13.5 },
        py: { xs: 5, md: 9 },
        backgroundColor,
      }}
    >
      <Box
        sx={{
          maxWidth: '1010px',
          mx: 'auto',
          width: 1,
        }}
      >
        <Swiper
          pagination={pagination}
          modules={[Pagination]}
          className="swiper-container"
          loop
          onInit={(ev) => {
            setSwiper(ev);
          }}
        >
          {items &&
            items.length > 0 &&
            items.map((item) => (
              <SwiperSlide key={uuidv4()}>
                <TestimonialsSlide
                  item={item}
                  color={color}
                  fourthColor={fourthColor}
                />
              </SwiperSlide>
            ))}
          <Box
            sx={{
              position: `absolute`,
              right: 0,
              bottom: -6,
              svg: {
                width: 24,
                height: 19,
                fill: '#B6B3B3',
                transitionProperty: `all`,
                transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
                transitionDuration: `150ms`,
                '&:hover': { fill: '#544D4D' },
              },
              button: {
                background: 'none',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
              },
            }}
          >
            <button type="button" onClick={() => swiper.slidePrev()}>
              <HiArrowLeft />
            </button>
            <button type="button" onClick={() => swiper.slideNext()}>
              <HiArrowRight />
            </button>
          </Box>
        </Swiper>
      </Box>
    </Section>
  );
};
export default Testimonials;

export const query = graphql`
  fragment LandingPageTestimonials on PrismicLandingPageDataBodyTestimonials {
    id
    primary {
      testimonials_style
    }
    items {
      attribution
      testimonial_text {
        richText
      }
      image {
        alt
        gatsbyImageData
      }
    }
  }
`;
