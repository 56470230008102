import { BaseComponentProps, Nav, UrlLike } from '@/types';
import { ButtonUnstyled } from '@mui/base';
import { Box, styled } from '@mui/material';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CTAButton, GroupNav, isGroupNav, NavItem } from '../Header/Header';
import Link from '../Link';
import Typography from '../Typography';
import { CloseButton } from './CloseButton';
import ButtonLink from '../ButtonLink';

type MobileMenuModalProps = {
  isOpen: boolean;
  setIsOpen: (bool: boolean) => void;
  logo: { gatsbyImageData: IGatsbyImageData; alt: string; link: UrlLike };
  navs: Array<Nav | GroupNav>;
  tertiaryCTA: CTAButton;
  secondaryCTA: CTAButton;
  mainCTA: CTAButton;
};

interface ExpandableMenuItemProps extends BaseComponentProps {
  title: string;
  items: NavItem[];
}

const StyledExpandButton = styled(ButtonUnstyled)`
  border: none;
  padding: 0px;
  background: none;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  width: 100%;
  display: flex;
  align-items: center;
`;

const ExpandableMenuItem = ({ title, items }: ExpandableMenuItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Box sx={{ width: 1, borderBottom: `1px solid #e7e6e6` }}>
      <StyledExpandButton onClick={() => setIsOpen(!isOpen)}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: `16px`,
            lineHeight: `21.86px`,
            letterSpacing: `-0.08px`,
            color: isOpen ? `#231A1A` : `#858080`,
            transitionProperty: `all`,
            transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
            transitionDuration: `150ms`,
            fontFamily: `Manrope`,
          }}
        >
          {title}
        </Typography>
        <KeyboardArrowDownIcon
          sx={{
            fill: isOpen ? `#231A1A` : `#858080`,
            transform: isOpen ? `rotate(180deg)` : ``,
            transitionProperty: `all`,
            transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
            transitionDuration: `150ms`,
          }}
        />
      </StyledExpandButton>
      <Box
        sx={{
          width: `100%`,
          maxHeight: isOpen ? `1500px` : `0px`,
          px: 1,
          transitionProperty: `max-height, visibility`,
          transitionDuration: isOpen ? `1.7s` : `0.35s`,
          transitionTimingFunction: `ease`,
          overflow: `hidden`,
        }}
      >
        {items.map((item) => (
          <Link
            to={item.url.url}
            sx={{ py: 2, px: 2, display: `flex`, gap: 2 }}
            key={item.title}
          >
            {item.icon && (
              <Box
                sx={{
                  maxWidth: 36,
                  maxHeight: 36,
                  minWidth: 36,
                  minHeight: 36,
                }}
              >
                <GatsbyImage image={item.icon} alt={item.title} />
              </Box>
            )}
            <Box sx={{ display: `flex`, flexDirection: `column`, gap: 0.5 }}>
              <Box
                sx={{
                  color: `#231A1A`,
                  fontSize: `16px`,
                  lineHeight: `21.86px`,
                  fontWeight: 500,
                  letterSpacing: `-0.08 px`,
                }}
              >
                {item.title}
              </Box>
              <Box
                sx={{
                  color: `#858080`,
                  fontWeight: 400,
                  fontSize: `12px`,
                  lineHeight: `16.39px`,
                  letterSpacing: `-0.08 px`,
                }}
              >
                {item.description.text}
              </Box>
            </Box>
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export const MobileMenuModal = ({
  isOpen,
  setIsOpen,
  logo,
  navs,
  mainCTA,
  secondaryCTA,
  tertiaryCTA,
}: MobileMenuModalProps): JSX.Element => (
  <Box
    sx={{
      backgroundColor: `#ffffff`,
      position: `fixed`,
      display: isOpen ? `block` : `none`,
      transform: isOpen ? `translate(0, 0)` : `translate(100%, 0)`,
      transition: `all 0.5s cubic-bezier(0.77, 0, 0.175, 1) 0s`,
      opacity: isOpen ? `1` : `0`,
      top: 0,
      right: 0,
      width: 1,
      height: 1,
      zIndex: `50`,
      overflow: `hidden`,
      px: { xs: 3, sm: 4 },
    }}
  >
    <Box
      sx={{
        display: `flex`,
        alignItems: `center`,
        justifyContent: `space-between`,
        minHeight: 74,
      }}
    >
      <Link to={logo.link.url}>
        <GatsbyImage image={logo.gatsbyImageData} alt={logo.alt} />
      </Link>
      <CloseButton clickHandler={() => setIsOpen(!isOpen)} />
    </Box>
    {navs.map((nav) => (
      <Box key={nav.title} sx={{ display: `flex`, alignItems: `stretch` }}>
        {isGroupNav(nav) ? (
          <ExpandableMenuItem title={nav.title} items={nav.items} />
        ) : (
          <Box
            sx={{
              borderBottom: `1px solid #E7E6E6`,
              width: 1,
            }}
          >
            <Link
              to={nav.url.url}
              sx={{
                px: 1,
                py: 2,
                display: `flex`,
                alignItems: `center`,
                fontWeight: 500,
                fontSize: `16px`,
                lineHeight: `21.86px`,
                letterSpacing: `-0.08px`,
                color: `#858080`,
              }}
            >
              {nav.title}
            </Link>
          </Box>
        )}
      </Box>
    ))}
    {(mainCTA.label || secondaryCTA.label || tertiaryCTA.label) && (
      <Box
        sx={{
          py: 6,
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `center`,
          gap: 3,
          textAlign: `center`,
        }}
      >
        {mainCTA.label && (
          <ButtonLink
            sx={{
              fontWeight: 600,
              fontSize: `16px`,
              lineHeight: `21.86px`,
              letterSpacing: `0.4 px`,
            }}
            to={mainCTA.url.url}
            target={mainCTA.url.target}
            dataEventAction={mainCTA.mixpanelEvent}
          >
            {mainCTA.label}
          </ButtonLink>
        )}
        {secondaryCTA.label && (
          <Box>
            <Link
              to={secondaryCTA.url.url}
              href={secondaryCTA.url.url}
              target={secondaryCTA.url.target}
              dataEventAction={secondaryCTA.mixpanelEvent}
              color="#858080"
            >
              {secondaryCTA.label}
            </Link>
          </Box>
        )}
        {tertiaryCTA.label && (
          <Box>
            <Link
              to={tertiaryCTA.url.url}
              href={tertiaryCTA.url.url}
              target={tertiaryCTA.url.target}
              dataEventAction={tertiaryCTA.mixpanelEvent}
              color="#858080"
            >
              {tertiaryCTA.label}
            </Link>
          </Box>
        )}
      </Box>
    )}
  </Box>
);
