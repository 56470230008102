import React, { forwardRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

const Section = forwardRef<HTMLElement, BoxProps>(
  ({ children, sx, ...props }, ref): JSX.Element => (
    <Box
      component="section"
      sx={{
        display: `flex`,
        flexWrap: `wrap`,
        mx: `auto`,
        px: { md: 13.5, xl: 26 },
        py: 8,
        ...sx,
      }}
      {...props}
      ref={ref}
    >
      {children}
    </Box>
  ),
);

export default Section;
