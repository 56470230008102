import React from 'react';
import { Tags as TagsComponent } from '@/components/Posts/Tags';

const Tags = ({
  context: { selectedTag = null, setSelectedTag = () => {}, tags = [] },
}) => (
  <TagsComponent
    selectedTag={selectedTag}
    setSelectedTag={setSelectedTag}
    tags={tags}
  />
);

export default Tags;
