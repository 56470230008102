/* eslint-disable react/no-danger */
import { graphql } from 'gatsby';
import React from 'react';
import { Box } from '@mui/material';
import Section from '@/components/Section';

const PipedriveForm = ({ slice }) => {
  if (!slice.primary) return <></>;

  React.useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://webforms.pipedrive.com/f/loader';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Section
      sx={{
        pt: { xs: 4, sm: 6, md: 8 },
        pb: { xs: 5, sm: 7, md: 9 },
        px: { xs: 3, sm: 4, md: 13.5 },
        scrollMarginTop: '50px',
      }}
      id="form"
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
        className="pipedriveWebForms"
        data-pd-webforms={`https://webforms.pipedrive.com/f/${slice.primary.form_id}`}
      />
    </Section>
  );
};

export default PipedriveForm;

export const query = graphql`
  fragment LandingPagePipedriveForm on PrismicLandingPageDataBodyPipedriveForm {
    id
    slice_type
    primary {
      form_id
    }
  }
  fragment LandingPageAfterPricingPipedriveForm on PrismicLandingPageDataBody1PipedriveForm {
    id
    slice_type
    primary {
      form_id
    }
  }
  fragment BlogPostPipedriveForm on PrismicBlogPostDataBodyPipedriveForm {
    id
    slice_type
    primary {
      form_id
    }
  }
  fragment PricingPagePipedriveForm on PrismicPricingPageDataBody4PipedriveForm {
    id
    slice_type
    primary {
      form_id
    }
  }
  fragment CaseStudyPipedriveForm on PrismicCaseStudyPageDataBodyPipedriveForm {
    id
    slice_type
    primary {
      form_id
    }
  }
`;
