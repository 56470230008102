import React from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import Section from '@/components/Section';
import ButtonLink from '@/components/ButtonLink';

const Cta = ({ slice }) => {
  const {
    primary: {
      link,
      link_label: label,
      vertical_padding: padding,
      cta_section_mixpanel_event: ctaSectionMixpanelEvent,
    },
  } = slice;
  return (
    <Section
      sx={{
        overflow: `hidden`,
        px: { xs: 3, sm: 4, md: 13.5 },
        py: { xs: padding ? 5 : 1, lg: padding ? 9 : 1 },
        justifyContent: `center`,
      }}
    >
      <ButtonLink
        key={uuidv4()}
        to={link.url}
        target={link.target}
        sx={{
          alignSelf: `flex-start`,
          width: { xs: 1, sm: 'auto' },
          textAlign: 'center',
        }}
        dataEventAction={ctaSectionMixpanelEvent}
      >
        {label}
      </ButtonLink>
    </Section>
  );
};
export default Cta;

export const query = graphql`
  fragment CaseStudyCta on PrismicCaseStudyPageDataBodyCta {
    id
    slice_type
    primary {
      link {
        url
        target
      }
      link_label
    }
  }
`;
