/* eslint-disable no-nested-ternary */
import React from 'react';
import { graphql } from 'gatsby';
import Section from '@/components/Section';
import { Widget } from '@typeform/embed-react';

const TypeFormEmbed = ({ slice }) => {
  if (!slice?.primary?.id) return null;

  return (
    <Section
      sx={{
        px: { xs: 3, sm: 4 },
        py: { xs: 4, sm: 6, md: 8 },
        pt: { xs: 2, sm: 4, md: 4 },
        display: `flex`,
        flexDirection: `column`,
        width: { xs: `100%`, sm: `100%`, md: `100%`, lg: `50%` },
      }}
    >
      <Widget
        id={slice.primary.id}
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          height: '500px',
          width: '100%',
        }}
        className="my-form"
      />
    </Section>
  );
};

export const query = graphql`
  fragment LandingPageTypeFormEmbed on PrismicLandingPageDataBodyTypeformEmbed {
    primary {
      id
    }
  }
  fragment LandingPageAfterPricingTypeFormEmbed on PrismicLandingPageDataBody1TypeformEmbed {
    primary {
      id
    }
  }
`;

export default TypeFormEmbed;
