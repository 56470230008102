import React from 'react';
import Section from '@/components/Section';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Box } from '@mui/material';
import { CustomTypography } from '@/components/Typography/CustomTypography';

const PressImageWithCaption = ({ slice }) => {
  const { primary } = slice;
  if (!primary) return <></>;
  return (
    <Section sx={{ px: { xs: 3, sm: 4 }, py: 5 }}>
      <Box
        sx={{
          maxWidth: 'sm',
          width: 1,
          mx: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          textAlign: 'center',
        }}
      >
        <Box sx={{ position: `relative` }}>
          {primary.image.gatsbyImageData && (
            <GatsbyImage
              alt={primary.image.alt}
              image={primary.image.gatsbyImageData}
            />
          )}
          <Box
            component="a"
            href={primary.image_download_link.url}
            sx={{
              position: `absolute`,
              right: 16,
              bottom: 16,
              border: `none`,
              backgroundColor: `inherit`,
              cursor: `pointer`,
            }}
          >
            <img
              src="/download-button.svg"
              alt={primary.image_download_label}
            />
          </Box>
        </Box>
        {primary.caption.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            customVariant="paragraphMedium"
            variant={type}
          >
            {text}
          </CustomTypography>
        ))}
      </Box>
    </Section>
  );
};

export const query = graphql`
  fragment PressImgaeWithCaption on PrismicPressPostDataBodyPressImageWithCaption {
    id
    primary {
      caption {
        richText
      }
      image {
        gatsbyImageData
        alt
      }
      image_download_link {
        url
      }
      image_download_label
    }
    slice_type
  }
`;

export default PressImageWithCaption;
