import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import { Box } from '@mui/material';

import Section from '@/components/Section';
import ButtonLink from '@/components/ButtonLink';
import getBackgroundColor from '@/utils/getBackgroundColor';
import { CustomTypography } from '@/components/Typography/CustomTypography';

const Banner = ({ slice }) => {
  const { items, primary } = slice;
  const { bannerTitle, bannerStyle, bannerDescription } = useMemo(() => {
    if (primary) {
      return {
        bannerTitle: primary.banner_title,
        bannerStyle: primary.banner_style,
        bannerDescription: primary.banner_description,
      };
    }
    return {
      bannerTitle: { richText: [] },
      bannerStyle: '',
      bannerDescription: { richText: [] },
    };
  }, [primary]);
  const { backgroundColor, color } = getBackgroundColor(bannerStyle);
  return (
    <Section
      sx={{
        backgroundColor,
        px: { xs: 3, sm: 4, md: 13.5 },
        py: { xs: 5, sm: 6, md: 9 },
        overflow: `hidden`,
        backgroundImage:
          bannerStyle === 'Background Image' &&
          primary.banner_background_image.url &&
          `url("${primary.banner_background_image.url}")`,
        backgroundPosition: `center`,
        backgroundSize: `cover`,
      }}
    >
      <Box
        sx={{
          flexDirection: `column`,
          display: `flex`,
          gap: 3,
          maxWidth: 'xl',
          mx: 'auto',
          width: 1,
        }}
      >
        {bannerTitle.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            customVariant="textJumbo"
            variant={type}
            sx={{ color, maxWidth: 808 }}
          >
            {text}
          </CustomTypography>
        ))}
        {bannerDescription.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            customVariant="paragraphXL"
            variant={type}
            sx={{ color, maxWidth: 704 }}
          >
            {text}
          </CustomTypography>
        ))}
        {items && items.length > 0 && (
          <Box sx={{ display: `flex`, gap: 2 }}>
            {items.map(
              ({
                banner_button_label: bannerButtonlabel,
                banner_button_link: bannerButtonLink,
                banner_button_style: bannerButtonStyle,
                banner_button_mixpanel_event: bannerButtonMixpanelEvent,
              }) =>
                bannerButtonlabel ? (
                  <ButtonLink
                    key={uuidv4()}
                    to={bannerButtonLink.url}
                    target={bannerButtonLink.target}
                    type={bannerButtonStyle}
                    dataEventAction={bannerButtonMixpanelEvent}
                  >
                    {bannerButtonlabel}
                  </ButtonLink>
                ) : null,
            )}
          </Box>
        )}
      </Box>
    </Section>
  );
};

export default Banner;

export const query = graphql`
  fragment HomepageBanner on PrismicHomepageDataBodyBanner {
    items {
      banner_button_label
      banner_button_style
      banner_button_mixpanel_event
      banner_button_link {
        url
        target
      }
    }
    slice_type
    primary {
      banner_description {
        richText
      }
      banner_style
      banner_title {
        richText
      }
      banner_background_image {
        url
      }
    }
  }
  fragment FeatureBanner on PrismicFeatureDataBodyBanner {
    items {
      banner_button_label
      banner_button_style
      banner_button_mixpanel_event
      banner_button_link {
        url
        target
      }
    }
    slice_type
    primary {
      banner_description {
        richText
      }
      banner_style
      banner_title {
        richText
      }
      banner_background_image {
        url
      }
    }
  }
  fragment PricingBanner on PrismicPricingPageDataBody4Banner {
    items {
      banner_button_label
      banner_button_style
      banner_button_mixpanel_event
      banner_button_link {
        url
        target
      }
    }
    slice_type
    primary {
      banner_description {
        richText
      }
      banner_style
      banner_title {
        richText
      }
      banner_background_image {
        url
      }
    }
  }
  fragment EbookAndReportsPageBanner on PrismicEbookAndReportsPageDataBodyBanner {
    items {
      banner_button_label
      banner_button_style
      banner_button_mixpanel_event
      banner_button_link {
        url
        target
      }
    }
    slice_type
    primary {
      banner_description {
        richText
      }
      banner_style
      banner_title {
        richText
      }
      banner_background_image {
        url
      }
    }
  }

  fragment EbookAndReportPageBanner on PrismicEbookAndReportPageDataBodyBanner {
    items {
      banner_button_label
      banner_button_style
      banner_button_mixpanel_event
      banner_button_link {
        url
        target
      }
    }
    slice_type
    primary {
      banner_description {
        richText
      }
      banner_style
      banner_title {
        richText
      }
      banner_background_image {
        url
      }
    }
  }

  fragment PressPostPageBanner on PrismicPressPostDataBodyBanner {
    items {
      banner_button_label
      banner_button_style
      banner_button_mixpanel_event
      banner_button_link {
        url
        target
      }
    }
    slice_type
    primary {
      banner_description {
        richText
      }
      banner_style
      banner_title {
        richText
      }
      banner_background_image {
        url
      }
    }
  }

  fragment BlogPostBanner on PrismicBlogPostDataBodyBanner {
    items {
      banner_button_label
      banner_button_style
      banner_button_mixpanel_event
      banner_button_link {
        url
        target
      }
    }
    slice_type
    primary {
      banner_description {
        richText
      }
      banner_style
      banner_title {
        richText
      }
      banner_background_image {
        url
      }
    }
  }

  fragment LandingPageBanner on PrismicLandingPageDataBodyBanner {
    items {
      banner_button_label
      banner_button_style
      banner_button_mixpanel_event
      banner_button_link {
        url
        target
      }
    }
    slice_type
    primary {
      banner_description {
        richText
      }
      banner_style
      banner_title {
        richText
      }
      banner_background_image {
        url
      }
    }
  }

  fragment LandingPageAfterPricingBanner on PrismicLandingPageDataBody1Banner {
    items {
      banner_button_label
      banner_button_style
      banner_button_mixpanel_event
      banner_button_link {
        url
        target
      }
    }
    slice_type
    primary {
      banner_description {
        richText
      }
      banner_style
      banner_title {
        richText
      }
      banner_background_image {
        url
      }
    }
  }

  fragment CompanyPageBanner on PrismicCompanyPageDataBodyBanner {
    items {
      banner_button_label
      banner_button_style
      banner_button_mixpanel_event
      banner_button_link {
        url
        target
      }
    }
    slice_type
    primary {
      banner_description {
        richText
      }
      banner_style
      banner_title {
        richText
      }
      banner_background_image {
        url
      }
    }
  }

  fragment JobPageBanner on PrismicJobPageDataBodyBanner {
    items {
      banner_button_label
      banner_button_style
      banner_button_mixpanel_event
      banner_button_link {
        url
        target
      }
    }
    slice_type
    primary {
      banner_description {
        richText
      }
      banner_style
      banner_title {
        richText
      }
      banner_background_image {
        url
      }
    }
  }
`;
