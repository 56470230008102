import React from 'react';
import lottie from 'lottie-web';
import { useOnScreen } from '@/hooks/useOnScreen';
import { Box } from '@mui/system';

type AnimationProps = {
  id: string;
  loop: boolean;
  animationTrigger: string;
  rawAnimationData: any;
};

const Animation = ({
  id,
  loop,
  animationTrigger,
  rawAnimationData,
}: AnimationProps): JSX.Element => {
  const animationContainer = React.useRef<any>();
  const onScreen = useOnScreen(animationContainer);
  const animationData =
    rawAnimationData && rawAnimationData.text
      ? JSON.parse(rawAnimationData.text)
      : null;
  const playOnHover = animationTrigger === `play on hover`;
  const autoplay = animationTrigger === `autoplay`;

  React.useEffect(() => {
    if (onScreen && autoplay) {
      lottie.play(id);
    } else {
      lottie.pause(id);
    }
  }, [onScreen, autoplay]);
  React.useEffect(() => {
    lottie.loadAnimation({
      name: id,
      container: animationContainer.current,
      renderer: `svg`,
      loop: loop || playOnHover,
      autoplay,
      animationData,
    });
  }, []);
  return (
    <Box
      sx={{ height: `100%` }}
      ref={animationContainer}
      onMouseEnter={playOnHover ? () => lottie.play(id) : undefined}
      onMouseLeave={playOnHover ? () => lottie.pause(id) : undefined}
    />
  );
};

export default Animation;
