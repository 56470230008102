import React from 'react';

import { Box } from '@mui/material';

import getFeatureFlagColor, { FeatureFlag } from '@/utils/getFeatureFlagColor';

type FeatureGroupFlagProps = {
  featureFlag: FeatureFlag;
};
const FeatureGroupFlag = ({
  featureFlag,
}: FeatureGroupFlagProps): JSX.Element =>
  featureFlag !== `None` ? (
    <Box
      sx={{
        backgroundColor: getFeatureFlagColor(featureFlag),
        typography: `heading6`,
        color: `#0A0101`,
        px: 1,
        py: 0.5,
        borderRadius: `100px`,
        whiteSpace: `nowrap`,
        fontSize: `8px`,
        lineHeight: `13.75px`,
        letterSpacing: `1.2px`,
      }}
    >
      {featureFlag}
    </Box>
  ) : (
    <></>
  );

export default FeatureGroupFlag;
