/* eslint-disable camelcase */
import * as React from 'react';
import { Box } from '@mui/material';
import { Listbox } from '@headlessui/react';
import theme from '@/theme';
import { ButtonUnstyled } from '@mui/base';
import { languages, setStorageLng } from '@/data/languages';
import { navigate } from 'gatsby';
import { LanguageBannerContext } from '@/contexts/languageContext';
import { linkResolver } from '../../utils/linkResolver';
import Typography from '../Typography';

type CloseButtonProps = {
  handleClose: () => void;
};

const CloseButton = ({ handleClose }: CloseButtonProps): React.ReactElement => (
  <Box
    sx={{
      position: `absolute`,
      top: 28,
      right: 28,
      button: {
        backgroundColor: `transparent`,
        border: `none`,
        cursor: `pointer`,
      },
    }}
  >
    <ButtonUnstyled onClick={handleClose}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.69229 8.00002L14.8403 2.85202C15.0683 2.63602 15.2003 2.33602 15.2003 2.00002C15.2003 1.34002 14.6603 0.800018 14.0003 0.800018C13.6643 0.800018 13.3643 0.932018 13.1483 1.14802L8.00029 6.30802L2.85229 1.14802C2.63629 0.932018 2.33629 0.800018 2.00029 0.800018C1.34029 0.800018 0.800293 1.34002 0.800293 2.00002C0.800293 2.33602 0.932293 2.63602 1.14829 2.85202L6.30829 8.00002L1.16029 13.148C0.932293 13.364 0.800293 13.664 0.800293 14C0.800293 14.66 1.34029 15.2 2.00029 15.2C2.33629 15.2 2.63629 15.068 2.85229 14.852L8.00029 9.69202L13.1483 14.84C13.3643 15.068 13.6643 15.2 14.0003 15.2C14.6603 15.2 15.2003 14.66 15.2003 14C15.2003 13.664 15.0683 13.364 14.8523 13.148L9.69229 8.00002Z"
          fill="white"
        />
      </svg>
    </ButtonUnstyled>
  </Box>
);

type Lang = {
  id: string;
  type: string;
  lang: any;
  uid: string;
  link_type: 'Document';
  tags: string[];
};

type LanguageSwitcherProps = {
  lang: string;
  altLangs: Lang[];
};

export const LanguageSwitcher = ({
  lang,
  altLangs,
}: LanguageSwitcherProps): JSX.Element => {
  const { setIsOpen } = React.useContext(LanguageBannerContext);

  React.useEffect(() => {
    if (lang !== `en-us`) {
      setIsOpen(false);
    }
  }, []);

  const [languageOptions, setLanguageOptions] = React.useState(() => [
    ...altLangs.map((altLang) => ({
      code: altLang.lang,
      label: languages[altLang.lang],
      current: false,
    })),
    {
      code: lang,
      label: languages[lang],
      current: true,
    },
  ]);

  // Trigger select change event
  const handleLangChange = (event: any) => {
    setLanguageOptions((prevOptions) => [
      ...prevOptions
        .filter((option) => option.code !== event)
        .map((option) => ({ ...option, current: false })),
      ...prevOptions
        .filter((option) => option.code === event)
        .map((option) => ({ ...option, current: true })),
    ]);
  };

  const handleSubmit = () => {
    const current =
      languageOptions.find((option) => option.current) || languageOptions[0];

    const currentAltLang = altLangs.find(
      (altLang) => altLang.lang === current.code,
    );

    if (currentAltLang) {
      setStorageLng(currentAltLang.lang);
      navigate(linkResolver(currentAltLang), { replace: true });
    } else {
      setStorageLng(lang);
    }

    setIsOpen(false);
  };

  const filteredLanguageOptions = languageOptions.filter(
    (languageOption) => !languageOption.current,
  );

  return (
    <Box
      sx={{
        display: `flex`,
        flexDirection: { xs: `column`, md: `row` },
        alignItems: { xs: `start`, md: `center` },
        justifyContent: `center`,
        gap: { xs: 2, md: 8 },
        width: 1,
        // zIndex: 2,
        backgroundColor: `#231A1A`,
        py: 3,
        px: { xs: 3, md: 8, lg: 3 },
      }}
    >
      <CloseButton handleClose={() => setIsOpen(false)} />
      <Box sx={{ maxWidth: 380, color: `white` }}>
        <Typography variant="textSmall">
          You are browsing the {languages[lang] || `English`} website. If you
          want to browse in a different language, select your locale below
        </Typography>
      </Box>
      <Box
        sx={{
          display: `flex`,
          alignItems: `center`,
          justifyContent: `center`,
          gap: 2,
        }}
      >
        <Listbox
          value={languageOptions.find(
            (languageOption) => languageOption.current,
          )}
          onChange={handleLangChange}
        >
          <Box
            sx={{
              width: 1,
              minWidth: { xs: 200, sm: 265 },
              maxWidth: 256,
              position: `relative`,
            }}
          >
            <Box
              component={Listbox.Button}
              sx={{
                background: `none`,
                border: `none`,
                width: 1,
                height: 1,
                px: 0,
                borderRadius: `12px`,
              }}
            >
              {({ open }: any) => (
                <Box
                  component="span"
                  sx={{
                    border: open ? `1px solid #858080` : `1px solid #E7E6E6`,
                    height: 1,
                    width: 1,
                    px: 2,
                    py: 1.5,
                    backgroundImage: `url("/down-chevron.svg")`,
                    backgroundRepeat: `no-repeat`,
                    backgroundPosition: `right 8px center`,
                    borderBottomLeftRadius:
                      filteredLanguageOptions?.length > 0 && open ? 0 : `12px`,
                    borderBottomRightRadius:
                      filteredLanguageOptions?.length > 0 && open ? 0 : `12px`,
                    borderTopLeftRadius: `12px`,
                    borderTopRightRadius: `12px`,
                    backgroundColor: `#ffffff`,
                    textAlign: `left`,
                    cursor: `pointer`,
                    fontSize: `16px`,
                    lineHeight: `24px`,
                    letterSpacing: `-0.04px`,
                    fontWeight: 400,
                    color: `#858080`,
                    display: `block`,
                  }}
                >
                  {
                    languageOptions.find(
                      (languageOption) => languageOption.current,
                    )?.label
                  }
                </Box>
              )}
            </Box>
            {filteredLanguageOptions?.length > 0 && (
              <Box
                component={Listbox.Options}
                sx={{
                  position: `absolute`,
                  top: `calc(100% - 26px)`,
                  listStyleType: `none`,
                  width: 1,
                  padding: 0,
                  borderLeft: `1px solid #858080`,
                  borderRight: `1px solid #858080`,
                  borderBottom: `1px solid #858080`,
                  borderBottomLeftRadius: `12px`,
                  borderBottomRightRadius: `12px`,
                  overflow: `hidden`,
                  zIndex: 2,
                }}
              >
                {filteredLanguageOptions.map((altLang, i) => (
                  <Listbox.Option key={altLang.code} value={altLang.code}>
                    {() => (
                      <Box sx={{}}>
                        <Box
                          sx={{
                            cursor: `pointer`,
                            width: 1,
                            textAlign: `left`,
                            px: 2,
                            pb: 1.5,
                            pt: i === 0 ? 1.5 : 1.5,
                            mt: i === 0 ? 1 : 0,
                            color: `#858080`,
                            fontSize: `16px`,
                            lineHeight: `24px`,
                            letterSpacing: `-0.04px`,
                            fontWeight: 400,
                            // zIndex: `-1`,
                            backgroundColor: `#ffffff`,
                            '&:hover': {
                              backgroundColor: `#E7E6E6`,
                            },
                          }}
                        >
                          {languages[altLang.code]}
                        </Box>
                      </Box>
                    )}
                  </Listbox.Option>
                ))}
              </Box>
            )}
          </Box>
        </Listbox>
        <Box
          sx={{
            button: {
              color: `#0A0101`,
              background: `#EBB0CF`,
              border: `1px solid #D45398`,
              boxSizing: `border-box`,
              boxShadow: `2px 2px 0px #D45398`,
              borderRadius: `12px`,
              padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
              textDecoration: `none`,
              cursor: `pointer`,
              fontWeight: 600,
              ':hover': {
                boxShadow: `4px 4px 0px #D45398`,
                marginRight: theme.spacing(0.25),
                marginBottom: theme.spacing(0.25),
                marginLeft: theme.spacing(-0.25),
                marginTop: theme.spacing(-0.25),
              },
              ':active': {
                boxShadow: `1px 1px 0px #D45398`,
                marginLeft: theme.spacing(0.125),
                marginTop: theme.spacing(0.125),
                marginRight: theme.spacing(-0.125),
                marginBottom: theme.spacing(-0.125),
              },
            },
          }}
        >
          <ButtonUnstyled onClick={handleSubmit}>Continue</ButtonUnstyled>
        </Box>
      </Box>
    </Box>
  );
};
