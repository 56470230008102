import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { SliceZone } from '@prismicio/react';

import Layout from '@/components/Layout';
import { Helmet } from 'react-helmet';
import components from '../slices';

const HomepageTemplate = ({ data, pageContext }) => {
  const homepage = data.prismicHomepage;
  const { body: slices, social_body: socialBody } = homepage.data;

  // html head data
  const headData = useMemo(
    () =>
      socialBody.map(({ primary: { description, image, title } }) => ({
        title: title.text,
        description: description.text,
        ogTitle: title.text,
        ogDescription: description.text,
        ogImageUrl: image.url,
      }))[0],
    [socialBody],
  );

  return (
    <Layout
      {...headData}
      lang={pageContext.lang}
      altLangs={pageContext.alternate_languages}
    >
      <Helmet>
        <link rel="canonical" href="https://rosetta.ai/" />
      </Helmet>
      <SliceZone
        slices={slices}
        components={components}
        context={{ page: 'homepage', lang: pageContext.lang }}
      />
    </Layout>
  );
};

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      data {
        social_body {
          ... on PrismicHomepageDataSocialBodyGeneralCard {
            primary {
              description {
                text
              }
              image {
                url
              }
              title {
                text
              }
            }
          }
          ... on PrismicHomepageDataSocialBodyTwitterCard {
            primary {
              description {
                text
              }
              image {
                url
              }
              title {
                text
              }
            }
          }
        }
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...HomepageSplashSection
          ...ClientsGrid
          ...HomepageStats
          ...HomepageBanner
          ...HomepageInlineGrid
          ...HomepageContentSpot
          ...HomepagePlatforms
          ...TextGrid
          ...HomepageTabs
          ...HomepageForm
          ...HomePageCenteredContentSpot
        }
      }
    }
  }
`;

export default withPrismicPreview(HomepageTemplate);
