type LinkProps = {
  target: string | null;
  to?: string;
  href?: string;
};

export const getLinkProps = (target: string | null, url: string): LinkProps => {
  if (target) {
    return {
      href: url,
      target,
    };
  }
  return {
    to: url,
    target,
  };
};
