import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import { Box } from '@mui/material';
import Link from '@/components/Link';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import usePricingContext from '@/hooks/usePricingContext';

const SecondaryPricingTier = ({ slice }) => {
  const { primary } = slice;
  const { priceLoc } = usePricingContext();
  const {
    amountUS,
    amountJP,
    amountTW,
    ctaLabel,
    ctaLink,
    ctaMixpanelEvent,
    description,
    title,
  } = useMemo(() => {
    if (primary) {
      return {
        amountUS: primary?.secondary_pricing_tier_amount,
        amountJP: primary?.secondary_pricing_tier_amount_jp,
        amountTW: primary?.secondary_pricing_tier_amount_tw,
        ctaLabel: primary?.secondary_pricing_tier_cta_label,
        ctaLink: primary?.secondary_pricing_tier_cta_link,
        ctaMixpanelEvent: primary?.secondary_pricing_tier_cta_mixpanel_event,
        description: primary?.secondary_pricing_tier_description,
        title: primary?.secondary_pricing_tier_title,
      };
    }
    return {};
  }, [primary]);
  const amount =
    // eslint-disable-next-line no-nested-ternary
    priceLoc === `JP` ? amountJP : priceLoc === `TW` ? amountTW : amountUS;

  if (!primary) return <></>;
  return (
    <Box
      sx={{
        flexGrow: 1,
        flexBasis: 0,
        backgroundColor: `#F8F8F8`,
        p: 3,
        display: `flex`,
        flexDirection: `column`,
        gap: 2,
        width: 1,
        height: 1,
      }}
    >
      <Box sx={{ display: `flex`, alignItems: `stretch`, gap: 1 }}>
        {title.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            variant={type}
            customVariant="heading4"
          >
            {text}
          </CustomTypography>
        ))}
        <Box
          sx={{
            typography: `heading6`,
            color: `#0A0101`,
            display: `flex`,
            alignItems: `center`,
            backgroundColor: `#E7E6E6`,
            borderRadius: `100px`,
            px: 1.5,
            py: 0.5,
            fontSize: `11px`,
            lineHeight: `13.75px`,
            letterSpacing: `1.5px`,
          }}
        >
          {amount}
        </Box>
      </Box>
      {description.richText.map(({ text, type }) => (
        <CustomTypography
          key={uuidv4()}
          variant={type}
          customVariant="textSmall"
          sx={{ maxWidth: 473 }}
        >
          {text}
        </CustomTypography>
      ))}
      <Link
        href={ctaLink.url}
        target={ctaLink.target}
        dataEventAction={ctaMixpanelEvent}
        sx={{
          color: `#5298D5`,
          fontWeight: 600,
          gap: 1,
          mt: `auto`,
          display: `flex`,
          alignItems: `center`,
          img: {
            width: 16,
            height: 12,
            transitionProperty: 'transform',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDuration: '0.15s',
          },
          '&:hover': {
            img: {
              transform: `translate(4px, 0px)`,
            },
          },
        }}
      >
        {ctaLabel}
        <img src="/blue-arrow.svg" alt="blue-arrow" height="12" />
      </Link>
    </Box>
  );
};

export const query = graphql`
  fragment SecondaryPricingTier on PrismicPricingPageDataBody1SecondaryPricingTier {
    id
    primary {
      secondary_pricing_tier_amount
      secondary_pricing_tier_amount_jp
      secondary_pricing_tier_amount_tw
      secondary_pricing_tier_cta_label
      secondary_pricing_tier_cta_mixpanel_event
      secondary_pricing_tier_cta_link {
        url
        target
      }
      secondary_pricing_tier_description {
        richText
      }
      secondary_pricing_tier_title {
        richText
      }
    }
  }
`;
export default SecondaryPricingTier;
