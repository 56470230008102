/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { globalHistory } from '@reach/router';

import { AppBar, Stack, Toolbar, Box, Divider } from '@mui/material';

import DropdownMenu from '@/components/DropdownMenu';
import Link from '@/components/Link';
import ButtonLink from '@/components/ButtonLink';

import { Nav, UrlLike } from '@/types';
import { HamburgerButton } from '@/components/Menu/HamburgerButton';
import { MobileMenuModal } from '@/components/Menu/MobileMenuModal';
import { LanguageBannerContext } from '@/contexts/languageContext';
import { LanguageSwitcher } from './LanguageSwitcher';
// import { LanguageSwitcher } from './LanguageSwitcher';

export type CTAButton = {
  label: string;
  url: UrlLike;
  mixpanelEvent?: string;
};

export interface NavItem extends Nav {
  icon: IGatsbyImageData;
  description: { type: string; text: string };
}
export interface GroupNav extends Omit<Nav, 'url'> {
  items: NavItem[];
}
export const isGroupNav = (nav: Nav | GroupNav): nav is GroupNav =>
  !!(nav as GroupNav).items;

export type HeaderProps = {
  headerLogo: { gatsbyImageData: IGatsbyImageData; alt: string; link: UrlLike };
  headerLogoMobile: { gatsbyImageData: IGatsbyImageData; alt: string };
  navs: Array<Nav | GroupNav>;
  tertiaryCTA: CTAButton;
  secondaryCTA: CTAButton;
  mainCTA: CTAButton;
  lang: string;
  altLangs: {
    id: string;
    type: string;
    lang: string;
    uid: string;
    link_type: 'Document';
    tags: string[];
  }[];
};

const Header = ({
  headerLogo,
  headerLogoMobile,
  navs,
  tertiaryCTA,
  secondaryCTA,
  mainCTA,
  lang,
  altLangs,
}: HeaderProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { isOpen: isLanguageBannerOpen } = React.useContext(
    LanguageBannerContext,
  );

  useEffect(() => {
    function handleResize() {
      window.innerWidth >= 1200 ? setIsOpen(false) : ``;
    }
    window.addEventListener(`resize`, handleResize);
    return () => {
      // unbind event listener on cleanup
      document.removeEventListener(`resize`, handleResize);
    };
  }, [setIsOpen]);

  useEffect(() => {
    isOpen
      ? (document.body.style.overflow = `hidden`)
      : (document.body.style.overflow = `unset`);
    return () => {
      document.body.style.overflow = `unset`;
    };
  }, [isOpen]);

  useEffect(
    () =>
      globalHistory.listen(({ action }) => {
        if (action === `PUSH`) setIsOpen(false);
      }),
    [setIsOpen],
  );

  return (
    <>
      {/* <Slide in={!trigger} appear={false}> */}
      <AppBar
        color="default"
        sx={{
          backgroundColor: `#fff`,
          boxShadow: `none`,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          ...{
            borderBottom: { xs: `1px solid rgb(231,230,230)` },
          },
        }}
      >
        {isLanguageBannerOpen && (
          <LanguageSwitcher lang={lang} altLangs={altLangs} />
        )}
        <Box sx={{ px: { xs: 3, sm: 4, lg: 13.5 } }}>
          <MobileMenuModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            logo={headerLogo}
            navs={navs}
            mainCTA={mainCTA}
            secondaryCTA={secondaryCTA}
            tertiaryCTA={tertiaryCTA}
          />
          <Toolbar
            sx={{
              maxWidth: `xl`,
              mx: `auto`,
              width: 1,
              px: { xs: 0, sm: 0, md: 0, lg: 0, xl: 0 },
            }}
          >
            <Link
              to={headerLogo.link.url}
              sx={{ display: { xs: `none`, md: `block` } }}
            >
              <GatsbyImage
                image={headerLogo.gatsbyImageData}
                alt={headerLogo.alt}
              />
            </Link>
            <Link
              to={headerLogo.link.url}
              sx={{ display: { xs: `block`, md: `none` } }}
            >
              <GatsbyImage
                image={headerLogoMobile.gatsbyImageData}
                alt={headerLogoMobile.alt}
              />
            </Link>
            <Box sx={{ flex: `1 1 auto` }} />
            {/* Mobile */}
            <>
              <Stack
                direction="row"
                spacing={2}
                sx={{ display: { xs: `flex`, lg: `none` } }}
              >
                <Box
                  sx={{
                    display: `flex`,
                    alignItems: `center`,
                    gap: 4.5,
                    py: { xs: 2, lg: 0 },
                  }}
                >
                  {mainCTA.label && (
                    <ButtonLink
                      to={mainCTA.url.url}
                      target={mainCTA.url.target}
                      dataEventAction={mainCTA.mixpanelEvent}
                    >
                      {mainCTA.label}
                    </ButtonLink>
                  )}
                  <HamburgerButton clickHandler={() => setIsOpen(!isOpen)} />
                </Box>
              </Stack>
            </>
            {/* desktop */}
            <>
              <Stack
                direction="row"
                spacing={2}
                sx={{ display: { xs: `none`, lg: `flex` } }}
              >
                {navs.map((nav) => (
                  <Box
                    key={nav.title}
                    sx={{ display: `flex`, alignItems: `stretch` }}
                  >
                    {isGroupNav(nav) ? (
                      <DropdownMenu title={nav.title} items={nav.items} />
                    ) : (
                      <Link
                        to={nav.url.url}
                        color="inherit"
                        sx={{
                          px: 2,
                          display: `flex`,
                          alignItems: `center`,
                          fontWeight: 600,
                          lineHeight: 1.5,
                          transitionProperty: `all`,
                          transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
                          transitionDuration: `150ms`,
                          color: `#858080`,
                          '&:hover': {
                            color: `#231A1A`,
                          },
                        }}
                      >
                        {nav.title}
                      </Link>
                    )}
                  </Box>
                ))}
                <Box sx={{ display: `flex`, alignItems: `center`, gap: 4.5 }}>
                  <Divider orientation="vertical" />
                  {tertiaryCTA.label && (
                    <Link
                      to={tertiaryCTA.url.url}
                      href={tertiaryCTA.url.url}
                      target={tertiaryCTA.url.target}
                      dataEventAction={tertiaryCTA.mixpanelEvent}
                      color="#858080"
                      sx={{
                        fontWeight: 500,
                        letterSpacing: `-0.08px`,
                      }}
                    >
                      {tertiaryCTA.label}
                    </Link>
                  )}
                  {secondaryCTA.label && (
                    <Link
                      to={secondaryCTA.url.url}
                      href={secondaryCTA.url.url}
                      target={secondaryCTA.url.target}
                      dataEventAction={secondaryCTA.mixpanelEvent}
                      color="#858080"
                      sx={{
                        fontWeight: 500,
                        letterSpacing: `-0.08px`,
                      }}
                    >
                      {secondaryCTA.label}
                    </Link>
                  )}
                  {mainCTA.label && (
                    <ButtonLink
                      to={mainCTA.url.url}
                      target={mainCTA.url.target}
                      dataEventAction={mainCTA.mixpanelEvent}
                    >
                      {mainCTA.label}
                    </ButtonLink>
                  )}
                </Box>
              </Stack>
            </>
          </Toolbar>
        </Box>
      </AppBar>
      {/* </Slide> */}
      <Toolbar sx={{ minHeight: { xs: 74, lg: 64 } }} />
    </>
  );
};

export default Header;
