/* eslint-disable import/no-unresolved */
import React, { useMemo, useState } from 'react';
import { graphql } from 'gatsby';

import { Box } from '@mui/material';

import Section from '@/components/Section';
import getBackgroundColor from '@/utils/getBackgroundColor';
import Typography from '@/components/Typography';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import '@/styles/swiper.css';
import { Pagination } from 'swiper';
import { HiArrowLeft, HiArrowRight } from 'react-icons/hi';
import { v4 as uuidv4 } from 'uuid';
import Link from '@/components/Link';
import { CustomTypography } from '@/components/Typography/CustomTypography';

const FeaturedPosts = ({ slice }) => {
  const { items, primary } = slice;
  const { featuredPostsTitle, featuredPostsStyle } = useMemo(() => {
    if (primary) {
      return {
        featuredPostsTitle: slice.primary?.featured_posts_section_title,
        featuredPostsStyle: slice.primary?.featured_posts_styling,
      };
    }
    return {
      featuredPostsTitle: { richText: [] },
      featuredPostsStyle: '',
    };
  }, [primary]);
  const { backgroundColor, color } = getBackgroundColor(featuredPostsStyle);

  const pagination = {
    clickable: true,
    renderBullet(index, className) {
      return `<span class="${className}"></span>`;
    },
  };

  const [swiper, setSwiper] = useState({});

  const docType = useMemo(() => {
    if (
      items &&
      items[0].featured_blogpost &&
      items[0].featured_blogpost.document &&
      items[0].featured_blogpost.document.type
    ) {
      return items[0].featured_blogpost.document.type;
    }
    return null;
  }, [items]);

  const featuredItems = useMemo(() => {
    if (docType === 'case_study_page') {
      return items.map((item) => ({
        url:
          item.featured_blogpost && item.featured_blogpost.document
            ? item.featured_blogpost.document.url
            : ``,
        description:
          item.featured_blogpost &&
          item.featured_blogpost.document &&
          item.featured_blogpost.document.data
            ? item.featured_blogpost.document.data.case_study_description
            : ``,
        title:
          item.featured_blogpost &&
          item.featured_blogpost.document &&
          item.featured_blogpost.document.data
            ? item.featured_blogpost.document.data.case_study_headline
            : ``,
        image:
          item.featured_blogpost &&
          item.featured_blogpost.document &&
          item.featured_blogpost.document.data
            ? item.featured_blogpost.document.data.case_study_background_image
            : ``,
        cta_text: item.cta_text,
      }));
    }
    if (docType === 'ebook_and_report_page') {
      return items.map((item) => ({
        url:
          item.featured_blogpost && item.featured_blogpost.document
            ? item.featured_blogpost.document.url
            : ``,
        description:
          item.featured_blogpost &&
          item.featured_blogpost.document &&
          item.featured_blogpost.document.data
            ? item.featured_blogpost.document.data.splash_section_description
            : ``,
        title:
          item.featured_blogpost &&
          item.featured_blogpost.document &&
          item.featured_blogpost.document.data
            ? item.featured_blogpost.document.data.splash_section_headline
            : ``,
        image:
          item.featured_blogpost &&
          item.featured_blogpost.document &&
          item.featured_blogpost.document.data
            ? item.featured_blogpost.document.data.splash_section_image
            : ``,
        cta_text: item.cta_text,
      }));
    }
    if (docType === 'press_post') {
      return items.map((item) => ({
        url:
          item.featured_blogpost && item.featured_blogpost.document
            ? item.featured_blogpost.document.url
            : ``,
        description:
          item.featured_blogpost &&
          item.featured_blogpost.document &&
          item.featured_blogpost.document.data
            ? item.featured_blogpost.document.data.press_post_description
            : ``,
        title:
          item.featured_blogpost &&
          item.featured_blogpost.document &&
          item.featured_blogpost.document.data
            ? item.featured_blogpost.document.data.press_post_title
            : ``,
        image:
          item.featured_blogpost &&
          item.featured_blogpost.document &&
          item.featured_blogpost.document.data
            ? item.featured_blogpost.document.data.press_post_image
            : ``,
        cta_text: item.cta_text,
      }));
    }
    return items.map((item) => ({
      url:
        item.featured_blogpost &&
        item.featured_blogpost.document &&
        item.featured_blogpost.document.url
          ? item.featured_blogpost.document.url
          : '',
      description:
        item.featured_blogpost &&
        item.featured_blogpost.document &&
        item.featured_blogpost.document.data
          ? item.featured_blogpost.document.data.blog_post_description
          : ``,
      title:
        item.featured_blogpost &&
        item.featured_blogpost.document &&
        item.featured_blogpost.document.data
          ? item.featured_blogpost.document.data.blog_post_title
          : ``,
      image:
        item.featured_blogpost &&
        item.featured_blogpost.document &&
        item.featured_blogpost.document.data
          ? item.featured_blogpost.document.data.blog_post_image
          : ``,
      cta_text: item.cta_text,
    }));
  });

  if (!primary) return <></>;

  return (
    <Section
      sx={{
        px: { xs: 3, sm: 4, md: 13.5 },
        py: { xs: 3, sm: 4, md: 9 },
        backgroundColor,
      }}
    >
      <Box
        sx={{
          maxWidth: 'xl',
          mx: 'auto',
          width: 1,
        }}
      >
        <Box sx={{ mb: { xs: 2, sm: 3 } }}>
          {featuredPostsTitle.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              variant={type}
              customVariant="heading3"
              sx={{ color, textAlign: `center` }}
            >
              {text}
            </CustomTypography>
          ))}
        </Box>
        <Swiper
          pagination={pagination}
          modules={[Pagination]}
          className="swiper-container"
          loop
          onInit={(ev) => {
            setSwiper(ev);
          }}
        >
          {featuredItems &&
            featuredItems.length > 0 &&
            featuredItems.map((item) => (
              <SwiperSlide key={uuidv4()}>
                <Slide item={item} />
              </SwiperSlide>
            ))}
          <Box
            sx={{
              position: `absolute`,
              right: 0,
              bottom: -6,
              svg: {
                width: 24,
                height: 19,
                fill: '#B6B3B3',
                transitionProperty: `all`,
                transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
                transitionDuration: `150ms`,
                '&:hover': { fill: '#544D4D' },
              },
              button: {
                background: 'none',
                border: 'none',
                outline: 'none',
                cursor: 'pointer',
              },
            }}
          >
            <button type="button" onClick={() => swiper.slidePrev()}>
              <HiArrowLeft />
            </button>
            <button type="button" onClick={() => swiper.slideNext()}>
              <HiArrowRight />
            </button>
          </Box>
        </Swiper>
      </Box>
    </Section>
  );
};

const Slide = ({ item }) => (
  <Box
    sx={{
      height: 491,
      width: {
        xs: `calc(100% - 24px)`,
        sm: `calc(100% - 32px)`,
        md: `calc(100% - 64px)`,
        lg: `calc(100% - 80px)`,
      },
      backgroundImage:
        item.image &&
        item.image.url &&
        `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.25)), url("${item.image.url}")`,
      backgroundPosition: `center`,
      backgroundSize: `cover`,
      backgroundColor: '#544D4D',
      display: `flex`,
      flexDirection: `column`,
      gap: 1,
      justifyContent: `end`,
      py: { xs: 1.5, sm: 6.125, lg: 5 },
      px: { xs: 1.5, sm: 2, md: 4, lg: 5 },
    }}
  >
    {item.title &&
      item.title.richText.map(({ text }) => (
        <Typography
          key={uuidv4()}
          variant="heading2"
          sx={{ fontSize: { xs: 40 }, color: '#ffffff' }}
        >
          {text}
        </Typography>
      ))}
    {item.description &&
      item.description.richText.map(({ text, type }) => (
        <CustomTypography
          customVariant="paragraphMedium"
          key={uuidv4()}
          variant={type}
          sx={{ color: '#ffffff' }}
        >
          {text}
        </CustomTypography>
      ))}
    <Link to={item.url}>
      <Typography
        variant="paragraphMedium"
        sx={{
          color: '#5298D5',
          fontWeight: 600,
          display: `flex`,
          alignItems: `center`,
          gap: 1,
          svg: {
            transitionProperty: 'transform',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDuration: '0.15s',
          },
          '&:hover': {
            svg: {
              transform: `translate(4px, 0px)`,
            },
          },
        }}
      >
        {item.cta_text}
        <HiArrowRight />
      </Typography>
    </Link>
  </Box>
);

export default FeaturedPosts;

export const query = graphql`
  fragment BlogHomePageFeaturedPosts on PrismicBlogHomePageDataBodyFeaturedPosts {
    primary {
      featured_posts_styling
      featured_posts_section_title {
        richText
        text
      }
    }
    items {
      cta_text
      featured_blogpost {
        document {
          ... on PrismicBlogPost {
            id
            url
            type
            data {
              blog_post_description {
                richText
              }
              blog_post_title {
                richText
              }
              blog_post_image {
                url
              }
            }
          }
        }
      }
    }
  }

  fragment CaseStudiesPageFeaturedPosts on PrismicCaseStudiesPageDataBodyFeaturedPosts {
    primary {
      featured_posts_styling
      featured_posts_section_title {
        richText
        text
      }
    }
    items {
      cta_text
      featured_blogpost {
        document {
          ... on PrismicCaseStudyPage {
            id
            url
            type
            data {
              case_study_description {
                richText
              }
              case_study_headline {
                richText
              }
              case_study_background_image {
                url
              }
            }
          }
        }
      }
    }
  }

  fragment EbookAndReportsPageFeaturedPosts on PrismicEbookAndReportsPageDataBodyFeaturedPosts {
    primary {
      featured_posts_styling
      featured_posts_section_title {
        richText
        text
      }
    }
    items {
      cta_text
      featured_blogpost {
        document {
          ... on PrismicEbookAndReportPage {
            id
            url
            type
            data {
              splash_section_description {
                richText
              }
              splash_section_headline {
                richText
              }
              splash_section_image {
                url
              }
            }
          }
        }
      }
    }
  }

  fragment PressPageFeaturedPosts on PrismicPressPageDataBodyFeaturedPosts {
    primary {
      featured_posts_styling
      featured_posts_section_title {
        richText
        text
      }
    }
    items {
      cta_text
      featured_blogpost {
        document {
          ... on PrismicPressPost {
            id
            url
            type
            data {
              press_post_description {
                richText
              }
              press_post_title {
                richText
              }
              press_post_image {
                url
              }
            }
          }
        }
      }
    }
  }
`;
