import React from 'react';
import Link from '@/components/Link';
import { styled } from '@mui/material/styles';
import { BaseComponentProps } from '@/types';
import { CSSObject } from '@emotion/styled';

const PrimaryLink = styled(Link)(({ theme }) => ({
  color: `#0A0101`,
  background: `#EBB0CF`,
  border: `1px solid #D45398`,
  boxSizing: `border-box`,
  boxShadow: `2px 2px 0px #D45398`,
  borderRadius: `12px`,
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  textDecoration: `none`,
  fontWeight: 600,
  ':hover': {
    boxShadow: `4px 4px 0px #D45398`,
    marginRight: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    marginLeft: theme.spacing(-0.25),
    marginTop: theme.spacing(-0.25),
  },
  ':active': {
    boxShadow: `1px 1px 0px #D45398`,
    marginLeft: theme.spacing(0.125),
    marginTop: theme.spacing(0.125),
    marginRight: theme.spacing(-0.125),
    marginBottom: theme.spacing(-0.125),
  },
}));

const SecondaryLink = styled(Link)(({ theme }) => ({
  color: `#0A0101`,
  background: `#FFFFFF`,
  border: `1px solid #0A0101`,
  boxSizing: `border-box`,
  boxShadow: `2px 2px 0px #0A0101`,
  borderRadius: `12px`,
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  textDecoration: `none`,
  fontWeight: 600,
  ':hover': {
    boxShadow: `4px 4px 0px #0A0101`,
    marginRight: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    marginLeft: theme.spacing(-0.25),
    marginTop: theme.spacing(-0.25),
  },
  ':active': {
    boxShadow: `1px 1px 0px #0A0101`,
    marginLeft: theme.spacing(0.125),
    marginTop: theme.spacing(0.125),
    marginRight: theme.spacing(-0.125),
    marginBottom: theme.spacing(-0.125),
  },
}));

const TertiaryLink = styled(Link)(({ theme }) => ({
  background: `#0A0101`,
  color: `#FFFFFF`,
  border: `1px solid #FFFFFF`,
  boxSizing: `border-box`,
  boxShadow: `2px 2px 0px #0A0101`,
  borderRadius: `12px`,
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  textDecoration: `none`,
  fontWeight: 600,
  ':hover': {
    boxShadow: `4px 4px 0px #0A0101`,
    marginRight: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25),
    marginLeft: theme.spacing(-0.25),
    marginTop: theme.spacing(-0.25),
  },
  ':active': {
    boxShadow: `1px 1px 0px #0A0101`,
    marginLeft: theme.spacing(0.125),
    marginTop: theme.spacing(0.125),
    marginRight: theme.spacing(-0.125),
    marginBottom: theme.spacing(-0.125),
  },
}));

const getStyledLinkComponent = (type: 'Primary' | 'Secondary' | 'Tertiary') => {
  if (type === `Primary`) {
    return PrimaryLink;
  }
  if (type === `Secondary`) {
    return SecondaryLink;
  }
  return TertiaryLink;
};

interface ButtonLinkProps extends BaseComponentProps {
  to: string;
  target: string | undefined;
  type?: 'Primary' | 'Secondary' | 'Tertiary';
  sx?: CSSObject;
  dataEventAction?: string;
}
const ButtonLink = ({
  to,
  target,
  sx,
  children,
  type,
  dataEventAction,
}: ButtonLinkProps): JSX.Element => {
  const StyledLink = getStyledLinkComponent(
    type as 'Primary' | 'Secondary' | 'Tertiary',
  );
  return (
    <StyledLink
      to={to}
      href={to}
      target={target}
      sx={sx}
      dataEventAction={dataEventAction}
    >
      {children}
    </StyledLink>
  );
};

ButtonLink.defaultProps = {
  sx: {},
  type: `Primary`,
  dataEventAction: null,
};

export default ButtonLink;
