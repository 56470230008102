import React from 'react';

export const Chevron = (): JSX.Element => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.714286 6C0.914286 6 1.09286 5.9175 1.22143 5.7825L5 1.8075L8.77857 5.7825C8.90714 5.9175 9.08571 6 9.28571 6C9.67857 6 10 5.6625 10 5.25C10 5.04 9.92143 4.8525 9.79286 4.7175L5.50714 0.2175C5.37857 0.0824995 5.2 0 5 0C4.8 0 4.62143 0.0824995 4.49286 0.2175L0.207143 4.7175C0.0785713 4.8525 0 5.04 0 5.25C0 5.6625 0.321428 6 0.714286 6Z"
    />
  </svg>
);
