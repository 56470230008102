import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import { Box } from '@mui/material';

import Section from '@/components/Section';
import getBackgroundColor from '@/utils/getBackgroundColor';
import Typography from '@/components/Typography';
import { GatsbyImage } from 'gatsby-plugin-image';
import { CustomTypography } from '@/components/Typography/CustomTypography';

const Quote = ({ slice }) => {
  const { primary } = slice;
  const { quote, attribution, image, quoteStyle } = useMemo(() => {
    if (primary) {
      return {
        quote: primary?.quote,
        attribution: primary?.attribution,
        image: primary?.image,
        quoteStyle: primary?.quote_style,
      };
    }
    return {};
  }, [primary]);
  const { backgroundColor, color, fourthColor } =
    getBackgroundColor(quoteStyle);

  if (!primary) return <></>;
  return (
    <Section
      sx={{
        backgroundColor,
        color,
        overflow: `hidden`,
        px: { xs: 3, sm: 4, md: 13.5 },
        py: { xs: 5, lg: 9 },
      }}
    >
      <Box
        sx={{
          maxWidth: '1010px',
          mx: 'auto',
          width: 1,
          display: `flex`,
          flexDirection: { xs: `column`, sm: `row` },
          justifyContent: `center`,
          alignItems: `center`,
          gap: 3,
          '.gatsby-image-wrapper': {
            minWidth: 180,
            minHeight: 180,
            maxWidth: 180,
            maxHeight: 180,
          },
        }}
      >
        {image.gatsbyImageData && (
          <GatsbyImage alt={image.alt} image={image.gatsbyImageData} />
        )}

        <Box
          sx={{
            mr: 'auto',
            display: `flex`,
            flexDirection: `column`,
            gap: 2,
          }}
        >
          {quote.richText.map(({ text, type }) => (
            <CustomTypography
              key={uuidv4()}
              variant={type}
              customVariant="quote"
            >
              {text}
            </CustomTypography>
          ))}
          <Typography variant="paragraphMedium" sx={{ color: fourthColor }}>
            {attribution}
          </Typography>
        </Box>
      </Box>
    </Section>
  );
};
export default Quote;

export const query = graphql`
  fragment CaseStudyQuote on PrismicCaseStudyPageDataBodyQuote {
    id
    slice_type
    primary {
      attribution
      quote_style
      image {
        alt
        gatsbyImageData
      }
      quote {
        richText
      }
    }
  }
  fragment LandingPageAfterPricingQuote on PrismicLandingPageDataBody1Quote {
    id
    slice_type
    primary {
      attribution
      quote_style
      image {
        alt
        gatsbyImageData
      }
      quote {
        richText
      }
    }
  }
`;
