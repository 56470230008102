import React from 'react';
import lottie from 'lottie-web';
import { graphql } from 'gatsby';
import Section from '@/components/Section';

const LottieTest = ({ slice }) => {
  const container = React.useRef(null);
  const animationData = JSON.parse(slice?.primary?.json_animation_data?.text);
  React.useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      loop: slice?.primary?.loop,
      autoplay: slice?.primary?.autoplay,
      animationData,
    });
  }, []);

  if (!slice.primary) return <></>;
  return (
    <Section>
      <div>
        <h1>The lottie slice</h1>
        <div
          ref={container}
          onMouseEnter={
            slice.primary.play_on_hover ? () => lottie.play() : undefined
          }
          onMouseLeave={
            slice.primary.play_on_hover ? () => lottie.pause() : undefined
          }
        />
      </div>
    </Section>
  );
};

export default LottieTest;

export const query = graphql`
  fragment LottieTest on PrismicLandingPageDataBodyLottieTest {
    primary {
      autoplay
      loop
      play_on_hover
      json_animation_data {
        text
      }
    }
  }
`;
