/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HEntry } from '@/types';
import { Box } from '@mui/material';
import React, { useRef } from 'react';

export const Headings = ({ entry }: { entry: HEntry }): JSX.Element => {
  const aRef = useRef<HTMLAnchorElement>(null);

  return (
    <Box
      sx={{
        a: {
          textDecoration: `none`,
          color: `#544D4D`,
          fontSize: `12px`,
          lineHeight: `18px`,
          fontWeight: 400,
        },
        '.active': {
          fontWeight: 600,
        },
        li: { listStyleType: `none` },
        display: `flex`,
        flexDirection: `column`,
        gap: 1,
      }}
    >
      <a
        href={`#${entry.id}`}
        className="h"
        ref={aRef}
        onClick={(e) => {
          e.preventDefault();
          const element = document.querySelector(`#${entry.id}`);
          const y =
            element!.getBoundingClientRect().top + window.pageYOffset - 75;
          window!.scrollTo({ top: y, behavior: `smooth` });
        }}
      >
        {entry.text}
      </a>
      {entry.items && (
        <Box
          component="ul"
          sx={{ display: `flex`, flexDirection: `column`, gap: 1 }}
        >
          {entry.items.map((h) => (
            <li key={h.id}>
              <Headings entry={h} />
            </li>
          ))}
        </Box>
      )}
    </Box>
  );
};
