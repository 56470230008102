import Section from '@/components/Section';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import { FormControlUnstyled } from '@mui/base';
import { Box, Button, InputLabel, TextField } from '@mui/material';
import { graphql } from 'gatsby';
import _ from 'lodash';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useForm } from 'react-hook-form';
import theme from '@/theme';
import { useFormspark } from '@formspark/use-formspark';

const Form = ({ slice }) => {
  const { items, primary } = slice;

  const [submitMessage, setSubmitMessage] = React.useState('');
  const [submit, submitting] = useFormspark({
    formId: primary?.formspark_id || '',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    // watch,
    resetField,
  } = useForm();

  const onSubmit = async (data) => {
    // Reset data and set loading true
    setSubmitMessage('');

    // Handle honeypot error
    if (data.honeypot) {
      setSubmitMessage('Error 321!');
      return;
    }

    // Remove honeypot field from data
    Object.keys(data).forEach((key) => {
      if (key === 'honeypot') {
        // eslint-disable-next-line no-param-reassign
        delete data[key];
      }
    });

    await submit(data)
      .then(() => {
        // update state
        setSubmitMessage('Thank you for your submission!');
        // Reset form
        items.forEach((item) => resetField(_.kebabCase(item.field_name)));
      })
      .catch(() => {
        setSubmitMessage('Error while submitting form!');
      });
  };

  if (!primary) return <></>;

  return (
    <Section
      sx={{ py: { xs: 8, sm: 16 }, px: { xs: 3, sm: 4 }, position: 'relative' }}
    >
      <Box
        sx={{
          position: 'absolute',
          backgroundImage: 'url("/Cubes-Right.svg")',
          height: '100%',
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right top',
          top: 0,
          right: 0,
          display: { xs: 'none', lg: 'block' },
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          backgroundImage: 'url("/Cubes-Left.svg")',
          height: '100%',
          width: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left bottom',
          top: 0,
          left: 0,
          display: { xs: 'none', lg: 'block' },
        }}
      />
      <Box
        sx={{
          maxWidth: 480,
          width: 1,
          mx: 'auto',
        }}
      >
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            mb: 3,
          }}
        >
          {primary.title &&
            primary.title.richText.map(({ text, type }) => (
              <CustomTypography
                key={uuidv4()}
                variant={type}
                customVariant="heading2"
                sx={{ lineHeight: 1.1 }}
              >
                {text}
              </CustomTypography>
            ))}
          {primary.description &&
            primary.description.richText.map(({ text, type }) => (
              <CustomTypography
                key={uuidv4()}
                variant={type}
                customVariant="paragraph"
                sx={{ lineHeight: 1.4 }}
              >
                {text}
              </CustomTypography>
            ))}
        </Box>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
              gap: 3,
            }}
          >
            {/*  */}
            {/* Prismic fields */}
            {items.map((item) => (
              <Box
                sx={{
                  gridColumn: `span ${item.col_span} / span ${item.col_span}`,
                }}
              >
                <FormControlUnstyled>
                  <InputLabel
                    htmlFor={_.kebabCase(item.field_name)}
                    sx={{
                      mb: '6px',
                      fontSize: '12px',
                      lineHeight: '16px',
                      span: { color: '#D45398' },
                    }}
                  >
                    {item.field_name}
                    {item.field_required && <span>*</span>}
                  </InputLabel>
                  <TextField
                    id={_.kebabCase(item.field_name)}
                    placeholder={item.field_placeholder_text}
                    autoComplete={item.field_autocomplete_type || 'off'}
                    defaultValue=""
                    error={errors[_.kebabCase(item.field_name)]}
                    sx={{
                      width: '100%',
                      div: { fontSize: '12px' },
                      input: {
                        p: 1,
                        '&::placeholder': {
                          textOverflow: 'ellipsis !important',
                          color: '#CECCCC',
                        },
                      },
                      [`& fieldset`]: {
                        borderRadius: 2,
                      },
                    }}
                    {...register(_.kebabCase(item.field_name), {
                      required:
                        item.field_required && `${item.field_name} is required`,
                      pattern: item.field_autocomplete_type === 'email' && {
                        value:
                          // eslint-disable-next-line no-useless-escape
                          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                        message: 'Please enter a valid email',
                      },
                    })}
                    disabled={submitting}
                  />
                  {errors[_.kebabCase(item.field_name)] && (
                    <Box
                      sx={{ fontSize: 12, mt: 1, ml: 0.5, color: '#D45398' }}
                    >
                      {errors[_.kebabCase(item.field_name)].message}
                    </Box>
                  )}
                </FormControlUnstyled>
                {/* {watch()} */}
              </Box>
            ))}

            {/* Honeypot */}
            <TextField
              {...register('honeypot')}
              id="honeypot"
              defaultValue=""
              autoComplete="off"
              sx={{
                display: 'none',
                gridColumn: `span 2 / span 2`,
              }}
            />

            {/* Submit */}
            <Box
              sx={{
                mx: 'auto',
                gridColumn: `span 2 / span 2`,
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Button
                sx={{
                  color: `#0A0101`,
                  background: `#EBB0CF`,
                  border: `1px solid #D45398`,
                  boxSizing: `border-box`,
                  boxShadow: `2px 2px 0px #D45398`,
                  borderRadius: `12px`,
                  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
                  textDecoration: `none`,
                  fontWeight: 600,
                  ':hover': {
                    boxShadow: `4px 4px 0px #D45398`,
                    marginRight: theme.spacing(0.25),
                    marginBottom: theme.spacing(0.25),
                    marginLeft: theme.spacing(-0.25),
                    marginTop: theme.spacing(-0.25),
                  },
                  ':active': {
                    boxShadow: `1px 1px 0px #D45398`,
                    marginLeft: theme.spacing(0.125),
                    marginTop: theme.spacing(0.125),
                    marginRight: theme.spacing(-0.125),
                    marginBottom: theme.spacing(-0.125),
                  },
                }}
                type="submit"
                disabled={submitting}
              >
                {primary.button_text || 'submit'}
              </Button>
              <Box sx={{ textAlign: 'center', fontSize: 12, lineHeight: 1.33 }}>
                {submitMessage}
              </Box>
            </Box>

            {/* Output helper */}
            {/* <pre>{JSON.stringify(watch(), null, 2)}</pre> */}
            {/*  */}
          </Box>
        </form>
      </Box>
    </Section>
  );
};

export const query = graphql`
  fragment HomepageForm on PrismicHomepageDataBodyForm {
    primary {
      description {
        richText
      }
      title {
        richText
      }
      button_text
      formspark_id
    }
    items {
      col_span
      field_name
      field_placeholder_text
      field_autocomplete_type
      field_required
    }
  }
  fragment LandingPageForm on PrismicLandingPageDataBodyForm {
    primary {
      description {
        richText
      }
      title {
        richText
      }
      button_text
      formspark_id
    }
    items {
      col_span
      field_name
      field_placeholder_text
      field_autocomplete_type
      field_required
    }
  }
  # Company page
  fragment CompanyPageForm on PrismicCompanyPageDataBodyForm {
    primary {
      description {
        richText
      }
      title {
        richText
      }
      button_text
      formspark_id
    }
    items {
      col_span
      field_name
      field_placeholder_text
      field_autocomplete_type
      field_required
    }
  }
  # Feature page
  fragment FeaturePageForm on PrismicFeatureDataBodyForm {
    primary {
      description {
        richText
      }
      title {
        richText
      }
      button_text
      formspark_id
    }
    items {
      col_span
      field_name
      field_placeholder_text
      field_autocomplete_type
      field_required
    }
  }
  # Resource page
  fragment ResourcePageForm on PrismicResourcePageDataBodyForm {
    primary {
      description {
        richText
      }
      title {
        richText
      }
      button_text
      formspark_id
    }
    items {
      col_span
      field_name
      field_placeholder_text
      field_autocomplete_type
      field_required
    }
  }
`;

export default Form;
