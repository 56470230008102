/* eslint-disable @typescript-eslint/no-empty-function */
import { getStorageLng, languages } from '@/data/languages';
import * as React from 'react';

type LanguageBannerContextType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const LanguageBannerContext =
  React.createContext<LanguageBannerContextType>({
    isOpen: false,
    setIsOpen: () => {},
  });

export const LanguageBannerContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactNode => {
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    const lngInStorage = getStorageLng();
    if (lngInStorage && lngInStorage in languages) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, []);
  return (
    <LanguageBannerContext.Provider value={{ isOpen, setIsOpen }}>
      {children}
    </LanguageBannerContext.Provider>
  );
};
