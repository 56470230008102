import { redirect } from '@/data/languages';
import * as React from 'react';
import Link from '@/components/Link';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { PrismicProvider } from '@prismicio/react';

import { repositoryConfigs } from '@/utils/prismicPreviews';
import { LanguageBannerContextProvider } from '@/contexts/languageContext';
import PricingContextProvider from '@/provider/PricingContextProvider';
import event, { initEventConfig } from '@/event';
import { linkResolver } from './src/utils/linkResolver';

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      <LanguageBannerContextProvider>
        <PricingContextProvider>{element}</PricingContextProvider>
      </LanguageBannerContextProvider>
    </PrismicPreviewProvider>
  </PrismicProvider>
);

export const onClientEntry = () => {
  initEventConfig();
};

export const onRouteUpdate = ({ location }) => {
  event.page.pageView(location);
};

export const onInitialClientRender = () => {
  redirect();
};
