/* eslint-disable no-nested-ternary */
import React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { v4 as uuidv4 } from 'uuid';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import Section from '@/components/Section';
import { TOC } from '@/components/TOC/TOC';
import _ from 'lodash';
import { NextPrevPosts } from '@/components/NextPrevPosts/NextPrevPosts';

const StyledBox = styled(Box)(({ theme, large, post }) => ({
  ...(post ? theme.typography.paragraphLarge : theme.typography.paragraph),
  h1: {
    ...theme.typography.heading1,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
    // if the text contains larger characters, we need to adjust the line height and letter spacing
    ...(large && {
      lineHeight: 1.3,
      letterSpacing: 0,
    }),
  },
  h2: {
    ...theme.typography.heading2,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
    // if the text contains larger characters, we need to adjust the line height and letter spacing
    ...(large && {
      lineHeight: 1.3,
      letterSpacing: 0,
    }),
  },
  h3: {
    ...theme.typography.heading3,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
    // if the text contains larger characters, we need to adjust the line height and letter spacing
    ...(large && {
      lineHeight: 1.3,
      letterSpacing: 0,
    }),
  },
  h4: {
    ...theme.typography.heading4,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
    // if the text contains larger characters, we need to adjust the line height and letter spacing
    ...(large && {
      lineHeight: 1.3,
      letterSpacing: 0,
    }),
  },
  h5: {
    ...theme.typography.heading5,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
    // if the text contains larger characters, we need to adjust the line height and letter spacing
    ...(large && {
      lineHeight: 1.3,
      letterSpacing: 0,
    }),
  },
  h6: {
    ...theme.typography.heading6,
    color: 'black',
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
    // if the text contains larger characters, we need to adjust the line height and letter spacing
    ...(large && {
      lineHeight: 1.3,
      letterSpacing: 0,
    }),
  },
  a: {
    color: `#B1D0EC`,
  },
}));

const RichText = ({ slice, context, slices }) => {
  const { items, primary } = slice;

  // Check if this is the last rich text slice
  const richTextSlices = slices.filter(
    (s) => s.slice_type === slice.slice_type,
  );
  const lastRichTextSlice = richTextSlices[richTextSlices.length - 1];
  const isLastRichTextSlice = slice.id === lastRichTextSlice.id;

  const videoStyles = {
    '[data-oembed-type="video"]': {
      position: 'relative',
      paddingBottom: '56.25%',
      height: 0,
      marginBlockStart: '1em',
      marginBlockEnd: '1em',
      iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 1,
        height: 1,
      },
    },
  };

  const imageStyles = {
    img: { width: 1 },
    '.block-img': {
      lineHeight: 0,
    },
  };

  const postSelector = `blog-post-content-${slice.id}`;

  return (
    <>
      <Section
        sx={{
          px: { xs: 3, sm: 4 },
          pt: 5,
          pb: 2,
          display: `flex`,
          flexDirection: `column`,
        }}
      >
        <Box
          sx={{
            maxWidth: primary?.full_width_text ? 'xl' : 800,
            width: 1,
            mx: 'auto',
            ...imageStyles,
            ...videoStyles,
            position: `relative`,
          }}
        >
          <TOC postSelector={`.${postSelector}`} />
          <Box className={postSelector}>
            {items &&
              items.length > 0 &&
              items.map(({ text_body: textBody }) => {
                const containsLargerCharacters = Boolean(
                  textBody.text.toString().match(/[\u3400-\u9FBF]/),
                );
                return (
                  <StyledBox
                    post={context?.post}
                    large={containsLargerCharacters}
                    key={uuidv4()}
                  >
                    <PrismicRichText
                      field={textBody.richText}
                      components={{
                        heading1: (props) => (
                          <h1 id={_.kebabCase(props.text)}>{props.children}</h1>
                        ),
                        heading2: (props) => (
                          <h2 id={_.kebabCase(props.text)}>{props.children}</h2>
                        ),
                        heading3: (props) => (
                          <h3 id={_.kebabCase(props.text)}>{props.children}</h3>
                        ),
                        heading4: (props) => (
                          <h4 id={_.kebabCase(props.text)}>{props.children}</h4>
                        ),
                        heading5: (props) => (
                          <h5 id={_.kebabCase(props.text)}>{props.children}</h5>
                        ),
                        heading6: (props) => (
                          <h6 id={_.kebabCase(props.text)}>{props.children}</h6>
                        ),
                      }}
                    />
                  </StyledBox>
                );
              })}
          </Box>
        </Box>
      </Section>
      {(context.next || context.prev) && isLastRichTextSlice && (
        <NextPrevPosts
          lang={context.lang}
          prevUrl={context.prev?.url}
          prevTitle={context.prev?.data?.blog_post_title.text}
          nextUrl={context.next?.url}
          nextTitle={context.next?.data.blog_post_title?.text}
        />
      )}
    </>
  );
};

export const query = graphql`
  fragment FeatureRichText on PrismicFeatureDataBodyRichText {
    id
    slice_type
    primary {
      full_width_text
    }
    items {
      text_body {
        richText
      }
    }
  }
  fragment BlogPostRichText on PrismicBlogPostDataBodyRichText {
    id
    slice_type
    primary {
      full_width_text
    }
    items {
      text_body {
        richText
        text
      }
    }
  }
  fragment LandingPageRichText on PrismicLandingPageDataBodyRichText {
    id
    slice_type
    primary {
      full_width_text
    }
    items {
      text_body {
        richText
        text
      }
    }
  }
  fragment LandingPageAfterPricingRichText on PrismicLandingPageDataBody1RichText {
    id
    slice_type
    primary {
      full_width_text
    }
    items {
      text_body {
        richText
        text
      }
    }
  }
  fragment CompanyPageRichText on PrismicCompanyPageDataBodyRichText {
    id
    slice_type
    primary {
      full_width_text
    }
    items {
      text_body {
        richText
        text
      }
    }
  }
  fragment JobPageRichText on PrismicJobPageDataBodyRichText {
    id
    slice_type
    primary {
      full_width_text
    }
    items {
      text_body {
        richText
        text
      }
    }
  }
`;

export default RichText;
