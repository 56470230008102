/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import React from 'react';
import Section from '@/components/Section';
import Typography from '@/components/Typography';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import { v4 as uuidv4 } from 'uuid';
import { Box, TypographyPropsVariantOverrides } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { OverridableStringUnion } from '@mui/types';

type TestimonialsSlideProps = {
  fourthColor: string;
  color: string;
  item: {
    attribution: string;
    testimonial_text: {
      richText: {
        type: OverridableStringUnion<
          Variant | 'inherit',
          TypographyPropsVariantOverrides
        >;
        text: string;
      }[];
    };
    image: { gatsbyImageData: IGatsbyImageData; alt: string };
  };
};

export const TestimonialsSlide = ({
  item,
  fourthColor,
  color,
}: TestimonialsSlideProps): JSX.Element => (
  <Section
    sx={{
      overflow: `hidden`,
      px: 0,
      py: 0,
      color,
    }}
  >
    <Box
      sx={{
        mx: `auto`,
        width: 1,
        display: `flex`,
        flexDirection: { xs: `column`, sm: `row` },
        justifyContent: `center`,
        alignItems: `center`,
        gap: 3,
        '.gatsby-image-wrapper': {
          minWidth: 180,
          minHeight: 180,
          maxWidth: 180,
          maxHeight: 180,
        },
      }}
    >
      {item.image.gatsbyImageData && (
        <GatsbyImage alt={item.image.alt} image={item.image.gatsbyImageData} />
      )}

      <Box
        sx={{
          mr: `auto`,
          display: `flex`,
          flexDirection: `column`,
          gap: 2,
        }}
      >
        {item.testimonial_text.richText.map(({ text, type }) => (
          <CustomTypography key={uuidv4()} variant={type} customVariant="quote">
            {text}
          </CustomTypography>
        ))}
        <Typography variant="paragraphMedium" sx={{ color: fourthColor }}>
          {item.attribution}
        </Typography>
      </Box>
    </Box>
  </Section>
);
