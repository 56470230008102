import React from 'react';
import { Box } from '@mui/material';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { v4 as uuidv4 } from 'uuid';

import Section from '@/components/Section';
import Link from '@/components/Link';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import getBackgroundColor from '@/utils/getBackgroundColor';
import Animation from '@/components/Animation';

const getTextColor = (color) => {
  let result = '#0A0101';
  if (color === 'Rosetta Blue') {
    result = '#B1D0EC';
  } else if (color === 'Rosetta Green') {
    result = '#D0ECB1';
  } else if (color === 'Rosetta Pink') {
    result = '#EBB0CF';
  }
  return result;
};

const InlineGrid = ({ slice }) => {
  const {
    items,
    primary: {
      inline_grid_title: inlineGridTitle,
      inline_grid_style: inlineGridStyle,
    },
  } = slice;

  const { backgroundColor, color, subColor } =
    getBackgroundColor(inlineGridStyle);

  if (!slice.primary) return <></>;

  return (
    <Section
      sx={{
        overflow: `hidden`,
        px: { xs: 3, sm: 4, md: 13.5 },
        pt: { xs: 4, sm: 6, md: 8 },
        pb: { xs: 5, sm: 7, md: 9 },
        backgroundColor,
        color,
      }}
    >
      <Box
        sx={{
          flexDirection: `column`,
          display: `flex`,
          maxWidth: 'xl',
          mx: 'auto',
          width: 1,
        }}
      >
        {inlineGridTitle.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            variant={type}
            customVariant="heading3"
          >
            {text}
          </CustomTypography>
        ))}
        <Box
          sx={{
            display: `flex`,
            mt: 3,
            justifyContent: `space-between`,
            flexDirection: { xs: `column`, lg: `row` },
            flexWrap: `wrap`,
            gap: { xs: 7, md: 3 },
          }}
        >
          {items &&
            items.length > 0 &&
            items.map(
              (
                {
                  inline_grid_item_title: inlineGridItemTitle,
                  inline_grid_item_image: inlineGridItemImage,
                  inline_grid_item_subtitle_icon: inlineGridItemSubtitleIcon,
                  inline_grid_item_subtitle: inlineGridItemSubtitle,
                  inline_grid_item_bold_text_color: inlineGridItemBoldTextColor,
                  inline_grid_item_bold_text: inlineGridItemBoldText,
                  inline_grid_item_description: inlineGridItemDescription,
                  inline_grid_item_cta_label: inlineGridItemCtaLabel,
                  inline_grid_item_cta_link: inlineGridItemCtaLink,
                  inline_grid_item_cta_mixpanel_event:
                    inlineGridItemCtaMixpanelEvent,
                  json_animation_data: jsonAnimationData,
                  animation_trigger: animationTrigger,
                  animation_loop: animationLoop,
                  media_type: mediaType,
                },
                i,
              ) => {
                const isAnimation = mediaType === 'animation';
                const isImage =
                  inlineGridItemImage.gatsbyImageData &&
                  mediaType !== 'animation';
                return (
                  <Box
                    key={uuidv4()}
                    className="inline-grid"
                    sx={{
                      maxWidth: { lg: 310, xl: 392 },
                      display: `flex`,
                      flexDirection: { xs: `column`, sm: `row`, lg: `column` },
                      alignItems: `center`,
                      gap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: { xs: 'none', sm: 272, md: 340, lg: 'none' },
                        order: {
                          xs: 0,
                          sm: i % 2 !== 0 ? 1 : 0,
                          lg: 0,
                        },
                      }}
                    >
                      {isImage && (
                        <GatsbyImage
                          alt={inlineGridItemImage.alt}
                          image={inlineGridItemImage.gatsbyImageData}
                        />
                      )}
                      {isAnimation && (
                        <Animation
                          id={inlineGridItemTitle.text}
                          loop={animationLoop}
                          animationTrigger={animationTrigger}
                          rawAnimationData={jsonAnimationData}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{ display: `flex`, flexDirection: `column`, gap: 1 }}
                    >
                      {inlineGridItemSubtitle.richText.length > 0 &&
                        inlineGridItemSubtitle.richText[0].text && (
                          <Box
                            sx={{
                              display: `flex`,
                              alignItems: `center`,
                              gap: 1.5,
                              mb: '10px',
                            }}
                          >
                            {inlineGridItemSubtitleIcon.gatsbyImageData && (
                              <GatsbyImage
                                alt={inlineGridItemSubtitleIcon.alt}
                                image={
                                  inlineGridItemSubtitleIcon.gatsbyImageData
                                }
                                style={{
                                  minWidth: 36,
                                  minHeight: 36,
                                  maxWidth: 36,
                                  maxHeight: 36,
                                }}
                              />
                            )}
                            {inlineGridItemSubtitle.richText.map(
                              ({ text, type }) => (
                                <CustomTypography
                                  key={uuidv4()}
                                  variant={type}
                                  customVariant="heading6"
                                  sx={{
                                    display: `inline-block`,
                                    color: subColor,
                                  }}
                                >
                                  {text}
                                </CustomTypography>
                              ),
                            )}
                          </Box>
                        )}
                      <Box>
                        {inlineGridItemTitle.richText.map(({ text, type }) => (
                          <CustomTypography
                            key={uuidv4()}
                            variant={type}
                            customVariant="heading3"
                            sx={{ display: `inline` }}
                          >
                            {text}
                          </CustomTypography>
                        ))}
                        {inlineGridItemBoldText.richText.map(
                          ({ text, type }) => (
                            <CustomTypography
                              key={uuidv4()}
                              variant={type}
                              customVariant="heading3"
                              sx={{
                                color: getTextColor(
                                  inlineGridItemBoldTextColor,
                                ),
                                display: `inline`,
                              }}
                            >
                              {` ${text}`}
                            </CustomTypography>
                          ),
                        )}
                      </Box>
                      {inlineGridItemDescription.richText.map(
                        ({ text, type }) => (
                          <CustomTypography
                            key={uuidv4()}
                            customVariant="paragraph"
                            variant={type}
                          >
                            {text}
                          </CustomTypography>
                        ),
                      )}
                      {inlineGridItemCtaLink.url && (
                        <Link
                          to={inlineGridItemCtaLink.url}
                          href={inlineGridItemCtaLink.url}
                          target={inlineGridItemCtaLink.target}
                          dataEventAction={inlineGridItemCtaMixpanelEvent}
                          sx={{
                            color: `#5298D5`,
                            gap: 1,
                            fontWeight: 600,
                            display: `flex`,
                            alignItems: `center`,
                            img: {
                              width: 16,
                              height: 12,
                              transitionProperty: 'transform',
                              transitionTimingFunction:
                                'cubic-bezier(0.4, 0, 0.2, 1)',
                              transitionDuration: '0.15s',
                            },
                            '&:hover': {
                              img: {
                                transform: `translate(4px, 0px)`,
                              },
                            },
                            mt: 0.5,
                          }}
                        >
                          {inlineGridItemCtaLabel}
                          <img src="/blue-arrow.svg" alt="blue-arrow" />
                        </Link>
                      )}
                    </Box>
                  </Box>
                );
              },
            )}
        </Box>
      </Box>
    </Section>
  );
};

export default InlineGrid;

export const query = graphql`
  fragment HomepageInlineGrid on PrismicHomepageDataBodyInlineGrid {
    id
    primary {
      inline_grid_style
      inline_grid_title {
        richText
      }
    }
    slice_type
    items {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      inline_grid_item_bold_text {
        richText
      }
      inline_grid_item_bold_text_color
      inline_grid_item_cta_mixpanel_event
      inline_grid_item_cta_label
      inline_grid_item_cta_link {
        id
        url
        target
      }
      inline_grid_item_description {
        richText
      }
      inline_grid_item_image {
        alt
        gatsbyImageData
      }
      inline_grid_item_subtitle {
        richText
      }
      inline_grid_item_subtitle_icon {
        alt
        gatsbyImageData(width: 36)
      }
      inline_grid_item_title {
        richText
      }
    }
  }
  fragment FeatureInlineGrid on PrismicFeatureDataBodyInlineGrid {
    id
    primary {
      inline_grid_style
      inline_grid_title {
        richText
      }
    }
    slice_type
    items {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      inline_grid_item_bold_text {
        richText
      }
      inline_grid_item_bold_text_color
      inline_grid_item_cta_mixpanel_event
      inline_grid_item_cta_label
      inline_grid_item_cta_link {
        id
        url
        target
      }
      inline_grid_item_description {
        richText
      }
      inline_grid_item_image {
        alt
        gatsbyImageData
      }
      inline_grid_item_subtitle {
        richText
      }
      inline_grid_item_subtitle_icon {
        alt
        gatsbyImageData(width: 36)
      }
      inline_grid_item_title {
        richText
      }
    }
  }

  fragment LandingPageInlineGrid on PrismicLandingPageDataBodyInlineGrid {
    id
    primary {
      inline_grid_style
      inline_grid_title {
        richText
      }
    }
    slice_type
    items {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      inline_grid_item_bold_text {
        richText
      }
      inline_grid_item_bold_text_color
      inline_grid_item_cta_mixpanel_event
      inline_grid_item_cta_label
      inline_grid_item_cta_link {
        id
        url
        target
      }
      inline_grid_item_description {
        richText
      }
      inline_grid_item_image {
        alt
        gatsbyImageData
      }
      inline_grid_item_subtitle {
        richText
      }
      inline_grid_item_subtitle_icon {
        alt
        gatsbyImageData(width: 36)
      }
      inline_grid_item_title {
        richText
      }
    }
  }

  fragment LandingPageAfterPricingInlineGrid on PrismicLandingPageDataBody1InlineGrid {
    id
    primary {
      inline_grid_style
      inline_grid_title {
        richText
      }
    }
    slice_type
    items {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      inline_grid_item_bold_text {
        richText
      }
      inline_grid_item_bold_text_color
      inline_grid_item_cta_mixpanel_event
      inline_grid_item_cta_label
      inline_grid_item_cta_link {
        id
        url
        target
      }
      inline_grid_item_description {
        richText
      }
      inline_grid_item_image {
        alt
        gatsbyImageData
      }
      inline_grid_item_subtitle {
        richText
      }
      inline_grid_item_subtitle_icon {
        alt
        gatsbyImageData(width: 36)
      }
      inline_grid_item_title {
        richText
      }
    }
  }

  fragment CompanyPageInlineGrid on PrismicCompanyPageDataBodyInlineGrid {
    id
    primary {
      inline_grid_style
      inline_grid_title {
        richText
      }
    }
    slice_type
    items {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      inline_grid_item_bold_text {
        richText
      }
      inline_grid_item_bold_text_color
      inline_grid_item_cta_mixpanel_event
      inline_grid_item_cta_label
      inline_grid_item_cta_link {
        id
        url
        target
      }
      inline_grid_item_description {
        richText
      }
      inline_grid_item_image {
        alt
        gatsbyImageData
      }
      inline_grid_item_subtitle {
        richText
      }
      inline_grid_item_subtitle_icon {
        alt
        gatsbyImageData(width: 36)
      }
      inline_grid_item_title {
        richText
      }
    }
  }

  fragment JobPageInlineGrid on PrismicJobPageDataBodyInlineGrid {
    id
    primary {
      inline_grid_style
      inline_grid_title {
        richText
      }
    }
    slice_type
    items {
      animation_loop
      animation_trigger
      media_type
      json_animation_data {
        text
      }
      inline_grid_item_bold_text {
        richText
      }
      inline_grid_item_bold_text_color
      inline_grid_item_cta_mixpanel_event
      inline_grid_item_cta_label
      inline_grid_item_cta_link {
        id
        url
        target
      }
      inline_grid_item_description {
        richText
      }
      inline_grid_item_image {
        alt
        gatsbyImageData
      }
      inline_grid_item_subtitle {
        richText
      }
      inline_grid_item_subtitle_icon {
        alt
        gatsbyImageData(width: 36)
      }
      inline_grid_item_title {
        richText
      }
    }
  }
`;
