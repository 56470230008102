import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import Link from '@/components/Link';

import Section from '@/components/Section';
import Typography from '@/components/Typography/Typography';
import getPlatformSectionColors from '@/utils/getPlatformSectionColors';
import { CustomTypography } from '@/components/Typography/CustomTypography';

const Platforms = ({ slice }) => {
  const { items, primary } = slice;
  const {
    platformsStyle,
    platformsTitle,
    platformsCtaLabel,
    platformsCtaLink,
    platformsCtaMixpanelEvent,
  } = useMemo(() => {
    if (primary) {
      return {
        platformsStyle: primary?.platforms_style,
        platformsTitle: primary?.platforms_title,
        platformsCtaLabel: primary?.platforms_cta_label,
        platformsCtaLink: primary?.platforms_cta_link,
        platformsCtaMixpanelEvent: primary?.platforms_cta_mixpanel_event,
      };
    }
    return {};
  }, [primary]);
  const { backgroundColor, color, tileColor } =
    getPlatformSectionColors(platformsStyle);

  if (!primary) return <></>;

  return (
    <Section
      sx={{
        backgroundColor,
        color,
        flexDirection: `column`,
        gap: 4.5,
        alignItems: 'center',
        px: { xs: 3, sm: 4, md: 13.5 },
        pt: { xs: 4, sm: 6, md: 8 },
        pb: { xs: 5, sm: 7, md: 9 },
      }}
    >
      {platformsTitle &&
        platformsTitle.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            variant={type}
            customVariant="heading3"
            sx={{ color }}
          >
            {text}
          </CustomTypography>
        ))}
      <Box
        className="platforms"
        sx={{
          display: `grid`,
          gridTemplateColumns: `repeat(2, minmax(0, 1fr))`,
          flexWrap: 'wrap',
          justifyContent: `space-between`,
          gap: 2,
          width: 1,
          maxWidth: 600,
        }}
      >
        {items &&
          items.length > 0 &&
          items.map(({ platform_item: platformItem }) => (
            <Box
              key={uuidv4()}
              className="platform"
              sx={{
                background: tileColor,
                width: 1,
                height: 80,
                display: `flex`,
                alignItems: `center`,
                justifyContent: `center`,
                gap: 1.5,
                overflow: `hidden`,
              }}
            >
              <img src="/check.svg" alt="black-arrow-large" />
              <Typography variant="paragraphXL">{platformItem}</Typography>
            </Box>
          ))}
      </Box>
      {platformsCtaLink.url && (
        <Box>
          <Link
            key={platformsCtaLink.url}
            href={platformsCtaLink.url}
            target={platformsCtaLink.target}
            dataEventAction={platformsCtaMixpanelEvent}
            sx={{
              color: `#5298D5`,
              gap: 1,
              fontWeight: 600,
              display: `flex`,
              alignItems: `center`,
              img: {
                width: 16,
                height: 12,
                transitionProperty: 'transform',
                transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                transitionDuration: '0.15s',
              },
              '&:hover': {
                img: {
                  transform: `translate(4px, 0px)`,
                },
              },
            }}
            underline="none"
          >
            {platformsCtaLabel}
            <img src="/blue-arrow.svg" alt="blue-arrow" />
          </Link>
        </Box>
      )}
    </Section>
  );
};

export default Platforms;

export const query = graphql`
  fragment HomepagePlatforms on PrismicHomepageDataBodyPlatforms {
    id
    slice_type
    primary {
      platforms_title {
        richText
      }
      platforms_style
      platforms_cta_link {
        url
        target
      }
      platforms_cta_label
      platforms_cta_mixpanel_event
    }
    items {
      platform_item
    }
  }

  fragment FeaturePlatforms on PrismicFeatureDataBodyPlatforms {
    id
    slice_type
    primary {
      platforms_title {
        richText
      }
      platforms_style
      platforms_cta_link {
        url
        target
      }
      platforms_cta_label
      platforms_cta_mixpanel_event
    }
    items {
      platform_item
    }
  }

  fragment LandingPagePlatforms on PrismicLandingPageDataBodyPlatforms {
    id
    slice_type
    primary {
      platforms_title {
        richText
      }
      platforms_style
      platforms_cta_link {
        url
        target
      }
      platforms_cta_label
      platforms_cta_mixpanel_event
    }
    items {
      platform_item
    }
  }

  fragment LandingPageAfterPricingPlatforms on PrismicLandingPageDataBody1Platforms {
    id
    slice_type
    primary {
      platforms_title {
        richText
      }
      platforms_style
      platforms_cta_link {
        url
        target
      }
      platforms_cta_label
      platforms_cta_mixpanel_event
    }
    items {
      platform_item
    }
  }

  fragment CompanyPagePlatforms on PrismicCompanyPageDataBodyPlatforms {
    id
    slice_type
    primary {
      platforms_title {
        richText
      }
      platforms_style
      platforms_cta_link {
        url
        target
      }
      platforms_cta_label
      platforms_cta_mixpanel_event
    }
    items {
      platform_item
    }
  }

  fragment JobPagePlatforms on PrismicJobPageDataBodyPlatforms {
    id
    slice_type
    primary {
      platforms_title {
        richText
      }
      platforms_style
      platforms_cta_link {
        url
        target
      }
      platforms_cta_label
      platforms_cta_mixpanel_event
    }
    items {
      platform_item
    }
  }
`;
