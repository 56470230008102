import React from 'react';

import { Box } from '@mui/material';
import Link from '@/components/Link';
import Typography from '@/components/Typography';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { HiArrowRight } from 'react-icons/hi';
import { CustomTypography } from '@/components/Typography/CustomTypography';
import { v4 as uuidv4 } from 'uuid';
import { linkLabels } from '@/data/linkLabels';

type PostProps = {
  i: number;
  post: {
    title: { richText: { type: string; text: string }[] };
    description: { richText: { type: string; text: string }[] };
    url: string;
    image: { gatsbyImageData: IGatsbyImageData; alt: string };
  };
  lang: string;
};

export const Post = ({ post, i, lang }: PostProps): JSX.Element => (
  <Box
    sx={{
      display: `flex`,
      flexDirection: {
        xs: `column`,
        smid: i % 2 === 0 ? `row-reverse` : `row`,
        lg: `column`,
      },
      gap: { xs: 1, smid: 2, lg: 1 },
      alignItems: { xs: `start`, smid: `center`, lg: `start` },
      maxWidth: { smid: 680, lg: `none` },
    }}
  >
    <Link
      to={post.url}
      sx={{
        minWidth: { smid: 272, lg: `none` },
        maxWidth: { smid: 272, lg: `none` },
        backgroundColor: `#fff`,
        overflow: `hidden`,
        cursor: `pointer`,
        position: `relative`,
        paddingBottom: { xs: `56.25%`, smid: `40%`, lg: `56.25%` },
        height: 0,
        width: 1,
        '.gatsby-image-wrapper': {
          position: `absolute`,
          top: 0,
          left: 0,
          width: 1,
          height: 1,
          transitionProperty: `all`,
          transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
          transitionDuration: `150ms`,
          '&:hover': {
            transform: `scale(1.2)`,
          },
        },
        img: {
          objectFit: `cover`,
          width: 1,
          height: 1,
        },
      }}
    >
      {post.image ? (
        <GatsbyImage alt={post.image.alt} image={post.image.gatsbyImageData} />
      ) : (
        <img src="/media-placeholder.png" alt="placeholder" />
      )}
    </Link>
    <Box sx={{ display: `flex`, flexDirection: `column`, gap: 1 }}>
      <Link to={post.url}>
        {post.title.richText.map(({ text }: any) => (
          <CustomTypography
            key={uuidv4()}
            customVariant="heading3"
            variant="heading2"
          >
            {text}
          </CustomTypography>
        ))}
      </Link>
      {post.description.richText.map(({ text, type }: any) => (
        <CustomTypography
          key={uuidv4()}
          customVariant="paragraphMedium"
          variant={type}
        >
          {text}
        </CustomTypography>
      ))}
      <Link to={post.url} sx={{ py: 1 }}>
        <Typography
          variant="paragraphMedium"
          sx={{
            color: `#5298D5`,
            fontWeight: 600,
            display: `flex`,
            alignItems: `center`,
            gap: 1,
            svg: {
              transitionProperty: `transform`,
              transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
              transitionDuration: `0.15s`,
            },
            '&:hover': {
              svg: {
                transform: `translate(4px, 0px)`,
              },
            },
          }}
        >
          {linkLabels[lang || `en-us`]}
          <HiArrowRight />
        </Typography>
      </Link>
    </Box>
  </Box>
);
