import React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
import { v4 as uuidv4 } from 'uuid';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import Section from '@/components/Section';
import _ from 'lodash';

const StyledBox = styled(Box)(({ theme }) => ({
  ...theme.typography.paragraph,
  h1: {
    ...theme.typography.heading1,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
  },
  h2: {
    ...theme.typography.heading2,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
  },
  h3: {
    ...theme.typography.heading3,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
  },
  h4: {
    ...theme.typography.heading4,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
  },
  h5: {
    ...theme.typography.heading5,
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
  },
  h6: {
    ...theme.typography.heading6,
    color: 'black',
    marginBlockStart: '16px',
    marginBlockEnd: '16px',
  },
  a: {
    color: `#B1D0EC`,
  },
}));

const PressRichText = ({ slice }) => {
  const { items } = slice;

  const videoStyles = {
    '[data-oembed-type="video"]': {
      position: 'relative',
      paddingBottom: '56.25%',
      height: 0,
      marginBlockStart: '1em',
      marginBlockEnd: '1em',
      iframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 1,
        height: 1,
      },
    },
  };

  return (
    <Section
      sx={{
        px: { xs: 3, sm: 4 },
        py: { lg: 5 },
        pb: 5,
        display: `flex`,
        flexDirection: `column`,
      }}
    >
      <Box
        sx={{
          maxWidth: 'sm',
          width: 1,
          mx: 'auto',
          ...videoStyles,
          position: `relative`,
        }}
      >
        {items &&
          items.length > 0 &&
          items.map(({ text_body: textBody }) => (
            <StyledBox key={uuidv4()}>
              <PrismicRichText
                field={textBody.richText}
                components={{
                  heading2: (props) => (
                    <h2 id={_.kebabCase(props.text)}>{props.children}</h2>
                  ),
                  heading3: (props) => (
                    <h3 id={_.kebabCase(props.text)}>{props.children}</h3>
                  ),
                  heading4: (props) => (
                    <h4 id={_.kebabCase(props.text)}>{props.children}</h4>
                  ),
                  heading5: (props) => (
                    <h5 id={_.kebabCase(props.text)}>{props.children}</h5>
                  ),
                  heading6: (props) => (
                    <h6 id={_.kebabCase(props.text)}>{props.children}</h6>
                  ),
                }}
              />
            </StyledBox>
          ))}
      </Box>
    </Section>
  );
};

export const query = graphql`
  fragment PressPostRichText on PrismicPressPostDataBodyPressRichText {
    id
    slice_type
    items {
      text_body {
        richText
        text
      }
    }
  }
`;

export default PressRichText;
