/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { HEntry } from '@/types';
import { getNestedHeadings } from '@/utils/getNestedHeadings';
import { useEffect, useState } from 'react';

export function useHeadingsData(
  postSelector: string,
  headingSelector: string,
): { headings: HEntry[] } {
  const [headings, setHeadings] = useState<HEntry[]>([]);

  useEffect(() => {
    const hs = getNestedHeadings(
      Array.from(
        document
          .querySelector(postSelector)!
          .querySelectorAll<HTMLHeadingElement>(headingSelector),
      ),
    );
    setHeadings(hs);
  }, []);

  return { headings };
}
