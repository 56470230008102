import React from 'react';
import Section from '@/components/Section';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import { Box } from '@mui/material';
import Typography from '@/components/Typography';
import { CustomTypography } from '@/components/Typography/CustomTypography';

const PressContacts = ({ slice }) => {
  const { primary, items } = slice;
  if (!primary) return <></>;
  return (
    <Section sx={{ px: { xs: 3, sm: 4 }, py: { xs: 4, sm: 6, md: 7.5 } }}>
      <Box
        sx={{
          maxWidth: 'sm',
          width: 1,
          mx: 'auto',
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
        }}
      >
        {primary.section_title.richText.map(({ text, type }) => (
          <CustomTypography
            key={uuidv4()}
            variant={type}
            customVariant="heading2"
          >
            {text}
          </CustomTypography>
        ))}
        {items && items.length > 0 && (
          <Box
            sx={{
              display: `flex`,
              gap: 3,
              flexDirection: { xs: `column`, sm: `row` },
              justifyContent: `space-between`,
            }}
          >
            {items.map((item) => (
              <Box
                sx={{
                  display: `flex`,
                  flexDirection: `column`,
                  gap: 1,
                  textAlign: `left`,
                }}
              >
                {item.contact_name.richText.map(({ text }) => (
                  <Typography key={uuidv4()} variant="paragraphBold">
                    {text}
                  </Typography>
                ))}
                <Box
                  sx={{
                    display: `flex`,
                    flexDirection: `column`,
                    gap: 0.5,
                    a: { color: `#5298D5`, textDecoration: `none` },
                  }}
                >
                  <Typography variant="textSmall">
                    {item.contact_company}
                  </Typography>
                  <Typography variant="textSmall">
                    <a href={`mailto:${item.contact_email}`}>
                      {item.contact_email}
                    </a>
                  </Typography>
                  <Typography variant="textSmall">
                    {item.contact_number}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Section>
  );
};

export const query = graphql`
  fragment PressContacts on PrismicPressPostDataBodyPressContacts {
    id
    primary {
      section_title {
        richText
      }
    }
    items {
      contact_name {
        richText
      }
      contact_company
      contact_email
      contact_number
    }
    slice_type
  }
`;

export default PressContacts;
