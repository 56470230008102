type VideoColors = {
  titleColor: string;
  descriptionColor: string;
  unselectedColor: string;
  notchColor: string;
};

export const getVideoSectionColors = (color: string): VideoColors => {
  if (color === `light`) {
    return {
      titleColor: `#000000`,
      descriptionColor: `#544D4D`,
      unselectedColor: `#B6B3B3`,
      notchColor: `#231A1A`,
    };
  }
  return {
    titleColor: `#F8F8F8`,
    descriptionColor: `#CECCCC`,
    unselectedColor: `#858080`,
    notchColor: `#F8F8F8`,
  };
};
