/* eslint-disable no-param-reassign */
/* eslint-disable react/require-default-props */
import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Chevron } from '../DropdownMenu/Chevron';
import { useHeadingsData } from '../hooks/useHeadingsData';
import { Headings } from './Headings';

export const TOC = ({
  postSelector,
  headingSelector,
}: {
  postSelector?: string;
  headingSelector?: string;
}): false | JSX.Element => {
  postSelector = postSelector || `.blog-post-content`;
  headingSelector = headingSelector || `h1,h2,h3,h4,h5,h6`;

  const { headings } = useHeadingsData(postSelector, headingSelector);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    headings.length > 0 && (
      <Box
        sx={{
          position: { lg: `absolute` },
          top: 0,
          right: { lg: -288, xl: -452 },
          height: 1,
          mx: `auto`,
          width: 1,
          maxWidth: { xs: `sm`, lg: 272, xl: 384 },
          mb: { xs: 7, sm: 10, lg: 0 },
        }}
      >
        <Box
          component="nav"
          aria-label="Table of Contents"
          sx={{
            position: { lg: `sticky` },
            top: 64,
            backgroundColor: `#F8F8F8`,
          }}
        >
          <div ref={scrollRef} className="outer-scroll">
            <Box
              role="heading"
              component="button"
              aria-level={6}
              onClick={() => setIsOpen(!isOpen)}
              sx={{
                fontWeight: 800,
                fontSize: `12px`,
                lineHeight: `15px`,
                letterSpacing: `1.5px`,
                p: 2,
                borderBottom: `1px solid #E7E6E6`,
                width: 1,
                backgroundColor: `inherit`,
                borderLeft: `none`,
                borderRight: `none`,
                borderTop: `none`,
                textAlign: `left`,
                cursor: `pointer`,
                svg: {
                  transform: isOpen ? `` : `rotate(180deg)`,
                  transitionProperty: `transform`,
                  transitionTimingFunction: `cubic-bezier(0.4, 0, 0.2, 1)`,
                  transitionDuration: `150ms`,
                  fill: `#231A1A`,
                },
                display: `flex`,
                alignItems: `center`,
                justifyContent: `space-between`,
              }}
            >
              TABLE OF CONTENTS
              <Chevron />
            </Box>
            <Box
              component="ul"
              sx={{
                li: { listStyleType: `none` },
                px: 2,
                my: 0,
                maxHeight: !isOpen ? 0 : { xs: 1000, lg: `80vh` },
                overflow: `hidden`,
                overflowY: `scroll`,
                transitionProperty: `max-height, visibility`,
                transitionDuration: isOpen ? `1.7s` : `0.35s`,
                transitionTimingFunction: `ease`,
                '&::-webkit-scrollbar': {
                  '-webkit-appearance': `none`,
                  width: `7px`,
                },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: `4px`,
                  backgroundColor: `rgba(0, 0, 0, .5)`,
                  '-webkit-box-shadow': `0 0 1px rgba(255, 255, 255, .5)`,
                },
              }}
            >
              {headings.map((h) => (
                <Box component="li" key={h.id} sx={{ my: 2 }}>
                  <Headings entry={h} />
                </Box>
              ))}
            </Box>
          </div>
        </Box>
      </Box>
    )
  );
};
