/* eslint-disable no-restricted-syntax */
import { MutableRefObject, useEffect } from 'react';

const events = [`mousedown`, `touchstart`];

export default (
  ref: MutableRefObject<any>,
  onClickOutside: () => void,
): any => {
  const isOutside = (element: any) =>
    !ref.current || !ref.current.contains(element);

  const onClick = (event: { target: any }) => {
    if (isOutside(event.target)) {
      onClickOutside();
    }
  };

  useEffect(() => {
    for (const event of events) {
      document.addEventListener(event, onClick);
    }

    const func = () => {
      for (const event of events) document.removeEventListener(event, onClick);
    };

    return func;
  });
};
