import React from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';

import { Box } from '@mui/material';

import Section from '@/components/Section';
import getBackgroundColor from '@/utils/getBackgroundColor';
import { CustomTypography } from '@/components/Typography/CustomTypography';

const TextGrid = ({ slice }) => {
  const {
    items,
    primary: {
      text_grid_style: textGridStyle,
      text_grid_title: textGridTitle,
      text_grid_background_image: textGridBackgroundImage,
    },
  } = slice;
  const { backgroundColor, color } = getBackgroundColor(textGridStyle);

  if (!slice.primary) return <></>;

  return (
    <Section
      sx={{
        backgroundColor,
        overflow: `hidden`,
        px: { xs: 3, sm: 4, md: 13.5 },
        py: { xs: 4, sm: 6, md: 8 },
        backgroundImage:
          textGridStyle === 'Background Image' &&
          textGridBackgroundImage.url &&
          `url("${textGridBackgroundImage.url}")`,
      }}
    >
      <Box
        sx={{
          flexDirection: 'column',
          gap: 4,
          display: `flex`,
          maxWidth: 1224,
          mx: 'auto',
          width: 1,
        }}
      >
        {textGridTitle.richText.map(({ text, type }) => (
          <CustomTypography
            customVariant="heading3"
            key={uuidv4()}
            variant={type}
            sx={{ color }}
          >
            {text}
          </CustomTypography>
        ))}
        <Box
          sx={{
            display: `grid`,
            justifyContent: `space-between`,
            columnGap: { xs: 3, xl: 19.25 },
            rowGap: { xs: 3, sm: 5 },
            gridTemplateColumns: {
              xs: `repeat(1, 1fr)`,
              sm: `repeat(2, 1fr)`,
              md: items.length % 3 === 1 ? `repeat(2, 1fr)` : `repeat(3, 1fr)`,
            },
          }}
        >
          {items &&
            items.length > 0 &&
            items.map(
              ({
                text_grid_item_headline: textGridItemHeadline,
                text_grid_item_description: textGridItemDescription,
              }) => (
                <Box
                  key={uuidv4()}
                  sx={{
                    maxWidth: 253,
                    display: `flex`,
                    flexDirection: `column`,
                    gap: 2,
                  }}
                >
                  <CustomTypography
                    key={textGridItemHeadline.text}
                    variant="textJumbo"
                    sx={{ color }}
                  >
                    {textGridItemHeadline.text}
                  </CustomTypography>
                  {textGridItemDescription.richText.map(({ text, type }) => (
                    <CustomTypography
                      key={uuidv4()}
                      customVariant="paragraph"
                      variant={type}
                      sx={{ color }}
                    >
                      {text}
                    </CustomTypography>
                  ))}
                </Box>
              ),
            )}
        </Box>
      </Box>
    </Section>
  );
};

export default TextGrid;

export const query = graphql`
  fragment TextGrid on PrismicHomepageDataBodyTextGrid {
    id
    items {
      text_grid_item_description {
        richText
      }
      text_grid_item_headline {
        text
      }
    }
    primary {
      text_grid_background_image {
        url
      }
      text_grid_style
      text_grid_title {
        richText
      }
    }
    slice_type
  }
  fragment LandingPageTextGrid on PrismicLandingPageDataBodyTextGrid {
    id
    items {
      text_grid_item_description {
        richText
      }
      text_grid_item_headline {
        text
      }
    }
    primary {
      text_grid_background_image {
        url
      }
      text_grid_style
      text_grid_title {
        richText
      }
    }
    slice_type
  }

  fragment LandingPageAfterPricingTextGrid on PrismicLandingPageDataBody1TextGrid {
    id
    items {
      text_grid_item_description {
        richText
      }
      text_grid_item_headline {
        text
      }
    }
    primary {
      text_grid_background_image {
        url
      }
      text_grid_style
      text_grid_title {
        richText
      }
    }
    slice_type
  }

  fragment CompanyPageTextGrid on PrismicCompanyPageDataBodyTextGrid {
    id
    items {
      text_grid_item_description {
        richText
      }
      text_grid_item_headline {
        text
      }
    }
    primary {
      text_grid_background_image {
        url
      }
      text_grid_style
      text_grid_title {
        richText
      }
    }
    slice_type
  }

  fragment JobPageTextGrid on PrismicJobPageDataBodyTextGrid {
    id
    items {
      text_grid_item_description {
        richText
      }
      text_grid_item_headline {
        text
      }
    }
    primary {
      text_grid_background_image {
        url
      }
      text_grid_style
      text_grid_title {
        richText
      }
    }
    slice_type
  }
`;
