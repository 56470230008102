type BackgroundAndTextColor = {
  backgroundColor: string;
  color: string;
  tileColor?: string;
};

const getPlatformSectionColors = (
  backgroundColorText: string,
): BackgroundAndTextColor => {
  const result = {
    backgroundColor: `#fff`,
    color: `#0A0101`,
    tileColor: `#F8F8F8`,
  };
  if (backgroundColorText === `Black Background`) {
    result.backgroundColor = `#231A1A`;
    result.color = `#F8F8F8`;
    result.tileColor = `#544D4D`;
  }
  if (backgroundColorText === `Light Gray Background`) {
    result.backgroundColor = `#F8F8F8`;
    result.tileColor = `#FFFFFF`;
  }

  return result;
};

export default getPlatformSectionColors;
