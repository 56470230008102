import mixpanel from 'mixpanel-browser';

function pageView(location: Location): void {
  const {
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    protocol,
    search,
  } = location;
  mixpanel.track(`pageView`, {
    'page name': href,
    hash,
    host,
    hostname,
    href,
    origin,
    pathname,
    port,
    protocol,
    search,
  });
}

const event = {
  pageView,
};

export default event;
