const { defaultLanguage } = require('../../prismic-configuration');

/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 *
 * @type import('@prismicio/helpers').LinkResolverFunction
 */
exports.linkResolver = (doc) => {
  const alterLangLink = `/${doc.lang}`;
  const getLanguageLink = (url) =>
    doc.lang === defaultLanguage ? url : `${alterLangLink}${url}`;
  switch (doc.type) {
    case 'homepage': {
      return getLanguageLink('/');
    }
    case 'feature': {
      return getLanguageLink(`/products/${doc.uid ? doc.uid : doc.id}`);
    }
    case 'pricing_page': {
      return getLanguageLink(`/pricing`);
    }
    case 'resource_page': {
      return getLanguageLink(`/resources/${doc.uid ? doc.uid : doc.id}`);
    }
    case 'ebook_and_reports_page': {
      return getLanguageLink(`/ebook-and-reports`);
    }
    case 'ebook_and_report_page': {
      return getLanguageLink(
        `/ebook-and-reports/${doc.uid ? doc.uid : doc.id}`,
      );
    }
    case 'case_studies_page': {
      return getLanguageLink(`/case-studies`);
    }
    case 'case_study_page': {
      return getLanguageLink(`/case-studies/${doc.uid ? doc.uid : doc.id}`);
    }
    case 'blog_home_page': {
      return getLanguageLink(`/blog`);
    }
    case 'blog_post': {
      return getLanguageLink(`/blog/${doc.uid ? doc.uid : doc.id}`);
    }
    case 'company_page': {
      return getLanguageLink(`/company/${doc.uid ? doc.uid : doc.id}`);
    }
    case 'press_page': {
      return getLanguageLink(`/press`);
    }
    case 'press_post': {
      return getLanguageLink(`/press/${doc.uid ? doc.uid : doc.id}`);
    }
    case 'landing_page': {
      return getLanguageLink(`/${doc.uid ? doc.uid : doc.id}`);
    }
    case 'job_page': {
      return getLanguageLink(`/company/jobs/${doc.uid ? doc.uid : doc.id}`);
    }
    case '404': {
      return getLanguageLink(`/404`);
    }

    default:
      return '/';
  }
};
