import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Button } from '@mui/material';

interface Props {
  text: string;
  id: string;
}

const Tooltip = ({ text, id }: Props): JSX.Element => (
  <>
    {id && (
      <>
        <Button
          type="button"
          data-tooltip-id={id}
          data-tooltip-html={text}
          aria-describedby={id}
          sx={{
            backgroundColor: `transparent`,
            padding: 0,
            minWidth: 0,
            mt: 0.5,
            img: { width: 20, height: 20 },
          }}
        >
          <img src="/info.png" alt="information" />
        </Button>
        <ReactTooltip id={id} />
      </>
    )}
  </>
);

export default Tooltip;
